import { RequestStatus, useRequest } from '@unicaiot/unica-iot-gallery-core'
import { Mapping } from 'classnames'
import { BuildingViewType, HData } from 'domain/Buildings/services/buildingService/types'
import { DependencyList } from 'react'
import { DataPointsDetailBaseParams } from 'views/router/Authorized/Building/Rooms/Datapoints/routes'
import { SensorDetailViewParamsType, SensorListViewParamsType, sensorsService } from './sensorsService'
import { Sensor, RegisterSensor } from './types'

export const useGetGraphData = (
    params: SensorDetailViewParamsType,
    deps?: DependencyList
): RequestStatus<HData | undefined> => {
    const getPromise = () => sensorsService.getGraphData(params)
    return useRequest(getPromise, deps)
}

export const useGetSensorDetailView = (
    params: DataPointsDetailBaseParams,
    deps?: DependencyList
): RequestStatus<BuildingViewType | undefined> => {
    const getPromise = () => sensorsService.getSensorDetailView(params)
    return useRequest(getPromise, deps)
}

export const useGetSensorsListViewData = (params?: SensorListViewParamsType): RequestStatus<Sensor[] | undefined> => {
    const getPromise = () => sensorsService.getSensorsListViewData(params)

    return useRequest(getPromise)
}

export const useMapping = (id: string): RequestStatus<Mapping | undefined> => {
    const getPromise = () => sensorsService.getMappingDetails(id)

    return useRequest(getPromise)
}

export const useGetRegistration = (id?: string): RequestStatus<RegisterSensor | undefined> => {
    const getPromise = () => (id ? sensorsService.registration(id) : undefined)

    return useRequest(getPromise, [id])
}

export const useGetSensor = (id: string): RequestStatus<Sensor | undefined> => {
    const getPromise = () => sensorsService.getSensor(id)

    return useRequest(getPromise)
}

import { Trans } from '@lingui/macro'
import {
    Button,
    ButtonStyleType,
    Center,
    Column,
    ColumnSpacing,
    FontSize,
    HeadingLevel,
    JustifyContent,
    PageTitle,
    Row,
    Title,
} from '@unicaiot/unica-iot-gallery-buildinginsight'
import { ActivityIndicator, ActivityIndicatorEnumType, LocaleContext } from '@unicaiot/unica-iot-gallery-core'
import { BuildingFormData, BuildingsForm } from 'domain/Buildings/components/BuildingsForm/BuildingsForm'
import { buildingsService } from 'domain/Buildings/services/buildingService/buildingService'
import { useGetBuildingsDetailViewQuery } from 'domain/Buildings/services/buildingService/buildingService.hooks'
import { checkIfRequestStatusOK } from 'domain/Core/utils/checkIfRequestStatusOK'
import React, { Dispatch, FunctionComponent, SetStateAction, useContext } from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { routes } from 'views/router/Authorized/routes'

interface RouteParams {
    id: string
}

interface Props extends RouteComponentProps<RouteParams> {}

export const EditBuildingView: FunctionComponent<Props> = ({ match }) => {
    const locale = useContext(LocaleContext)
    const history = useHistory()
    const { id } = match.params

    const { data, error, loading } = useGetBuildingsDetailViewQuery({ id })

    const submitBuilding = async (data: BuildingFormData, setError: Dispatch<SetStateAction<string | undefined>>) => {
        const { id, name, file } = data
        const result = await buildingsService.putBuilding({
            id,
            name,
        })

        const uploadImageResponse = file && result && (await buildingsService.postBuildingImage({ id, file }))

        if (checkIfRequestStatusOK(uploadImageResponse?.status ?? result.status)) {
            history.push(routes.authorized.buildings.detail(id))

            return
        }

        setError(locale._('common.error'))
    }

    return <Column spacing={ColumnSpacing.spacing5}>{renderBuilding()}</Column>

    function renderBuilding() {
        if (loading) {
            return (
                <Center grow={true}>
                    <ActivityIndicator size={ActivityIndicatorEnumType.large} />
                    <Trans id="common.loading">De data vanuit de datapunten wordt opgehaald.</Trans>
                </Center>
            )
        }

        if (error) {
            return (
                <Center grow={true}>
                    <Column spacing={ColumnSpacing.spacing4}>
                        <Row justifyContent={JustifyContent.center}>
                            <Title headingLevel={HeadingLevel.h2} fontSize={FontSize.size22}>
                                <Trans id="common.error.title">Er is iets fout gegaan</Trans>
                            </Title>
                        </Row>
                        <Row justifyContent={JustifyContent.center}>
                            <Trans id="common.error.message">
                                Er ging iets mis met het laden van de pagina. Keer terug naar de vorige pagina.
                            </Trans>
                        </Row>
                        <Row justifyContent={JustifyContent.center}>
                            <Button buttonStyle={ButtonStyleType.primary} onClick={() => history.goBack()}>
                                <Trans id={'common.goBack'}>Terug naar vorige pagina</Trans>
                            </Button>
                        </Row>
                    </Column>
                </Center>
            )
        }

        if (data) {
            return (
                <>
                    <PageTitle>
                        <Trans id={'editBuildings.title'}>{locale._('editBuilding.title', { name: data?.name })}</Trans>
                    </PageTitle>
                    <BuildingsForm onSave={submitBuilding} building={data} />
                </>
            )
        }
    }
}

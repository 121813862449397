import classNames, { Argument } from 'classnames'
import React from 'react'
import { InfoText, TextColorEnumType, TextWeightEnumType } from '@unicaiot/unica-iot-gallery-buildinginsight'
import styles from './UserRole.module.scss'
import { Roles } from 'domain/Users/services/types'

interface Props {
    userRole: Roles | Roles[]
    className?: Argument
    backgroundColor: BackgroundColor
}

type BackgroundColor = 'dark' | 'grey'

export const UserRole: React.FC<Props> = props => {
    const { userRole, backgroundColor, className } = props
    const classes = classNames(
        styles.container,
        {
            [styles.isDark]: backgroundColor === 'dark',
            [styles.isGrey]: backgroundColor === 'grey',
        },
        className
    )

    return (
        <div className={classes}>
            <InfoText weight={TextWeightEnumType.bold} color={TextColorEnumType.white} className={styles.text}>
                {userRole}
            </InfoText>
        </div>
    )
}

import { roomsRoutes } from './Rooms/routes'

export interface BuildingRoutesBaseParams {
    buildingId: string
}

export const buildingsRoutes = {
    index: '/buildings',
    create: '/buildings/create',
    detail: (id: string | number = ':id') => `/buildings/${id}`,
    edit: (id: string | number = ':id') => `/buildings/${id}/edit`,
    rooms: roomsRoutes,
}

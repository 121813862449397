import styles from './BuildingGrid.module.scss'

import React, { FunctionComponent } from 'react'

import classNames, { Argument } from 'classnames'

interface Props {
    className?: Argument
}

export const BuildingGrid: FunctionComponent<Props> = props => {
    const classes = classNames(styles.container, props.className)

    return <div className={classes}>{props.children}</div>
}

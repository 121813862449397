import { Sensor } from 'domain/Rooms/Sensors/service/types'

class Sort {
    public sortByValues(data: Sensor[]): Sensor[] {
        const filteredArray = [...data].sort((a, b) => {
            if (a.latestKpi.kpi > b.latestKpi.kpi) {
                return 1
            } else if (a.latestKpi.kpi < b.latestKpi.kpi) {
                return -1
            }

            return 0
        })

        return filteredArray
    }

    public sortByDates(data: Sensor[]): Sensor[] {
        const filteredArray = [...data].sort((a, b) => {
            return b.modificationDate > a.modificationDate ? 1 : -1
        })

        return filteredArray
    }

    public sortBySpace(data: Sensor[]): Sensor[] {
        const filteredArray = [...data].sort((a, b) => {
            return a.roomName > b.roomName ? 1 : -1
        })

        return filteredArray
    }

    public sortByAlphabeticOrder(data: Sensor[]): Sensor[] {
        const filteredArray = [...data].sort((a, b) => {
            return a.buildingName > b.buildingName ? -1 : 1
        })

        return filteredArray
    }

    public sortByType(data: Sensor[]): Sensor[] {
        const filteredArray = [...data].sort((a, b) => {
            return a.type > b.type ? 1 : -1
        })

        return filteredArray
    }

    public sortBySensorCode(data: Sensor[]): Sensor[] {
        const filteredArray = [...data].sort((a, b) => {
            return a.name > b.name ? 1 : -1
        })

        return filteredArray
    }
}

export const SortFunctions = new Sort()

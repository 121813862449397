export interface UserDetailBaseParams {
    id: string
}

export const userRoutes = {
    index: '/users',
    create: '/users/create',
    detail: (id: string = ':id') => `/users/${id}/read`,
    editPassword: (id: string = ':id') => `/users/${id}/edit-password`,
    deleteUser: (id: string = ':id') => `/users/${id}/delete-user`,
}

import { Trans } from '@lingui/macro'
import {
    Button,
    ButtonStyleType,
    Column,
    ColumnSpacing,
    ImageUpload,
    Input,
    InputStyle,
    JustifyContent,
    Modal,
    PromptModalView,
    PreviewLayout,
    Row,
    RowSpacing,
    TextButton,
    Tooltip,
    useModal,
} from '@unicaiot/unica-iot-gallery-buildinginsight'
import { LocaleContext } from '@unicaiot/unica-iot-gallery-core'
import { buildingsService } from 'domain/Buildings/services/buildingService/buildingService'
import { useGetBuildingImage } from 'domain/Buildings/services/buildingService/buildingService.hooks'
import { Building } from 'domain/Buildings/services/buildingService/types'
import React, { Dispatch, Fragment, FunctionComponent, SetStateAction, useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { routes } from 'views/router/Authorized/routes'
import { checkIfRequestStatusOK } from '../../../Core/utils/checkIfRequestStatusOK'
import { FormDeleteModal } from '../../../Shared/FormDeleteModal/FormDeleteModal'
import { CreateBuildingsPreviewSvg } from '../CreateBuildingPreviewSvg/CreateBuildingsPreviewSvg'

export interface BuildingFormData {
    id: string
    name: string
    file?: File
}

interface Props {
    onSave: (data: BuildingFormData, setError: Dispatch<SetStateAction<string | undefined>>) => void
    saveLabel?: string | JSX.Element
    building?: Building
}

export const BuildingsForm: FunctionComponent<Props> = ({ onSave, building }) => {
    const locale = useContext(LocaleContext)
    const [name, setName] = useState<string | undefined>(building?.name)
    const [error, setError] = useState<string>()
    // TODO: pre-fill file when it's available in the API
    const [file, setFile] = useState<File>()
    const { data } = useGetBuildingImage(building?.id ?? '')
    const [imageURI, setImageURI] = useState('')
    const history = useHistory()
    const [deleteModal, deleteModalActions] = useModal()
    const src = URL.createObjectURL(new Blob([data!]))

    // Convert it to a data string instead of an blob so image doesn't flicker when re-rendering
    const getImageUri = (file: File) =>
        new Promise(resolve => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => resolve(reader.result)
        })

    useEffect(() => {
        if (!file) {
            setImageURI('')
            return
        }

        async function imageUriPromise() {
            if (!file) {
                return
            }

            const uri = await getImageUri(file)
            setImageURI(uri as string)
        }

        imageUriPromise()
    }, [file])

    const onSaveBuilding = () => {
        if (building) {
            if (!name) {
                setError(locale._('createBuilding.nameError'))
                return
            }

            onSave({ id: building?.id, name, file }, setError)
        }
    }

    const onDeleteBuilding = async () => {
        if (!building) {
            return
        }

        try {
            const result = await buildingsService.deleteEntity(building.id)

            if (checkIfRequestStatusOK(result.status)) {
                history.push(routes.authorized.buildings.index)
                return
            }
        } catch (e) {
            setError(locale._('common.error.title'))
            alert(locale._('common.error.title'))
        }
    }

    const renderBasicFooterButtons = () => (
        <Row justifyContent={JustifyContent.flexEnd} spacing={RowSpacing.spacing4}>
            <TextButton
                onClick={() => history.push(routes.authorized.buildings.index)}
                buttonStyle={ButtonStyleType.secondary}
            >
                {locale._('common.cancel')}
            </TextButton>
            <Button onClick={onSaveBuilding}>{locale._('common.save')}</Button>
        </Row>
    )

    return (
        <>
            <PreviewLayout
                preview={<CreateBuildingsPreviewSvg name={name} imageUri={!data ? '' : src} />}
                footer={
                    <Fragment>
                        {building ? (
                            <Row justifyContent={JustifyContent.spaceBetween}>
                                {renderDeleteButton()}
                                <Modal ref={deleteModal}>
                                    <PromptModalView>
                                        <FormDeleteModal
                                            title={locale._('deleteBuilding.title', { name: building.name })}
                                            infoText={locale._('deleteBuilding.message', { name: building.name })}
                                            type={'building'}
                                            onCancel={deleteModalActions.actions.closeModal}
                                            onDelete={onDeleteBuilding}
                                        />
                                    </PromptModalView>
                                </Modal>

                                {renderBasicFooterButtons()}
                            </Row>
                        ) : (
                            renderBasicFooterButtons()
                        )}
                    </Fragment>
                }
            >
                <Column spacing={ColumnSpacing.spacing4}>
                    <ImageUpload onChange={(v: File | undefined) => setFile(v)} />
                    <Input
                        value={name}
                        onChange={(v: string) => setName(v)}
                        style={InputStyle.light}
                        label={<Trans id={'BuildingsForm.name.label'}>Naam gebouw</Trans>}
                        error={!!error}
                        errorMessage={error}
                    />
                </Column>
            </PreviewLayout>
        </>
    )

    function renderDeleteButton(): JSX.Element {
        if (!building?.rooms?.length) {
            return (
                <Button buttonStyle={ButtonStyleType.danger} onClick={deleteModalActions.actions.openModal}>
                    <Trans id={'deleteBuilding.delete'}>Gebouw verwijderen</Trans>
                </Button>
            )
        }

        return (
            <Tooltip place="right" effect="solid" text={locale._('deleteBuilding.error.message')}>
                <Button
                    buttonStyle={ButtonStyleType.danger}
                    onClick={deleteModalActions.actions.openModal}
                    isDisabled={true}
                >
                    <Trans id={'deleteBuilding.delete'}>Gebouw verwijderen</Trans>
                </Button>
            </Tooltip>
        )
    }
}

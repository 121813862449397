import styles from './LiveFilter.module.scss'
import { Trans } from '@lingui/macro'
import { Button, Column, DateSelect, Icon, Icons, LiveFilterButton } from '@unicaiot/unica-iot-gallery-buildinginsight'
import { LocaleContext } from '@unicaiot/unica-iot-gallery-core'
import moment from 'moment'
import React, { FunctionComponent, useCallback, useContext, useState } from 'react'

interface Props {
    onFilterChange?: (value: LiveFilterType) => void
}

type DateType = [Date, Date]

export interface SessionStorageValues {
    timeframe: string
    date: DateType
}

export interface LiveFilterType {
    isActive: boolean
    timeframe: string
    date: DateType
}

export const LIVE_FILTER_SESSION_STORAGE_KEY = 'unica-bi-live-filter-state'

export const LiveFilter: FunctionComponent<Props> = ({ onFilterChange }) => {
    const sessionStorage = window.sessionStorage.getItem(LIVE_FILTER_SESSION_STORAGE_KEY)
    const defaultValues = sessionStorage ? (JSON.parse(sessionStorage) as SessionStorageValues) : undefined

    const [timeframe, setTimeframe] = useState(defaultValues?.timeframe || 'day')
    const [date, setDate] = useState<DateType>(defaultValues?.date ?? [new Date(), new Date()])
    const [isActive, setIsActive] = useState(defaultValues ? true : false)

    const locale = useContext(LocaleContext)

    const onReset = () => {
        setIsActive(false)
        setTimeframe('')
        setDate([new Date(), new Date()])
        onFilterChange && onFilterChange({ isActive, timeframe, date: [new Date(), new Date()] })
        clearSessionStorage()
    }

    const onSubmit = (close: () => void) => {
        setIsActive(true)
        setSessionStorage()
        onFilterChange && onFilterChange({ isActive, timeframe, date })
        close()
    }

    const isButtonDisabled = () => {
        if (!timeframe || !date || !date) {
            return true
        }

        return false
    }

    const onDateChange = useCallback((v: [Date, Date]) => {
        setDate(v)
    }, [])

    const renderLabel = () => {
        if (!isActive) {
            return <Trans id="LiveFilter.label">Live</Trans>
        }

        const formattedDate = moment(date[0]).format('DD MMMM')

        return (
            <div className={styles.activeLabel}>
                <div className={styles.activeLabelItem}>
                    <Icon size={12} icon={Icons.calendar} /> {formattedDate}
                </div>
            </div>
        )
    }

    const setSessionStorage = function () {
        window.sessionStorage.setItem(
            LIVE_FILTER_SESSION_STORAGE_KEY,
            JSON.stringify({
                timeframe,
                date,
            })
        )
    }

    const clearSessionStorage = function () {
        window.sessionStorage.removeItem(LIVE_FILTER_SESSION_STORAGE_KEY)
    }

    return (
        <LiveFilterButton
            isActive={isActive}
            label={renderLabel()}
            description={locale._('LiveFilter.description')}
            onReset={onReset}
            dropDownContent={close => (
                <Column spacing={2}>
                    <DateSelect
                        dateLabel={locale._({ id: 'LiveFilter.dateTime.label', message: 'Start datum' })}
                        onChange={onDateChange}
                        dateValue={date}
                    />
                    <Button isDisabled={isButtonDisabled()} onClick={() => onSubmit(close)}>
                        <Trans id="LiveFilter.select">Selecteer moment</Trans>
                    </Button>
                </Column>
            )}
        />
    )
}

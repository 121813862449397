import { Trans } from '@lingui/macro'
import {
    Center,
    Column,
    ColumnSpacing,
    Icons,
    MoreButton,
    PageTitle,
} from '@unicaiot/unica-iot-gallery-buildinginsight'
import { ActivityIndicator, ActivityIndicatorEnumType, LocaleContext } from '@unicaiot/unica-iot-gallery-core'
import { BuildingDetailGrid } from 'domain/Buildings/components/BuildingDetailGrid/BuildingDetailGrid'
import { useGetBuildingsDetailViewQuery } from 'domain/Buildings/services/buildingService/buildingService.hooks'
import { PointKpi, Type } from 'domain/Rooms/Sensors/service/types'
import { EntityFilter, Filters } from 'domain/Shared/EntityFilter/EntityFilter'
import { ErrorBlock } from 'domain/Shared/ErrorBlock/ErrorBlock'
import { LiveFilter } from 'domain/Shared/LiveFilter/LiveFilter'
import React, { Fragment, FunctionComponent, useContext, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { routes } from 'views/router/Authorized/routes'

interface RouteParams {
    id: string
}

interface Props extends RouteComponentProps<RouteParams> {}

export const BuildingDetailView: FunctionComponent<Props> = ({ match }) => {
    const locale = useContext(LocaleContext)
    const { id } = match.params
    const [filters, setFilters] = useState<Filters>()
    const { data, error, loading } = useGetBuildingsDetailViewQuery(
        {
            id,
            kpiType: filters?.kpiType as PointKpi,
            sensorType: filters?.sensorType as Type,
        },
        [filters]
    )

    return (
        <Column spacing={ColumnSpacing.spacing5}>
            <PageTitle
                renderActions={() => (
                    <Fragment>
                        <EntityFilter
                            onChange={filterValue => setFilters(filterValue)}
                            sortByLabel={<Trans id="BuildingDetailView.filters.sort">sorteren</Trans>}
                        />
                        <LiveFilter />
                        <MoreButton
                            topLinks={[
                                {
                                    to: routes.authorized.buildings.edit(id),
                                    label: locale._('buildingDetail.editBuilding'),
                                    icon: Icons.edit,
                                },
                                {
                                    to: routes.authorized.buildings.rooms.create({ buildingId: id }),
                                    label: locale._('Dashboard.addRoom'),
                                    icon: Icons.add,
                                },
                            ]}
                        />
                    </Fragment>
                )}
            >
                {data?.name}
            </PageTitle>
            {renderBuildingDetail()}
        </Column>
    )

    function renderBuildingDetail() {
        if (loading) {
            return (
                <Center grow={true}>
                    <ActivityIndicator size={ActivityIndicatorEnumType.large} />
                    <Trans id="common.loading">De data vanuit de datapunten wordt opgehaald.</Trans>
                </Center>
            )
        }

        if (error) {
            return (
                <Center grow={true}>
                    <ErrorBlock />
                </Center>
            )
        }

        if (data) {
            return <BuildingDetailGrid buildingDetail={data} />
        }
    }
}

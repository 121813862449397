import { RequestStatus, useRequest } from '@unicaiot/unica-iot-gallery-core'
import { DependencyList } from 'react'
import { buildingsService, BuildingsViewParamsType, BuildingViewDetailParamsType } from './buildingService'
import { Building, BuildingViewType } from './types'
import { BuildingExpandedRoomsByBuildingIdQuery, BuildingExpandedRoomsQuery } from '../types/buildingExpandedRooms'

export const useGetBuilding = (pointRef?: string): RequestStatus<Building | undefined> => {
    const getPromise = () => (pointRef ? buildingsService.getBuilding(pointRef) : undefined)

    return useRequest(getPromise, [pointRef])
}

export const useGetBuildingExpandedRoomsQuery = (): RequestStatus<BuildingExpandedRoomsQuery | undefined> => {
    const getPromise = () => buildingsService.getBuildingExpandedRooms()

    return useRequest(getPromise, [])
}

export const useGetBuildingExpandedRoomsByBuildingIdQuery = (
    buildingId: string
): RequestStatus<BuildingExpandedRoomsByBuildingIdQuery | undefined> => {
    const getPromise = () => buildingsService.getBuildingExpandedRoomsByBuildingId(buildingId)

    return useRequest(getPromise, [])
}

export const useGetBuildings = (pointRefs?: string[]): RequestStatus<Building[] | undefined> => {
    const getPromise = () =>
        pointRefs
            ? Promise.all(pointRefs.map(pointRef => buildingsService.getBuilding(pointRef))).then(r => {
                  return {
                      data: r.map(e => {
                          return e.data
                      }),
                      status: 200,
                      statusText: 'OK',
                      headers: {},
                      config: {},
                  }
              })
            : undefined

    return useRequest(getPromise, [pointRefs])
}

export const useGetBuildingsDetailViewQuery = (
    params: BuildingViewDetailParamsType,
    deps?: DependencyList
): RequestStatus<BuildingViewType | undefined> => {
    const getPromise = () => buildingsService.getBuildingDetailView(params)

    return useRequest(getPromise, deps)
}

export const useGetBuildingViews = (
    data?: {
        params?: BuildingsViewParamsType
    },
    deps?: DependencyList
): RequestStatus<BuildingViewType[] | undefined> => {
    const getPromise = () => buildingsService.getBuildingViews(data?.params)

    return useRequest(getPromise, deps)
}

export const useGetBuildingImage = (id: string, deps?: DependencyList): RequestStatus<ArrayBuffer | undefined> => {
    const getPromise = () => buildingsService.getBuildingImage(id)
    return useRequest(getPromise, deps)
}

export const useGetBuildingsExpandedData = (): RequestStatus<BuildingViewType[] | undefined> => {
    const getPromise = () => buildingsService.getBuildingsExpandedData()

    return useRequest(getPromise)
}

import {
    Button,
    ButtonStyleType,
    Column,
    ColumnSpacing,
    DashedContainer,
    FontSize,
    HeadingLevel,
    Icons,
    JustifyContent,
    Row,
    Title,
} from '@unicaiot/unica-iot-gallery-buildinginsight'
import React from 'react'
import styles from './EmptyState.module.scss'

interface Props {
    title: string
    infoText: string
    button: ButtonType
}

interface ButtonType {
    text: string
    onClick: () => void
}

export const EmptyState: React.FC<Props> = props => {
    const { title, infoText, button } = props

    return (
        <DashedContainer className={styles.container}>
            <Column spacing={ColumnSpacing.spacing3}>
                <Row justifyContent={JustifyContent.center}>
                    <Title headingLevel={HeadingLevel.h2} fontSize={FontSize.size22}>
                        {title}
                    </Title>
                </Row>
                <Row justifyContent={JustifyContent.center}>{infoText}</Row>
                <Row justifyContent={JustifyContent.center}>
                    <Button icon={Icons.add} buttonStyle={ButtonStyleType.primary} onClick={button.onClick}>
                        {button.text}
                    </Button>
                </Row>
            </Column>
        </DashedContainer>
    )
}

import {
    Button,
    ButtonStyleType,
    FontSize,
    HeadingLevel,
    TextButton,
    Title,
} from '@unicaiot/unica-iot-gallery-buildinginsight'
import { LocaleContext } from '@unicaiot/unica-iot-gallery-core'
import React, { FC, useContext } from 'react'
import styles from './SensorSaveModal.module.scss'

interface Props {
    onSave?: () => void
    onCancel?: () => void
}

export const SensorSaveModal: FC<Props> = props => {
    const { onCancel, onSave } = props
    const locale = useContext(LocaleContext)

    return (
        <div className={styles.container}>
            <Title className={styles.title} headingLevel={HeadingLevel.h4} fontSize={FontSize.size16}>
                {locale._(`sensorsForm.saveSensorModal.title`)}
            </Title>
            <div className={styles.buttonContainer}>
                <TextButton onClick={onCancel} buttonStyle={ButtonStyleType.secondary}>
                    {locale._(`sensorsForm.saveSensorModal.cancel`)}
                </TextButton>

                <Button buttonStyle={ButtonStyleType.primary} onClick={onSave}>
                    {locale._(`sensorsForm.saveSensorModal.save`)}
                </Button>
            </div>
        </div>
    )
}

import { client, ResponsePromise } from '@unicaiot/unica-iot-gallery-core'
import { Mapping } from 'classnames'
import dateFormat from 'dateformat'
import { BuildingViewType, HData } from 'domain/Buildings/services/buildingService/types'
import { DataPointsDetailBaseParams } from 'views/router/Authorized/Building/Rooms/Datapoints/routes'
import { config } from '../../../../config'
import { RegisterSensor, Sensor, PointKpi } from './types'

export interface IExplorerService {
    getGraphData: (params: SensorDetailViewParamsType) => ResponsePromise<HData>
    getSensorDetailView: (params: DataPointsDetailBaseParams) => ResponsePromise<BuildingViewType>
    putSensor: (sensor: Sensor) => ResponsePromise
    registration: (id: string) => ResponsePromise<RegisterSensor>
    getSensor: (id: string) => ResponsePromise<Sensor>
    deleteEntity: (id: string) => ResponsePromise
    getSensorsListViewData: (params?: SensorListViewParamsType) => ResponsePromise<Sensor[]>
    getMappingDetails: (id: string) => ResponsePromise<Mapping>
}

export interface SensorDetailData {
    value: number
    time: Date
}

export interface SensorFormData {
    buildingRef: string
    roomRef: string
}

export interface SensorListViewParamsType {
    page?: number
    pageCount?: number
    kpiType?: PointKpi
    sensorType?: Sensor
    excludeSensors?: boolean
    orderByProperty?: string
    orderByType?: string
}

export interface SensorDetailViewParamsType {
    id: string
    startDate: Date
    endDate: Date
}

export const sensorsService: IExplorerService = {
    getGraphData(params: SensorDetailViewParamsType) {
        function getFormatedDate(date: Date) {
            return dateFormat(date, 'yyyy-mm-dd')
        }
        const { id } = params

        return client.get(config.hdata.get, {
            params: {
                id: `@${id}`,
                range: `"${getFormatedDate(params.endDate)},${getFormatedDate(params.startDate)}"`,
            },
        })
    },
    getSensorDetailView(params: DataPointsDetailBaseParams) {
        const { buildingId, roomId, dataPointId, ...rest } = params
        return client.get<BuildingViewType>(config.getSensorDetailView(buildingId, roomId, dataPointId), {
            params: rest,
        })
    },
    putSensor(sensor: Sensor) {
        return client.put(config.sensors(sensor.id), { buildingRef: sensor.buildingRef, roomRef: sensor.roomRef })
    },
    registration(id: string) {
        return client.get(config.registration(id))
    },
    deleteEntity(id: string) {
        return client.delete(config.entities('sensors', id))
    },
    getSensorsListViewData(params?: SensorListViewParamsType) {
        return client.get<Sensor[]>(config.sensorsListView, {
            params: {
                ['Paging.Page']: params?.page,
                ['Paging.PageCount']: params?.pageCount,
                ['Filter.KpiType']: params?.kpiType,
                ['Filter.SensorType']: params?.sensorType,
                ['OrderBy.Property']: params?.orderByProperty,
                ['OrderBy.Type']: params?.orderByType,
            },
        })
    },
    getMappingDetails(id: string) {
        return client.get<Mapping>(config.mapping(id))
    },
    getSensor(id: string) {
        return client.get<Sensor>(config.entities('sensors', id))
    },
}

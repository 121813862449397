import classNames, { Argument } from 'classnames'
import React from 'react'
import styles from './EditSensorTextBlock.module.scss'
import { InfoText, TextSizeEnumType, TextWeightEnumType } from '@unicaiot/unica-iot-gallery-buildinginsight'

interface Props {
    title: string
    description: string
    className?: Argument
}

export const EditSensorTextBlock: React.FC<Props> = props => {
    const { title, description, className } = props
    const classes = classNames(styles.container, className)

    return (
        <div className={classes}>
            <InfoText className={styles.title} size={TextSizeEnumType.large} weight={TextWeightEnumType.bold}>
                {title}
            </InfoText>
            <InfoText>{description}</InfoText>
        </div>
    )
}

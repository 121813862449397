import React, { FunctionComponent, useCallback, useContext, useEffect, useState } from 'react'

import classNames, { Argument } from 'classnames'
import { Column, FilterButton, Select } from '@unicaiot/unica-iot-gallery-buildinginsight'
import { Trans } from '@lingui/macro'
import { LocaleContext } from '@unicaiot/unica-iot-gallery-core'
import { useHistory } from 'react-router-dom'
import { PointKpi, Type } from 'domain/Rooms/Sensors/service/types'

interface Props {
    className?: Argument
    sortByLabel: JSX.Element | string
    onChange?: (filters?: Filters) => void
}

export interface Filters {
    sort?: string
    kpiType?: string
    sensorType?: string
    orderByType?: string
}

export enum OrderByType {
    Asc = 'Asc',
    Desc = 'Desc',
}

export const EntityFilter: FunctionComponent<Props> = ({ className, sortByLabel, onChange }) => {
    const locale = useContext(LocaleContext)
    const history = useHistory()
    const [activeFilters, setActiveFilters] = useState<Filters>({
        sort: undefined,
        kpiType: undefined,
        sensorType: undefined,
        orderByType: OrderByType.Asc,
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onFilterChange = useCallback((filters?: Filters) => onChange && onChange(filters), [])

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search)
        const sort = urlParams.get('sort') || undefined
        const kpiType = urlParams.get('kpiType') || undefined
        const sensorType = urlParams.get('sensorType') || undefined
        const orderByType = urlParams.get('orderByType') || OrderByType.Asc

        setActiveFilters({
            sort,
            kpiType,
            sensorType,
            orderByType,
        })
    }, [])

    useEffect(() => {
        if (onFilterChange) {
            onFilterChange(activeFilters)
        }

        const params = new URLSearchParams()
        if (activeFilters?.sort) {
            params.append('sort', activeFilters.sort)
        }
        if (activeFilters?.kpiType) {
            if (activeFilters.kpiType === 'not-sorted') {
                params.delete('kpiType')
            } else {
                params.append('kpiType', activeFilters.kpiType)
            }
        }
        history.push({ search: params.toString() })
    }, [activeFilters, history, onFilterChange])

    return (
        <FilterButton
            className={classNames(className)}
            dropDownContent={
                <Column spacing={2}>
                    <Select
                        label={sortByLabel}
                        value={activeFilters?.sort}
                        options={[
                            {
                                label: locale._('entityFilters.buildingOptions.byName'),
                                value: 'by-name',
                            },
                            {
                                label: locale._('entityFilters.buildingOptions.byDate'),
                                value: 'by-date',
                            },
                        ]}
                        onChange={value => setActiveFilters({ ...activeFilters, sort: value })}
                    />
                    <Select
                        label={locale._({ id: 'entityFilters.orderByType', message: 'Volgorde' })}
                        value={activeFilters?.orderByType}
                        options={[
                            {
                                label: locale._({
                                    id: 'entityFilters.orderByType.ascending',
                                    message: 'Oplopend',
                                }),
                                value: OrderByType.Asc,
                            },
                            {
                                label: locale._({
                                    id: 'entityFilters.orderByType.descending',
                                    message: 'Aflopend',
                                }),
                                value: OrderByType.Desc,
                            },
                        ]}
                        onChange={value => setActiveFilters({ ...activeFilters, orderByType: value })}
                    />
                    <Select
                        label={<Trans id="entityFilters.averageValues">gemiddelde waarden</Trans>}
                        value={activeFilters?.kpiType}
                        options={[
                            {
                                label: locale._('entityFilters.averageValuesOptions.notSorted'),
                                value: '',
                            },
                            {
                                label: locale._('entityFilters.averageValuesOptions.good'),
                                value: PointKpi.Sufficient,
                            },
                            {
                                label: locale._('entityFilters.averageValuesOptions.average'),
                                value: PointKpi.Moderate,
                            },
                            {
                                label: locale._('entityFilters.averageValuesOptions.bad'),
                                value: PointKpi.InSufficient,
                            },
                        ]}
                        onChange={value => setActiveFilters({ ...activeFilters, kpiType: value })}
                    />
                    <Select
                        label={locale._({ id: 'entityFilters.sensorType', message: 'Sensor type' })}
                        value={activeFilters?.sensorType}
                        options={[
                            {
                                label: locale._({
                                    id: 'entityFilters.sensorTypeOptions.notSorted',
                                    message: 'Niet gesorteerd',
                                }),
                                value: '',
                            },
                            {
                                label: locale._({
                                    id: 'entityFilters.sensorTypeOptions.airQuality',
                                    message: 'Luchtkwaliteit',
                                }),
                                value: Type.CO2,
                            },
                            {
                                label: locale._({
                                    id: 'entityFilters.sensorTypeOptions.humidity',
                                    message: 'Luchtvochtigheid',
                                }),
                                value: Type.humidity,
                            },
                            {
                                label: locale._({
                                    id: 'entityFilters.sensorTypeOptions.temperature',
                                    message: 'Temperatuur',
                                }),
                                value: Type.temperature,
                            },
                        ]}
                        onChange={value => setActiveFilters({ ...activeFilters, sensorType: value })}
                    />
                </Column>
            }
        >
            Filter
        </FilterButton>
    )
}

import { Button, ButtonStyleType, Center } from '@unicaiot/unica-iot-gallery-buildinginsight'
import { LocaleContext } from '@unicaiot/unica-iot-gallery-core'
import classNames, { Argument } from 'classnames'
import { Sensor } from 'domain/Rooms/Sensors/service/types'
import React, { useContext, useState } from 'react'
import { RegisterSensorSuccessAccordion } from '../RegisterSensorSuccessAccordion/RegisterSensorSuccessAccordion'
import { RegisterSensorSuccessHeader } from '../RegisterSensorSuccessHeader/RegisterSensorSuccessHeader'
import styles from './RegisterSensorSuccessContainer.module.scss'

interface Props {
    className?: Argument
    data: Sensor
}

export const RegisterSensorSuccessContainer: React.FC<Props> = props => {
    const { className, data } = props
    const [open, setOpen] = useState<boolean>(false)
    const classes = classNames(
        styles.container,
        {
            [styles.isCentered]: !open,
        },
        className
    )
    const locale = useContext(LocaleContext)

    return (
        <div className={classes}>
            <RegisterSensorSuccessHeader withBackground={open} />
            {renderAccordion()}
        </div>
    )

    function renderAccordion() {
        if (!open) {
            return (
                <Center>
                    <Button buttonStyle={ButtonStyleType.primary} onClick={() => setOpen(true)}>
                        {locale._({ id: 'registerSensorSuccessAccordion.button', message: 'Sensor gegevens bekijken' })}
                    </Button>
                </Center>
            )
        }

        return <RegisterSensorSuccessAccordion data={data} open={open} onClick={() => setOpen(false)} />
    }
}

import React, { Fragment, useContext, useState } from 'react'
import {
    Center,
    Column,
    ColumnSpacing,
    Icons,
    InfoText,
    Modal,
    MoreButton,
    PageTitle,
    PromptModalView,
    Table,
    TableItem,
    TextColorEnumType,
    useModal,
} from '@unicaiot/unica-iot-gallery-buildinginsight'
import { EntityFilter } from 'domain/Shared/EntityFilter/EntityFilter'
import { Trans } from '@lingui/macro'
import { LiveFilter } from 'domain/Shared/LiveFilter/LiveFilter'
import { routes } from '../../routes'
import { ActivityIndicator, ActivityIndicatorEnumType, LocaleContext } from '@unicaiot/unica-iot-gallery-core'
import { ErrorBlock } from 'domain/Shared/ErrorBlock/ErrorBlock'
import { Sensor } from 'domain/Rooms/Sensors/service/types'
import { RoomTableHeader } from 'domain/Rooms/components/RoomTableHeader/RoomTableHeader'
import { useHistory } from 'react-router-dom'
import { DataPointItem } from 'domain/Rooms/components/DataPointItem/DataPointItem'
import moment from 'moment'
import { getDataPointType } from 'utils/getDataPointType'
import { getDataPointUnit } from 'utils/getDataPointUnit'
import { RegisterSensorModal } from 'domain/DataPoints/Components/RegisterSensorModal/RegisterSensorModal'
import { SortFunctions } from 'utils/sortByValues'
import { useGetSensorsListViewData } from 'domain/Rooms/Sensors/service/sensorsService.hooks'
import { typeTranslations } from 'domain/DataPoints/translations/translations'

enum TableHeaders {
    type = 'type',
    building = 'building',
    space = 'space',
    value = 'value',
    checkedOutOn = 'checkedOutOn',
    sensorCode = 'sensorCode',
}

const tableHeaders = [
    TableHeaders.type,
    TableHeaders.building,
    TableHeaders.space,
    TableHeaders.value,
    TableHeaders.checkedOutOn,
    TableHeaders.sensorCode,
]

export type HeaderTranslationsType = {
    [key in keyof typeof TableHeaders]: string
}

export const AllDataPointsView: React.FC = () => {
    const [filter, setFilter] = useState<TableHeaders>()
    const [filteredList, setFilteredList] = useState<Sensor[]>()
    const [registerSensorModal, modalActions] = useModal()
    const { data, error, loading } = useGetSensorsListViewData()
    const history = useHistory()
    const locale = useContext(LocaleContext)

    const headerTranslations: HeaderTranslationsType = {
        [TableHeaders.type]: locale._({ id: 'DataPointsList.headers.type', message: 'Type' }),
        [TableHeaders.building]: locale._({ id: 'DataPointsList.headers.building', message: 'Gebouw' }),
        [TableHeaders.space]: locale._({ id: 'DataPointsList.headers.space', message: 'Specifieke ruimte' }),
        [TableHeaders.value]: locale._({ id: 'DataPointsList.headers.value', message: 'Sensor waarde' }),
        [TableHeaders.checkedOutOn]: locale._({ id: 'DataPointsList.headers.checkedOutOn', message: 'Gewijzigd op' }),
        [TableHeaders.sensorCode]: locale._({ id: 'DataPointsList.headers.sensorCode', message: 'Sensorcode' }),
    }

    return (
        <Column spacing={ColumnSpacing.spacing5}>
            <PageTitle
                renderActions={() => (
                    <Fragment>
                        <EntityFilter
                            sortByLabel={locale._({
                                id: 'AllDataPoints.filters.sortSensors',
                                message: 'sensoren sorteren',
                            })}
                        />
                        <LiveFilter />
                        <MoreButton
                            topLinks={[
                                {
                                    onClick: modalActions.actions.openModal,
                                    label: locale._({ id: 'AllDataPoints.addSensor', message: 'Sensor toevoegen' }),
                                    icon: Icons.add,
                                },
                            ]}
                        />
                    </Fragment>
                )}
            >
                <Trans id="allDataPoints.title">Alle Datapunten</Trans>
            </PageTitle>
            {renderList()}
        </Column>
    )

    function renderList() {
        if (error) {
            return (
                <Center grow={true}>
                    <ErrorBlock />
                </Center>
            )
        }

        if (loading) {
            return (
                <Center grow={true}>
                    <ActivityIndicator size={ActivityIndicatorEnumType.large} />
                    <Trans id="common.loading">De data vanuit de datapunten wordt opgehaald.</Trans>
                </Center>
            )
        }

        if (data) {
            const list = filteredList ?? data

            return (
                <>
                    <Table headers={renderHeaders(data)} rows={renderListItems(list)} flex={1} />
                    <Modal ref={registerSensorModal}>
                        <PromptModalView>
                            <RegisterSensorModal onCancel={modalActions.actions.closeModal} />
                        </PromptModalView>
                    </Modal>
                </>
            )
        }
    }

    function renderListItems(data?: Sensor[]) {
        const jsx = data?.map((sensor, index) => {
            return (
                <TableItem
                    key={index}
                    onClick={() =>
                        history.push(
                            routes.authorized.buildings.rooms.dataPoints.detail({
                                buildingId: sensor.buildingRef,
                                roomId: sensor.roomRef,
                                dataPointId: sensor.id,
                            })
                        )
                    }
                    data={[
                        <InfoText key={index}>{locale._(typeTranslations[sensor.type])}</InfoText>,
                        <InfoText key={index}>{sensor.buildingName}</InfoText>,
                        <InfoText key={index}>{sensor.roomName}</InfoText>,
                        <DataPointItem
                            key={index}
                            value={`${sensor.latestKpi.value} ${getDataPointUnit(sensor.type)}`}
                            status={sensor.latestKpi.kpi}
                            type={getDataPointType(sensor.type)}
                        />,
                        <InfoText color={TextColorEnumType.grey} key={index}>
                            {moment(sensor.modificationDate).format('h:mm - DD-MM-YYYY')}
                        </InfoText>,
                        <InfoText color={TextColorEnumType.grey} key={index}>
                            {sensor.name}
                        </InfoText>,
                    ]}
                    spacing={'2'}
                    flex={1}
                />
            )
        })
        return jsx
    }

    function renderHeaders(sensors?: Sensor[]) {
        return tableHeaders.map((text, index) => {
            return (
                <RoomTableHeader
                    key={index}
                    headingText={headerTranslations[text]}
                    onClick={() => {
                        if (filter === text) {
                            setFilter(undefined)
                            setFilteredList(undefined)
                            return
                        }

                        setFilter(text)
                        filterData(text, sensors)
                    }}
                    isOpen={filter === text}
                />
            )
        })
    }

    function filterData(filter?: TableHeaders, data?: Sensor[]) {
        if (data) {
            let sensors = data

            switch (filter) {
                case TableHeaders.type:
                    sensors = SortFunctions.sortByType(data)
                    break
                case TableHeaders.value:
                    sensors = SortFunctions.sortByValues(data)
                    break
                case TableHeaders.checkedOutOn:
                    sensors = SortFunctions.sortByDates(data)
                    break
                case TableHeaders.space:
                    sensors = SortFunctions.sortBySpace(data)
                    break
                case TableHeaders.building:
                    sensors = SortFunctions.sortByAlphabeticOrder(data)
                    break
                case TableHeaders.sensorCode:
                    sensors = SortFunctions.sortBySensorCode(data)
                    break
            }
            setFilteredList(sensors)
        }
    }
}

import React, { useContext } from 'react'
import { RegisterSensorForm } from 'domain/RegisterSensor/RegisterSensorForm/RegisterSensorForm'
import { RegisterFormContainer } from 'domain/RegisterSensor/RegisterSensorFormContainer/RegisterSensorFormContainer'
import { RegisterFormHeader } from 'domain/RegisterSensor/RegisterSensorFormHeader/RegisterSensorFormHeader'
import { useParams } from 'react-router-dom'
import { RegisterSensorDetailParams, routes } from '../routes'
import { Center } from '@unicaiot/unica-iot-gallery-buildinginsight'
import { ErrorBlock } from 'domain/Shared/ErrorBlock/ErrorBlock'
import { ActivityIndicator, ActivityIndicatorEnumType, LocaleContext } from '@unicaiot/unica-iot-gallery-core'
import { Trans } from '@lingui/macro'
import { useSubmitSensor } from 'domain/Rooms/Sensors/hooks/useSubmitSensor'
import { useGetRegistration, useMapping } from 'domain/Rooms/Sensors/service/sensorsService.hooks'

export const RegisterSensorView: React.FC = () => {
    const { id } = useParams<RegisterSensorDetailParams>()
    const { data, loading, error } = useMapping(id)

    const registrationRequest = useGetRegistration(data?.value)

    const { onSubmit } = useSubmitSensor(routes.authorized.registration.registerSucces(data?.value))
    const locale = useContext(LocaleContext)

    if (error) {
        return <ErrorBlock>{error}</ErrorBlock>
    }

    if (registrationRequest.error) {
        return <ErrorBlock>{registrationRequest.error}</ErrorBlock>
    }

    return <RegisterFormContainer>{renderRegisterSensorView()}</RegisterFormContainer>

    function renderRegisterSensorView() {
        if (error) {
            return (
                <Center grow={true}>
                    <ErrorBlock />
                </Center>
            )
        }

        if (loading) {
            return (
                <Center grow={true}>
                    <ActivityIndicator size={ActivityIndicatorEnumType.large} />
                    <Trans id="common.loading">De data vanuit de datapunten wordt opgehaald.</Trans>
                </Center>
            )
        }

        if (data) {
            return (
                <>
                    <RegisterFormHeader
                        title={locale._({ id: 'registerSensor.title', message: 'Gefeliciteerd met je nieuwe sensor!' })}
                        text={locale._({
                            id: 'registerSensor.message',
                            message:
                                'Je kunt de sensor nu toewijzen aan de gewenste locatie binnen een gebouw en ruimte.',
                        })}
                    />
                    <RegisterSensorForm
                        loading={loading}
                        onSave={onSubmit}
                        id={data.value}
                        data={registrationRequest}
                    />
                </>
            )
        }
    }
}

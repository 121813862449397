import { unauthorizedRoutes } from '@unicaiot/unica-iot-gallery-buildinginsight'
import { buildingsRoutes } from './Building/routes'
import { userRoutes } from './Menu/Users/routes'

export interface RegisterSensorDetailParams {
    id: string
}

export const routes = {
    unauthorized: unauthorizedRoutes,
    authorized: {
        index: '/',
        buildings: buildingsRoutes,
        allDataPoints: '/all-datapoints',
        users: userRoutes,
        registration: {
            index: '/registration',
            register: (id: string = ':id') => `/registration/${id}`,
            registerSucces: (id: string = ':id') => `/registration/${id}/success`,
        },
    },
}

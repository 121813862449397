import { Building } from 'domain/Buildings/services/buildingService/types'

export interface Sensor extends ISensor {
    latestKpi: LatestValue
    buildingRef: string
    buildingName: string
    roomRef: string
    roomName: string
    kind: string
    modificationDate: Date
    type: Type
    unit: string
}

export enum PointKpi {
    Sufficient = 'Sufficient',
    Moderate = 'Moderate',
    InSufficient = 'InSufficient',
    Unknown = 'Unknown',
}

export interface ISensor {
    id: string
    name: string
    identifier: string
}

export enum Type {
    CO2 = 'CO2',
    temperature = 'temperature',
    humidity = 'humidity',
}

export interface LatestValue {
    kpi: PointKpi
    value: number
    time: string
}

export interface Mapping {
    tenantId: string
    value: string
    parameters: {
        devEUI: string
        code: string
    }
    id: string
}

export interface RegisterSensor {
    buildings: Building[]
    sensor: Sensor
}

export interface HData {
    meta: {
        id: string
    }
    rows: HDataRow[]
}

export interface HDataRow {
    ts: string
    val: string
}

import {
    ActionMenuItem,
    Auth,
    AuthorizedContentLayout,
    Header,
    HeaderItem,
    Icons,
} from '@unicaiot/unica-iot-gallery-buildinginsight'
import { config, LocaleContext, RedirectorTemplate, useUser } from '@unicaiot/unica-iot-gallery-core'
import React, { FunctionComponent, useContext } from 'react'
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom'
import { BuildingsView } from './Building/BuildingsView'
import { MenuView } from './Menu/MenuView'
import { RegisterSensorSuccessView } from './RegisterSensor/RegisterSensorSuccessView'
import { RegisterSensorView } from './RegisterSensor/RegisterSensorView'
import { routes } from './routes'

const Redirector: FunctionComponent<RouteComponentProps> = props => {
    return (
        <RedirectorTemplate
            {...props}
            getRedirector={params => {
                let path = config.defaultPath

                if (params.c) {
                    path = routes.authorized.registration.register(`${params.c}`)
                }

                return <Redirect to={path} />
            }}
        />
    )
}

export const AuthorizedView: FunctionComponent = () => {
    const locale = useContext(LocaleContext)
    const user = useUser()

    const headerItems: HeaderItem[] = [
        {
            to: '',
            label: locale._('menuItems.main.energy'),
            icon: Icons['module-energy'],
            disabled: true,
        },
        {
            to: '',
            label: locale._('menuItems.main.water'),
            icon: Icons['module-water'],
            disabled: true,
        },
        {
            to: '',
            label: locale._('menuItems.main.occupancy'),
            icon: Icons['module-occupancy'],
            disabled: true,
        },
        {
            to: '',
            label: locale._('menuItems.main.mobility'),
            icon: Icons['module-mobility'],
            disabled: true,
        },
        {
            to: '',
            label: locale._('menuItems.main.air'),
            icon: Icons['module-air'],
            disabled: true,
        },
        {
            to: routes.authorized.buildings.index,
            label: locale._('menuItems.main.buildings'),
        },
    ]

    const actionMenuItems: ActionMenuItem[] = [
        {
            to: routes.authorized.users.index,
            label: locale._('menuItems.actions.users'),
        },
        {
            to: routes.authorized.users.detail(user?.sub),
            label: locale._('menuItems.actions.profile'),
        },
        {
            to: routes.authorized.allDataPoints,
            label: locale._('menuItems.actions.datapoints'),
        },
        {
            to: '/placeholder',
            label: locale._('menuItems.actions.settings'),
        },
    ]

    return (
        <Auth>
            {onLogout => (
                <Switch>
                    <Route
                        path={routes.authorized.registration.register()}
                        exact={true}
                        component={RegisterSensorView}
                    />
                    <Route
                        path={routes.authorized.registration.registerSucces()}
                        exact={true}
                        component={RegisterSensorSuccessView}
                    />
                    <Route exact={true} path={routes.authorized.index} component={Redirector} />
                    <AuthorizedContentLayout
                        header={
                            <Header onLogout={onLogout} headerItems={headerItems} actionMenuItems={actionMenuItems} />
                        }
                    >
                        <Switch>
                            <Route path={routes.authorized.buildings.index} component={BuildingsView} />
                            <Route component={MenuView} />
                        </Switch>
                    </AuthorizedContentLayout>
                </Switch>
            )}
        </Auth>
    )
}

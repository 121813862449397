import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { routes } from '../routes'
import { AllDataPointsView } from './AllDataPoints/AllDataPointsView'
import { UsersView } from './Users/UsersView'

export const MenuView: React.FC = () => {
    return (
        <Switch>
            <Route path={routes.authorized.allDataPoints} exact={true} component={AllDataPointsView} />
            <Route path={routes.authorized.users.index} component={UsersView} />
        </Switch>
    )
}

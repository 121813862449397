export interface User {
    active: boolean
    email: string
    firstName: string
    id: string
    lastName: string
    role: Roles | Roles[]
    location: string
}

export interface EditPasswordFormData {
    oldPassword: string
    password1: string
    password2: string
}

export interface DeleteUserFormData {
    id: string
    password: string
}

export interface CreateUserFormData {
    email: string
    firstname: string
    lastname: string
    password?: string
    role?: string
}
export enum Roles {
    superAdmin = 'co2API.SuperAdmin',
    admin = 'co2API.Admin',
}

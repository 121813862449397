import React, { FunctionComponent } from 'react'
import { Route, Switch } from 'react-router-dom'
import { BuildingDetailView } from 'views/pages/Buildings/BuidlingDetailView/BuildingDetailView'
import { BuildingsOverviewView } from 'views/pages/Buildings/BuildingsOverviewView/BuildingsOverviewView'
import { CreateBuildingsView } from 'views/pages/Buildings/CreateBuildingsView/CreateBuildingsView'
import { EditBuildingView } from 'views/pages/Buildings/EditBuildingView/EditBuildingView'
import { routes } from '../routes'
import { RoomsView } from './Rooms/RoomsView'

interface Props {}

export const BuildingsView: FunctionComponent<Props> = () => {
    return (
        <Switch>
            <Route path={routes.authorized.buildings.index} exact={true} component={BuildingsOverviewView} />
            <Route path={routes.authorized.buildings.create} exact={true} component={CreateBuildingsView} />
            <Route path={routes.authorized.buildings.detail()} exact={true} component={BuildingDetailView} />
            <Route path={routes.authorized.buildings.edit()} exact={true} component={EditBuildingView} />
            <Route path={routes.authorized.buildings.rooms.detail()} component={RoomsView} />
        </Switch>
    )
}

import React from 'react'
import { FontSize, HeadingLevel, InfoText, Title, TitleColor } from '@unicaiot/unica-iot-gallery-buildinginsight'
import styles from './RegisterSensorFormHeader.module.scss'

interface Props {
    title: string
    text: string
}

export const RegisterFormHeader: React.FC<Props> = props => {
    const { title, text } = props

    return (
        <div className={styles.container}>
            <div className={styles.contentContainer}>
                <Title headingLevel={HeadingLevel.h2} fontSize={FontSize.size22} color={TitleColor.dark}>
                    {title}
                </Title>
                <InfoText className={styles.text}>{text}</InfoText>
            </div>
        </div>
    )
}

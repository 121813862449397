import { RequestStatus, useRequest } from '@unicaiot/unica-iot-gallery-core'
import { BuildingViewType } from 'domain/Buildings/services/buildingService/types'
import { DependencyList } from 'react'
import { RoomsDetailBaseParams } from 'views/router/Authorized/Building/Rooms/routes'
import { roomsService } from './roomsService'
import { Room } from './types'

export const useGetRoom = (pointRef?: string): RequestStatus<Room | undefined> => {
    const getPromise = () => (pointRef ? roomsService.getRoom(pointRef) : undefined)

    return useRequest(getPromise, [pointRef])
}

export const useGetRooms = (pointRefs?: string[]): RequestStatus<Room[] | undefined> => {
    const getPromise = () =>
        pointRefs
            ? Promise.all(pointRefs.map(pointRef => roomsService.getRoom(pointRef))).then(r => {
                  return {
                      data: r.map(e => {
                          return e.data
                      }),
                      status: 200,
                      statusText: 'OK',
                      headers: {},
                      config: {},
                  }
              })
            : undefined

    return useRequest(getPromise, [pointRefs])
}

export const useGetRoomViews = (
    params: RoomsDetailBaseParams,
    deps?: DependencyList
): RequestStatus<BuildingViewType | undefined> => {
    const getPromise = () => roomsService.getRoomViews(params)
    return useRequest(getPromise, deps)
}

export const useGetRoomsListDetailViews = (
    params: RoomsDetailBaseParams
): RequestStatus<BuildingViewType | undefined> => {
    const getPromise = () => roomsService.getRoomListViews(params)

    return useRequest(getPromise)
}

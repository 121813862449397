import {
    Column,
    ColumnSpacing,
    FontSize,
    HeadingLevel,
    Icon,
    Icons,
    InfoText,
    Title,
    TitleColor,
} from '@unicaiot/unica-iot-gallery-buildinginsight'
import { LocaleContext } from '@unicaiot/unica-iot-gallery-core'
import classNames, { Argument } from 'classnames'
import React, { useContext } from 'react'
import styles from './RegisterSensorSuccessHeader.module.scss'

interface Props {
    withBackground: boolean
    className?: Argument
}

export const RegisterSensorSuccessHeader: React.FC<Props> = props => {
    const { withBackground, className } = props
    const locale = useContext(LocaleContext)
    const classes = classNames(
        styles.container,
        {
            [styles.withBackground]: withBackground,
        },
        className
    )

    return (
        <div className={classes}>
            <Column className={styles.contentContainer} spacing={ColumnSpacing.spacing4}>
                <Icon icon={Icons.checkmark} className={styles.icon} />
                <Title
                    headingLevel={HeadingLevel.h2}
                    fontSize={FontSize.size22}
                    color={TitleColor.dark}
                    className={styles.text}
                >
                    {locale._({
                        id: 'registerSensorSuccessHeader.title',
                        message: 'Je sensor is succesvol geregistreerd.',
                    })}
                </Title>
                <InfoText className={styles.text}>
                    {locale._({
                        id: 'registerSensorSuccessHeader.message',
                        message:
                            'De sensor is toegevoegd aan onderstaand gebouw en ruimte. Eventuele beschikbare data is direct zichtbaar.',
                    })}
                </InfoText>
            </Column>
        </div>
    )
}

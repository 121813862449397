import { RequestStatus, useRequest } from '@unicaiot/unica-iot-gallery-core'
import { User } from './types'
import { usersService } from './usersService'

export const useGetAllUsers = (deps?: Array<any>): RequestStatus<User[] | undefined> => {
    const getPromise = () => usersService.getAllUsers()

    return useRequest(getPromise, deps)
}

export const useGetUser = (id: string): RequestStatus<User | undefined> => {
    const getPromise = () => usersService.getUser(id)

    return useRequest(getPromise)
}

import React from 'react'
import {
    InfoText,
    TextColorEnumType,
    TextSizeEnumType,
    TextWeightEnumType,
} from '@unicaiot/unica-iot-gallery-buildinginsight'
import styles from './DataPointsDetailItem.module.scss'

interface Props {
    label: string
    value: string
}

export const DataPointsDetailItem: React.FC<Props> = props => {
    const { label, value } = props

    return (
        <>
            <InfoText size={TextSizeEnumType.small} color={TextColorEnumType.grey}>
                {label}
            </InfoText>
            <InfoText size={TextSizeEnumType.large} weight={TextWeightEnumType.bold} className={styles.value}>
                {value}
            </InfoText>
        </>
    )
}

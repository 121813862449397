import { useGetBuildingExpandedRoomsByBuildingIdQuery } from 'domain/Buildings/services/buildingService/buildingService.hooks'
import { useParams } from 'react-router-dom'
import { RoomsDetailBaseParams } from 'views/router/Authorized/Building/Rooms/routes'

export function useBuildingOverviewViewComponent() {
    const { buildingId } = useParams<RoomsDetailBaseParams>()
    const { data, loading, error } = useGetBuildingExpandedRoomsByBuildingIdQuery(buildingId)
    const expandedRooms = data ? [data] : []

    return { data: { expandedRooms, loading, error }, actions: {} }
}

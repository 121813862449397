import styles from './RegisterSensorModal.module.scss'
import React, { useContext } from 'react'
import {
    Title,
    InfoText,
    HeadingLevel,
    FontSize,
    AnimatedHighlightListItem,
    AnimatedHighlightList,
    QrCodeScanVisual,
    PhoneInteractionVisual,
    IconButton,
    Icons,
} from '@unicaiot/unica-iot-gallery-buildinginsight'
import { LocaleContext } from '@unicaiot/unica-iot-gallery-core'
import { Trans } from '@lingui/macro'

interface Props {
    onCancel?: () => void
}

export const RegisterSensorModal: React.FunctionComponent<Props> = props => {
    const { onCancel } = props
    const locale = useContext(LocaleContext)
    return (
        <div className={styles.container}>
            <div className={styles.headerContainer}>
                <IconButton onClick={onCancel} icon={Icons.close} />
            </div>

            <Title className={styles.title} fontSize={FontSize.size20} headingLevel={HeadingLevel.h1}>
                {locale._({ id: 'RegisterSensorModal.title', message: 'Sensor registeren' })}
            </Title>
            <InfoText>
                <Trans id={'RegisterSensorModal.infoRightText'}>
                    Gefeliciteerd met je nieuwe sensor! Om deze toe te voegen heb je een QR sanner nodig mettoegang tot
                    het internet.
                    <br />
                    <br />
                    Bijvoorbeeld een smartphone of tablet.
                </Trans>
            </InfoText>
            <AnimatedHighlightList
                className={styles.stepList}
                data={[
                    state => (
                        <AnimatedHighlightListItem active={state.active} initial={state.initial}>
                            <div className={styles.stepWrapper}>
                                <QrCodeScanVisual animate={state.animateIn} />
                                <div className={styles.stepTextWrapper}>
                                    <Title
                                        className={styles.title}
                                        fontSize={FontSize.size16}
                                        headingLevel={HeadingLevel.h3}
                                    >
                                        {locale._({
                                            id: 'RegisterSensorModal.step1Title',
                                            message: 'Stap 1',
                                        })}
                                    </Title>
                                    <InfoText>
                                        {locale._({
                                            id: 'RegisterSensorModal.step1Text',
                                            message: 'Scan de QR code op de verpakking',
                                        })}
                                    </InfoText>
                                </div>
                            </div>
                        </AnimatedHighlightListItem>
                    ),
                    state => (
                        <AnimatedHighlightListItem active={state.active} initial={state.initial}>
                            <div className={styles.stepWrapper}>
                                <PhoneInteractionVisual animate={state.animateIn} />
                                <div className={styles.stepTextWrapper}>
                                    <Title
                                        className={styles.title}
                                        fontSize={FontSize.size16}
                                        headingLevel={HeadingLevel.h3}
                                    >
                                        {locale._({
                                            id: 'RegisterSensorModal.step2Title',
                                            message: 'Stap 2',
                                        })}
                                    </Title>
                                    <InfoText>
                                        {locale._({
                                            id: 'RegisterSensorModal.step2Text',
                                            message: 'Wijs het datapunt toe aan een locatie en ruimte',
                                        })}
                                    </InfoText>
                                </div>
                            </div>
                        </AnimatedHighlightListItem>
                    ),
                ]}
            />
        </div>
    )
}

import { client, ResponsePromise } from '@unicaiot/unica-iot-gallery-core'
import { BuildingViewType } from 'domain/Buildings/services/buildingService/types'
import { RoomsDetailBaseParams } from 'views/router/Authorized/Building/Rooms/routes'
import { config } from '../../../config'
import { Room } from './types'

export interface IExplorerService {
    postRoom: (params: RoomFormData) => ResponsePromise
    putRoom: (entity: RoomEntity) => ResponsePromise
    getRoom: (pointRef: string) => ResponsePromise<Room>
    getRoomViews: (params: RoomsDetailBaseParams) => ResponsePromise<BuildingViewType>
    getRoomListViews: (params: RoomsDetailBaseParams) => ResponsePromise<BuildingViewType>
    deleteEntity: (id: string) => ResponsePromise
}

export interface RoomFormData {
    name: string
    type: string
    buildingRef: string
    roomRef?: string
}

export interface RoomEntity {
    roomRef: string
    name: string
}

export const roomsService: IExplorerService = {
    putRoom(entity: RoomEntity) {
        return client.put(config.entities('rooms', entity.roomRef), entity)
    },
    getRoom(pointRef: string) {
        return client.get<Room>(config.rooms, { params: { pointRef } })
    },
    postRoom(params: RoomFormData) {
        return client.post(config.rooms, {
            name: params.name,
            type: params.type,
            buildingRef: params.buildingRef,
            roomRef: params.roomRef,
        })
    },
    getRoomViews(params) {
        const { buildingId, roomId } = params
        return client.get<BuildingViewType>(config.roomsDetailView(buildingId, roomId))
    },
    getRoomListViews(params) {
        const { buildingId, roomId } = params
        return client.get<BuildingViewType>(config.roomsListDetailView(buildingId, roomId))
    },
    deleteEntity(id: string) {
        return client.delete(config.entities('rooms', id))
    },
}

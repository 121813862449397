import { ActivityIndicator, ActivityIndicatorEnumType, LocaleContext } from '@unicaiot/unica-iot-gallery-core'
import { ButtonStyleType, Center, Icons, PageTitle, TextButton } from '@unicaiot/unica-iot-gallery-buildinginsight'
import { ErrorBlock } from 'domain/Shared/ErrorBlock/ErrorBlock'
import React, { useContext } from 'react'
import { useBuildingOverviewViewComponent } from './useBuildingOverviewViewComponent'
import { BuildingHierarchyList } from 'domain/Buildings/components/BuildingHierarchyList/BuildingHierarchyList'
import { useHistory } from 'react-router-dom'

export const BuildingOverviewView: React.FunctionComponent = () => {
    const locale = useContext(LocaleContext)
    const history = useHistory()
    const {
        data: { expandedRooms, loading, error },
    } = useBuildingOverviewViewComponent()

    if (loading) {
        return (
            <Center grow={true}>
                <ActivityIndicator size={ActivityIndicatorEnumType.large} />
                {locale._({ id: 'common.loading', message: 'De hierarchie van het gebouw word opgehaald' })}
            </Center>
        )
    }

    if (error || !expandedRooms) {
        return (
            <Center grow={true}>
                <ErrorBlock />
            </Center>
        )
    }

    return (
        <>
            <PageTitle
                SubTitleComponent={
                    <TextButton
                        icon={Icons.return}
                        buttonStyle={ButtonStyleType.secondary}
                        onClick={() => history.goBack()}
                    >
                        {locale._({ id: 'BuildingOverviewView.goBackToOverview', message: 'Terug naar overzicht' })}
                    </TextButton>
                }
            >
                {locale._({ id: 'BuildingOverviewView.title', message: 'Gebouw hierarchie' })}
            </PageTitle>

            <BuildingHierarchyList
                startingNodeName={locale._({ id: 'BuildingOverviewView.startingNodeName', message: 'Gebouwen' })}
                data={expandedRooms}
            />
        </>
    )
}

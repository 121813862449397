import React, { Dispatch, SetStateAction, useContext, useState } from 'react'
import {
    Button,
    ButtonStyleType,
    Checkbox,
    Column,
    ColumnSpacing,
    Icons,
    InfoText,
    InputStyle,
    PasswordInput,
    TextSizeEnumType,
    TextWeightEnumType,
} from '@unicaiot/unica-iot-gallery-buildinginsight'
import { LocaleContext } from '@unicaiot/unica-iot-gallery-core'
import styles from './DeleteUserForm.module.scss'
import { DeleteUserFormData } from 'domain/Users/services/types'

interface Props {
    id: string
    onDelete: (data: DeleteUserFormData, setError: Dispatch<SetStateAction<string | undefined>>) => void
}

export const DeleteUserForm: React.FC<Props> = props => {
    const [password, setPassword] = useState<string>()
    const [errorMessage, setErrorMessage] = useState<string>()
    const [checkboxValue, setCheckboxValue] = useState<boolean>()
    const { id, onDelete } = props
    const locale = useContext(LocaleContext)

    return (
        <Column className={styles.container} spacing={ColumnSpacing.spacing4}>
            <div className={styles.textContainer}>
                <InfoText size={TextSizeEnumType.large} weight={TextWeightEnumType.bold} className={styles.title}>
                    {locale._({ id: 'deleteUserForm.title', message: 'Account verwijderen' })}
                </InfoText>
                <InfoText className={styles.alinea1}>
                    {locale._({
                        id: 'deleteUserForm.message.alinea1',
                        message: `
                        Om je account te verwijderen vragen we je wachtwoord in te vullen.
            `,
                    })}
                </InfoText>
                <InfoText>
                    {locale._({
                        id: 'deleteUserForm.message.alinea2',
                        message: `
            
                        Na het verwijderen heb je geen toegang meer tot Building insight. Om opnieuw toegang te krijgen zul je dit kunnen doen bij de Building Insight Admin.
            `,
                    })}
                </InfoText>
            </div>
            <Checkbox
                onChange={v => setCheckboxValue(v.target.checked)}
                label={locale._({
                    id: 'deleteUserForm.checkbox.label',
                    message: `
                        Ja, ik wil mijn account verwijderen
            `,
                })}
            />
            <PasswordInput
                type="password"
                label={<>{locale._('common.fields.password.label')}</>}
                placeholder={locale._('common.fields.password.placeholder')}
                onChange={v => setPassword(v)}
                value={password}
                required={true}
                style={InputStyle.light}
                error={!!errorMessage}
                errorMessage={errorMessage}
            />
            <Button
                buttonStyle={ButtonStyleType.danger}
                icon={Icons.bin}
                isSubmit={true}
                className={styles.submitButton}
                isDisabled={!checkboxValue}
                onClick={onDeleteAccount}
            >
                {locale._({
                    id: 'deleteUserForm.submitButtonLabel',
                    message: 'Verwijder mijn profiel',
                })}
            </Button>
        </Column>
    )

    function onDeleteAccount() {
        if (checkboxValue) {
            onDelete(
                {
                    id,
                    password: password ?? '',
                },
                setErrorMessage
            )
        }
    }
}

import { LocaleContext, pathof } from '@unicaiot/unica-iot-gallery-core'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { sensorsService } from './service/sensorsService'
import {
    Button,
    ButtonStyleType,
    Column,
    ColumnSpacing,
    JustifyContent,
    Modal,
    PreviewLayout,
    PromptModalView,
    Row,
    RowSpacing,
    Select,
    TextButton,
    useModal,
} from '@unicaiot/unica-iot-gallery-buildinginsight'
import { FormDeleteModal } from 'domain/Shared/FormDeleteModal/FormDeleteModal'
import { Trans } from '@lingui/macro'
import { routes } from 'views/router/Authorized/routes'
import { checkIfRequestStatusOK } from 'domain/Core/utils/checkIfRequestStatusOK'
import { DataPointsDetailBaseParams } from 'views/router/Authorized/Building/Rooms/Datapoints/routes'
import { EditSensorTextBlock } from './Components/EditSensorTextBlock/EditSensorTextBlock'
import { useGetBuildingsExpandedData } from 'domain/Buildings/services/buildingService/buildingService.hooks'
import { SensorSaveModal } from './Components/SensorSaveModal/SensorSaveModal'
import { Room } from '../service/types'
import { Sensor } from './service/types'
import { Building } from 'domain/Buildings/services/buildingService/types'
import { translations } from '../../../translations'

interface Props {
    onSave: (sensor: Sensor) => void
    saveLabel?: string | JSX.Element
    sensor: Sensor
}

export const SensorsForm: React.FC<Props> = props => {
    const { onSave, sensor } = props
    const { buildingId, roomId, dataPointId } = useParams<DataPointsDetailBaseParams>()
    const locale = useContext(LocaleContext)

    const { data } = useGetBuildingsExpandedData()

    const [building, setBuilding] = useState<Building>()
    const [globalRoom, setRoom] = useState<Room>()

    const [buildings, setBuildings] = useState<Building[]>([])

    const history = useHistory()
    const [deleteModalRef, deleteModalOptions] = useModal()
    const [saveModalRef, saveModalOptions] = useModal()

    useEffect(() => {
        if (sensor?.buildingRef && sensor?.roomRef) {
            const b = data?.find(b => b.id === sensor?.buildingRef)
            setBuilding(Object.assign({}, b))
            setRoom(findRoom(b?.rooms, sensor?.roomRef))
        }
        if (data) {
            setBuildings(data)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    const expand = (room?: Room, rooms?: Room[], parrentRoom?: Room) => {
        return (
            <Fragment>
                <Select
                    value={room?.id || ''}
                    disabled={!building}
                    required={true}
                    backgroundColor={'white'}
                    placeholder={
                        parrentRoom
                            ? undefined
                            : locale._(pathof<typeof translations>()._('registrationView')._('roomSelect').path)
                    }
                    onChange={handleSelectOnChange}
                    options={getOptions()}
                />
                {room?.rooms && expand(room.rooms?.[0], rooms?.find(r => r.id === room?.id)?.rooms, room)}
            </Fragment>
        )

        function handleSelectOnChange(v: string) {
            const vRoom = rooms?.find(r => r.id === v)
            let result: Room | undefined = vRoom && { ...vRoom, rooms: [] }

            if (parrentRoom) {
                parrentRoom.rooms = result ? [result] : []
                result = globalRoom
            }

            setRoom(Object.assign({}, result))
        }

        function getOptions() {
            if (parrentRoom) {
                const options = [
                    {
                        value: '',
                        label: locale._(pathof<typeof translations>()._('registrationView')._('roomNone').path),
                    },
                ]

                return options.concat(
                    rooms?.map(t => {
                        return { value: t.id, label: t.name }
                    }) ?? []
                )
            }

            return []
        }
    }

    return (
        <>
            <PreviewLayout
                footer={
                    <Fragment>
                        <Row justifyContent={JustifyContent.spaceBetween}>
                            {renderDeleteButton()}
                            <Modal ref={deleteModalRef}>
                                <PromptModalView>
                                    <FormDeleteModal
                                        title={locale._('sensorsForm.deleteModal.title', {
                                            name: locale._(`roomDetail.dataPoints.${sensor.type}`),
                                        })}
                                        infoText={locale._('sensorsForm.deleteModal.message', {
                                            name: locale._(`roomDetail.dataPoints.${sensor.type}`),
                                        })}
                                        type={'sensor'}
                                        onCancel={deleteModalOptions.actions.closeModal}
                                        onDelete={onDeleteSensor}
                                        deleteIcon={true}
                                    />
                                </PromptModalView>
                            </Modal>

                            {renderBasicFooterButtons()}
                        </Row>
                    </Fragment>
                }
            >
                <Column spacing={ColumnSpacing.spacing4}>
                    <EditSensorTextBlock
                        title={locale._('sensorsForm.message.title')}
                        description={locale._('sensorsForm.message.description')}
                    />
                    <Select
                        value={building?.id}
                        required={true}
                        label={<Trans id={'sensorsForm.location'}>Locatie</Trans>}
                        placeholder={locale._(
                            pathof<typeof translations>()._('registrationView')._('buildingSelect').path
                        )}
                        onChange={v => {
                            setBuilding(buildings.find(b => b.id === v))
                            setRoom(undefined)
                        }}
                        backgroundColor={'white'}
                        options={buildings.map(t => {
                            return { value: t.id, label: t.name }
                        })}
                    />
                    {expand(globalRoom, building?.rooms)}
                </Column>
            </PreviewLayout>
        </>
    )

    function renderBasicFooterButtons() {
        return (
            <Row justifyContent={JustifyContent.flexEnd} spacing={RowSpacing.spacing4}>
                <TextButton
                    onClick={() =>
                        history.push(
                            routes.authorized.buildings.rooms.dataPoints.detail({ buildingId, roomId, dataPointId })
                        )
                    }
                    buttonStyle={ButtonStyleType.secondary}
                >
                    {locale._('common.cancel')}
                </TextButton>
                {renderSaveButton()}
                <Modal ref={saveModalRef}>
                    <PromptModalView>
                        <SensorSaveModal onCancel={saveModalOptions.actions.closeModal} onSave={onSaveSensor} />
                    </PromptModalView>
                </Modal>
            </Row>
        )
    }

    function renderDeleteButton(): JSX.Element {
        return (
            <Button buttonStyle={ButtonStyleType.danger} onClick={deleteModalOptions.actions.openModal}>
                <Trans id={'sensorsForm.delete'}>Sensor verwijderen</Trans>
            </Button>
        )
    }

    function renderSaveButton(): JSX.Element {
        return <Button onClick={saveModalOptions.actions.openModal}>{locale._('common.save')}</Button>
    }

    async function onDeleteSensor() {
        try {
            const result = await sensorsService.deleteEntity(sensor.id)

            if (checkIfRequestStatusOK(result.status)) {
                history.push(routes.authorized.buildings.index)
                return
            }
        } catch (e) {
            alert(locale._('common.error.title'))
        }
    }

    function onSaveSensor() {
        if (!building || !globalRoom || !sensor) {
            return
        }

        const getRoomRef = (room: Room): string => {
            return room.rooms?.[0] ? getRoomRef(room.rooms?.[0]) : room.id
        }

        sensor.buildingRef = building.id
        sensor.roomRef = getRoomRef(globalRoom)

        onSave(sensor)
    }

    function findRoom(rooms?: Room[], roomRef?: string, parentRoom?: Room): Room | undefined {
        const room =
            rooms?.find(r => r.id === roomRef) || rooms?.map(r => findRoom(r.rooms, roomRef, r))?.filter(r => r)?.[0]

        let result: Room | undefined

        if (room) {
            result = Object.assign({}, room)
            result.rooms = result.rooms || []
        }

        if (parentRoom && result) {
            result.roomRef = parentRoom.id
            result = Object.assign({}, { ...parentRoom, rooms: [result] })
        }

        return result
    }
}

import { LocaleContext } from '@unicaiot/unica-iot-gallery-core'
import React, { useContext } from 'react'
import { Button, ButtonStyleType, FontSize, HeadingLevel, Title } from '@unicaiot/unica-iot-gallery-buildinginsight'
import { useHistory } from 'react-router-dom'
import { routes } from 'views/router/Authorized/routes'
import styles from './UserCreateModal.module.scss'

interface Props {
    title: string
    infoText: string
}

export const UserCreateModal: React.FC<Props> = props => {
    const { title, infoText } = props
    const locale = useContext(LocaleContext)
    const history = useHistory()

    return (
        <div className={styles.container}>
            <Title className={styles.title} headingLevel={HeadingLevel.h4} fontSize={FontSize.size16}>
                {title}
            </Title>
            <p className={styles.infoText}>{infoText}</p>
            <div className={styles.buttonContainer}>
                <Button
                    buttonStyle={ButtonStyleType.primary}
                    onClick={() => history.push(routes.authorized.users.index)}
                >
                    {locale._({ id: 'userCreateModal.buttonText', message: 'Terug naar overzicht' })}
                </Button>
            </div>
        </div>
    )
}

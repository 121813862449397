import React, { Fragment, useContext, useState } from 'react'
import {
    ButtonStyleType,
    Center,
    Column,
    ColumnSpacing,
    Icons,
    InfoText,
    JustifyContent,
    Link,
    MoreButton,
    PageTitle,
    Row,
    Table,
    TableItem,
    TextButton,
    TextColorEnumType,
    TextWeightEnumType,
} from '@unicaiot/unica-iot-gallery-buildinginsight'
import { EntityFilter } from 'domain/Shared/EntityFilter/EntityFilter'
import { ActivityIndicator, ActivityIndicatorEnumType, LocaleContext, useUser } from '@unicaiot/unica-iot-gallery-core'
import { Trans } from '@lingui/macro'
import { ErrorBlock } from 'domain/Shared/ErrorBlock/ErrorBlock'
import { RoomTableHeader } from 'domain/Rooms/components/RoomTableHeader/RoomTableHeader'
import { UserRole } from 'domain/Users/Components/UserRole/UserRole'
import { routes } from '../../routes'
import { useHistory } from 'react-router-dom'
import { useGetAllUsers } from 'domain/Users/services/userService.hooks'
import { Roles, User } from 'domain/Users/services/types'

enum TableHeaders {
    nameEmail = 'nameEmail',
    role = 'role',
    actions = 'actions',
}

const tableHeaders = [TableHeaders.nameEmail, TableHeaders.role, TableHeaders.actions]

export type HeaderTranslationsType = {
    [key in keyof typeof TableHeaders]: string
}
export const UsersOverviewView: React.FC = () => {
    const history = useHistory()
    const [filter, setFilter] = useState<TableHeaders>()
    const [filteredList, setFilteredList] = useState<User[]>()
    const locale = useContext(LocaleContext)
    const { data, loading, error } = useGetAllUsers()
    const user = useUser()

    const headerTranslations: HeaderTranslationsType = {
        [TableHeaders.nameEmail]: locale._({ id: 'usersList.headers.nameEmail', message: 'Name / E-mailadres' }),
        [TableHeaders.role]: locale._({ id: 'usersList.headers.role', message: 'Functie' }),
        [TableHeaders.actions]: ' ', // Empty header
    }

    return (
        <>
            <Column spacing={ColumnSpacing.spacing5}>
                <PageTitle
                    renderActions={() => (
                        <Fragment>
                            <EntityFilter
                                // TODO: use the filters when fetching data
                                // onChange={filters => console.log(filters)}
                                sortByLabel={locale._({
                                    id: 'userView.filters.sortUsers',
                                    message: 'Sorteren op rol',
                                })}
                            />
                            <MoreButton topLinks={renderTopLinks()} />
                        </Fragment>
                    )}
                >
                    <Trans id="users.title">Gebruikers</Trans>
                </PageTitle>
                {renderList()}
            </Column>
        </>
    )

    function renderList() {
        if (error) {
            return (
                <Center grow={true}>
                    <ErrorBlock />
                </Center>
            )
        }

        if (loading) {
            return (
                <Center grow={true}>
                    <ActivityIndicator size={ActivityIndicatorEnumType.large} />
                    <Trans id="common.loading">De data vanuit de datapunten wordt opgehaald.</Trans>
                </Center>
            )
        }

        if (data) {
            const list = filteredList ?? data
            return <Table headers={renderHeaders(data)} rows={renderListItems(list)} flex={1} />
        }
    }

    function renderHeaders(users?: User[]) {
        return tableHeaders.map((text, index) => {
            if (text === TableHeaders.actions) {
                return <RoomTableHeader key={index} headingText={headerTranslations[text]} />
            }
            return (
                <RoomTableHeader
                    key={index}
                    headingText={headerTranslations[text]}
                    onClick={() => {
                        if (filter === text) {
                            setFilter(undefined)
                            setFilteredList(undefined)
                            return
                        }

                        setFilter(text)
                        filterData(text, users)
                    }}
                    isOpen={filter === text}
                />
            )
        })
    }

    function renderListItems(data?: User[]) {
        const jsx = data?.map((user, index) => {
            return (
                <TableItem
                    key={index}
                    onClick={() => history.push(routes.authorized.users.detail(user.id))}
                    data={[
                        <div key={index}>
                            <InfoText weight={TextWeightEnumType.bold}>{user.firstName}</InfoText>

                            <Link href={`mailto:${user.email}`}>
                                <InfoText color={TextColorEnumType.grey}>{user.email}</InfoText>
                            </Link>
                        </div>,
                        <UserRole
                            key={index}
                            userRole={user.role}
                            backgroundColor={user.active === true ? 'dark' : 'grey'}
                        />,
                        <Row justifyContent={JustifyContent.flexEnd} key={index}>
                            {renderUserActions(user.id)}
                        </Row>,
                    ]}
                    spacing={'2'}
                    flex={1}
                />
            )
        })
        return jsx
    }

    function filterData(filter?: TableHeaders, data?: User[]) {
        if (data) {
            let sensors = data

            switch (filter) {
                case TableHeaders.role:
                    sensors = sortByRole(data)
                    break
                case TableHeaders.nameEmail:
                    sensors = sortByName(data)
                    break
            }
            setFilteredList(sensors)
        }
    }

    function sortByName(data: User[]): User[] {
        const filteredArray = [...data].sort((a, b) => {
            return a.firstName > b.firstName ? 1 : -1
        })

        return filteredArray
    }

    function sortByRole(data: User[]): User[] {
        const filteredArray = [...data].sort((a, b) => {
            return a.role > b.role ? 1 : -1
        })

        return filteredArray
    }

    function renderTopLinks() {
        if (user?.role === Roles.superAdmin || user?.role.includes(Roles.superAdmin)) {
            return [
                {
                    to: routes.authorized.users.create,
                    label: locale._({ id: 'usersView.addUser', message: 'Gebruiker toevoegen' }),
                    icon: Icons.add,
                },
            ]
        }
    }

    function renderUserActions(id: string) {
        if (user?.role === Roles.superAdmin || user?.role.includes(Roles.superAdmin)) {
            return (
                <>
                    <TextButton
                        icon={Icons.reset}
                        onClick={() => history.push(routes.authorized.users.editPassword(id))}
                        buttonStyle={ButtonStyleType.externalLink}
                    >
                        {locale._({
                            id: 'userView.resetPassword',
                            message: 'Reset password',
                        })}
                    </TextButton>
                </>
            )
        }

        return null
    }
}

import { checkIfRequestStatusOK } from 'domain/Core/utils/checkIfRequestStatusOK'
import { sensorsService } from '../service/sensorsService'
import { useHistory } from 'react-router-dom'
import { useState } from 'react'
import { Sensor } from '../service/types'

export function useSubmitSensor(path: string) {
    const [loading, setLoading] = useState<boolean>(false)
    const history = useHistory()

    const onSubmit = async (data: Sensor) => {
        setLoading(true)
        const result = await sensorsService.putSensor(data)

        if (checkIfRequestStatusOK(result.status)) {
            history.push(path)
        }
        setLoading(false)
    }

    return { onSubmit, loading }
}

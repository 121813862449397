import React from 'react'
import { IconButton, Icons, InfoText, TextColorEnumType } from '@unicaiot/unica-iot-gallery-buildinginsight'
import styles from './RoomTableHeader.module.scss'
import classNames from 'classnames'

interface Props {
    headingText: string
    onClick?: () => void
    isOpen?: boolean
}

export const RoomTableHeader: React.FC<Props> = props => {
    const { headingText, onClick, isOpen } = props

    const classes = (cls: string) =>
        classNames(cls, {
            [styles.isOpen]: isOpen === true,
        })

    return (
        <>
            <InfoText color={TextColorEnumType.grey} className={classes(styles.headingText)}>
                {headingText}
            </InfoText>
            {renderIcon()}
        </>
    )

    function renderIcon() {
        if (!onClick) {
            return null
        }

        if (isOpen) {
            return <IconButton className={classes(styles.icon)} icon={Icons['arrow-up']} onClick={onClick} />
        }

        return <IconButton className={classes(styles.icon)} icon={Icons['arrow-down']} onClick={onClick} />
    }
}

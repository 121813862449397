import { RoomsDetailBaseParams } from '../routes'

export interface DataPointsDetailBaseParams extends RoomsDetailBaseParams {
    dataPointId: string
}

export const dataPointsRoutes = {
    detail: (
        params: DataPointsDetailBaseParams = {
            buildingId: ':buildingId',
            roomId: ':roomId',
            dataPointId: ':dataPointId',
        }
    ) => `/buildings/${params.buildingId}/rooms/${params.roomId}/sensors/${params.dataPointId}`,
    edit: (
        params: DataPointsDetailBaseParams = {
            buildingId: ':buildingId',
            roomId: ':roomId',
            dataPointId: ':dataPointId',
        }
    ) => `/buildings/${params.buildingId}/rooms/${params.roomId}/sensors/${params.dataPointId}/edit`,
}

import classNames, { Argument } from 'classnames'
import React from 'react'
import styles from './UserMenuItems.module.scss'
import { Link } from '@unicaiot/unica-iot-gallery-buildinginsight'

interface Props {
    className?: Argument
    label: string
    to: string
    isDisabled?: boolean
    isActive?: boolean
}

export const UserMenuItems: React.FC<Props> = props => {
    const { label, to, isDisabled, isActive, className } = props
    const classes = classNames(
        styles.dropdownItem,
        {
            [styles.isDisabled]: isDisabled,
            [styles.isActive]: isActive,
        },
        className
    )

    return (
        <Link className={classes} to={to}>
            {label}
        </Link>
    )
}

import classNames from 'classnames'
import React from 'react'
import styles from './ScrollWrapper.module.scss'

interface Props {
    className?: string
}

export const ScrollWrapper: React.FC<Props> = ({ children, className }) => {
    const classes = classNames(styles.scrollWrapper, className)

    return (
        <div className={classes}>
            <div className={styles.dataPointsWrapper}>{children}</div>
        </div>
    )
}

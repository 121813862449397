import styles from './BuildingCard.module.scss'

import React, { FunctionComponent, useEffect, useState } from 'react'

import classNames, { Argument } from 'classnames'
import { Card, Center, FontSize, HeadingLevel, Title } from '@unicaiot/unica-iot-gallery-buildinginsight'
import { Link } from 'react-router-dom'
import { Trans } from '@lingui/macro'
import { ScrollWrapper } from 'domain/Shared/ScrollWrapper/ScrollWrapper'
import { useGetBuildingImage } from 'domain/Buildings/services/buildingService/buildingService.hooks'
import { ActivityIndicator } from '@unicaiot/unica-iot-gallery-core'
import { buildingsService } from 'domain/Buildings/services/buildingService/buildingService'

interface Props {
    className?: Argument
    dataPoints?: JSX.Element
    imgSrc: string
    id: string
    title: string
    rooms: number
    sensors: number
    modificationDate?: string
    to: string
}

export const BuildingCard: FunctionComponent<Props> = ({
    className,
    title,
    imgSrc,
    rooms,
    sensors,
    id,
    modificationDate,
    dataPoints,
    to,
}) => {
    const [loading, setLoading] = useState<boolean>()
    const [image, setImage] = useState<string>()

    const classes = classNames(styles.container, className)

    useEffect(() => {
        getImage()
    }, [])

    return (
        <Link className={classes} to={to}>
            <Card>
                <div className={styles.imageWrapper}>{renderImage()}</div>
                <Title className={styles.title} headingLevel={HeadingLevel.h2} fontSize={FontSize.size16}>
                    {title}
                </Title>
                <div className={styles.infoWrapper}>
                    <div>
                        <div className={styles.infoLabel}>
                            <Trans id="roomDetail.rooms.title">Ruimtes</Trans>
                        </div>
                        <div className={styles.infoValue}>{rooms}</div>
                    </div>
                    <div>
                        <div className={styles.infoLabel}>
                            <Trans id="roomDetail.sensors.title">Sensoren</Trans>
                        </div>
                        <div className={styles.infoValue}>{sensors}</div>
                    </div>
                    <div>
                        <div className={styles.infoLabel}>
                            <Trans id="roomDetail.modifiedOn.title">Gewijzigd op</Trans>
                        </div>
                        <div className={styles.infoValue}>{modificationDate}</div>
                    </div>
                </div>
                <ScrollWrapper>{dataPoints}</ScrollWrapper>
            </Card>
        </Link>
    )

    async function getImage() {
        setLoading(true)

        if (imgSrc) {
            const result = await buildingsService.getBuildingImage(id)
            const url = URL.createObjectURL(new Blob([result.data!]))
            setLoading(false)
            setImage(url)
            return
        }

        setLoading(false)
        setImage('https://picsum.photos/id/1081/500/300')
    }

    function renderImage() {
        if (loading) {
            return (
                <Center>
                    <ActivityIndicator />
                </Center>
            )
        }

        if (image) {
            return <img src={image} alt={id} />
        }

        return <img src={'https://picsum.photos/id/1081/500/300'} alt={id} />
    }
}

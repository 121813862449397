import { Trans } from '@lingui/macro'
import {
    Column,
    PageTitle,
    ColumnSpacing,
    MoreButton,
    Icons,
    RectangleDataPoint,
    DataPointSize,
    Center,
    HeadingLevel,
    FontSize,
    ButtonStyleType,
    Button,
    Title,
    JustifyContent,
    Row,
} from '@unicaiot/unica-iot-gallery-buildinginsight'
import { ActivityIndicator, ActivityIndicatorEnumType, LocaleContext } from '@unicaiot/unica-iot-gallery-core'
import { BuildingCard } from 'domain/Buildings/components/BuildingCard/BuildingCard'
import { BuildingGrid } from 'domain/Buildings/components/BuildingGrid/BuildingGrid'
import { buildingsService } from 'domain/Buildings/services/buildingService/buildingService'
import { useGetBuildingViews } from 'domain/Buildings/services/buildingService/buildingService.hooks'
import { Building } from 'domain/Buildings/services/buildingService/types'
import { PointKpi, Type } from 'domain/Rooms/Sensors/service/types'
import { EmptyState } from 'domain/Shared/EmptyState/EmptyState'
import { EntityFilter, Filters } from 'domain/Shared/EntityFilter/EntityFilter'
import { LiveFilter } from 'domain/Shared/LiveFilter/LiveFilter'
import moment from 'moment'
import React, { Fragment, FunctionComponent, useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { getDataPointStatus } from 'utils/getDataPointStatus'
import { getDataPointType } from 'utils/getDataPointType'
import { getDataPointUnit } from 'utils/getDataPointUnit'
import { routes } from 'views/router/Authorized/routes'

export const BuildingsOverviewView: FunctionComponent = () => {
    const locale = useContext(LocaleContext)
    const [filters, setFilters] = useState<Filters>()
    const { data, error, loading } = useGetBuildingViews(
        {
            params: {
                kpiType: filters?.kpiType as PointKpi,
                sensorType: filters?.sensorType,
            },
        },
        [filters?.kpiType, filters?.sensorType]
    )

    const history = useHistory()

    return (
        <Column spacing={ColumnSpacing.spacing5}>
            <PageTitle
                renderActions={() => (
                    <Fragment>
                        <EntityFilter
                            onChange={filter => setFilters(filter)}
                            sortByLabel={<Trans id="Dashboard.filters.sortBuildings">gebouwen sorteren</Trans>}
                        />
                        <LiveFilter /> {/*Should be integrated when api supports this */}
                        <MoreButton
                            topLinks={[
                                {
                                    to: routes.authorized.buildings.create,
                                    label: locale._('Dashboard.addBuilding'),
                                    icon: Icons.add,
                                },
                            ]}
                        />
                    </Fragment>
                )}
            >
                <Trans id="Dashboard.title">Gebouwen</Trans>
            </PageTitle>
            {renderBuildings()}
        </Column>
    )

    function renderBuildings() {
        if (loading) {
            return (
                <Center grow={true}>
                    <ActivityIndicator size={ActivityIndicatorEnumType.large} />
                    <Trans id="common.loading">De data vanuit de datapunten wordt opgehaald.</Trans>
                </Center>
            )
        }

        if (error) {
            return (
                <Center grow={true}>
                    <Column spacing={ColumnSpacing.spacing4}>
                        <Row justifyContent={JustifyContent.center}>
                            <Title headingLevel={HeadingLevel.h2} fontSize={FontSize.size22}>
                                <Trans id="common.error.title">Er is iets fout gegaan</Trans>
                            </Title>
                        </Row>
                        <Row justifyContent={JustifyContent.center}>
                            <Trans id="common.error.message">
                                Er ging iets mis met het laden van de pagina. Keer terug naar de vorige pagina.
                            </Trans>
                        </Row>
                        <Row justifyContent={JustifyContent.center}>
                            <Button buttonStyle={ButtonStyleType.primary} onClick={() => history.goBack()}>
                                <Trans id={'common.goBack'}>Terug naar vorige pagina</Trans>
                            </Button>
                        </Row>
                    </Column>
                </Center>
            )
        }

        if (data?.length) {
            return (
                <BuildingGrid>
                    {data.map((building, index) => {
                        return (
                            <BuildingCard
                                id={building.id}
                                key={index}
                                to={routes.authorized.buildings.detail(building.id)}
                                title={building.name}
                                rooms={building.counts.rooms}
                                sensors={building.counts.sensors}
                                modificationDate={moment(building.modificationDate).format('DD MMMM YYYY')}
                                imgSrc={building.imgName}
                                dataPoints={<Fragment>{renderDataPoints(building)}</Fragment>}
                            />
                        )
                    })}
                </BuildingGrid>
            )
        }

        return (
            <Center grow={true}>
                <EmptyState
                    title={locale._('Dashboard.emptyBuilding')}
                    infoText={locale._('Dashboard.emptyBuilding.infoText')}
                    button={{
                        text: locale._('Dashboard.addBuilding'),
                        onClick: () => history.push(routes.authorized.buildings.create),
                    }}
                />
            </Center>
        )
    }

    function renderDataPoints(building: Building) {
        const data = Object.entries(building.averageValues)

        return data.map((dataPoint, index) => {
            const type = dataPoint[0] as Type
            const data = dataPoint[1]
            const value = Math.round(dataPoint[1]?.value || 0)

            return (
                <RectangleDataPoint
                    key={index}
                    status={getDataPointStatus(data?.kpi)}
                    type={getDataPointType(type)}
                    size={DataPointSize.smallGroup}
                    label={locale._(`buildingDetail.dataPointAverages.${type}`)}
                    value={`${value} ${getDataPointUnit(type)}`}
                />
            )
        })
    }
}

import React, { Dispatch, SetStateAction, useContext, useState } from 'react'
import {
    Button,
    ButtonStyleType,
    Column,
    ColumnSpacing,
    InfoText,
    Input,
    InputStyle,
    PasswordInput,
    PasswordStrengthIndicator,
    Row,
    Select,
    TextSizeEnumType,
    TextWeightEnumType,
} from '@unicaiot/unica-iot-gallery-buildinginsight'
import styles from './UserCreateForm.module.scss'
import { LocaleContext } from '@unicaiot/unica-iot-gallery-core'
import { useHistory } from 'react-router-dom'
import { routes } from 'views/router/Authorized/routes'
import { EmailValidators } from 'utils/validators/EmailValidators'
import { CreateUserFormData } from 'domain/Users/services/types'

interface Props {
    onSave: (data: CreateUserFormData, setError: Dispatch<SetStateAction<string | undefined>>) => void
    loading?: boolean
}

enum Roles {
    Admin = 'Admin',
    SuperAdmin = 'SuperAdmin',
}

export type RoleTranslationsType = {
    [key in keyof typeof Roles]: string
}

export const UserCreateForm: React.FC<Props> = props => {
    const { onSave, loading } = props
    const [firstName, setFirstName] = useState<string>()
    const [firstNameErrorMessage, setFirstNameErrorMessage] = useState<string>()
    const [lastName, setLastName] = useState<string>()
    const [lastNameErrorMessage, setLastNameErrorMessage] = useState<string>()
    const [email, setEmail] = useState<string>()
    const [emailErrorMessage, setEmailErrorMessage] = useState<string>()
    const [password1, setPassword1] = useState<string>()
    const [password1ErrorMessage, setPassword1ErrorMessage] = useState<string>()
    const [password2, setPassword2] = useState<string>()
    const [password2ErrorMessage, setPassword2ErrorMessage] = useState<string>()
    const [role, setRole] = useState<string>()
    const locale = useContext(LocaleContext)
    const history = useHistory()

    const roleTranslations: RoleTranslationsType = {
        [Roles.Admin]: locale._({ id: 'createUser.roles.admin', message: 'Admin' }),
        [Roles.SuperAdmin]: locale._({ id: 'createUser.roles.superAdmin', message: 'Super Admin' }),
    }

    return (
        <>
            <Column className={styles.container} spacing={ColumnSpacing.spacing4}>
                <div className={styles.textContainer}>
                    <InfoText size={TextSizeEnumType.large} weight={TextWeightEnumType.bold} className={styles.title}>
                        {locale._({ id: 'createUser.title', message: 'Account aanmaken' })}
                    </InfoText>
                    <InfoText className={styles.alinea1}>
                        {locale._({
                            id: 'createUser.message.alinea1',
                            message: `
                        Voer onderstaande gegevens in van de gebruiker. Er wordt een Welkomst-mail verstuurd naar de gebruiker ter onboarding.
                `,
                        })}
                    </InfoText>
                </div>
                <InfoText size={TextSizeEnumType.large} weight={TextWeightEnumType.bold} className={styles.title}>
                    {locale._({ id: 'createUser.data', message: 'Gegevens' })}
                </InfoText>
                <Column className={styles.inputContainer}>
                    <Input
                        value={firstName}
                        onChange={v => setFirstName(v)}
                        style={InputStyle.light}
                        placeholder={locale._({ id: 'createUser.firstName.placeholder', message: 'Voornaam' })}
                        label={<>{locale._({ id: 'createUser.firstName', message: 'Voornaam' })}</>}
                        error={!!firstNameErrorMessage}
                        errorMessage={firstNameErrorMessage}
                    />
                    <Input
                        value={lastName}
                        onChange={v => setLastName(v)}
                        placeholder={locale._({ id: 'createUser.lastName.placeholder', message: 'Achternaam' })}
                        label={<>{locale._({ id: 'createUser.lastName', message: 'Achternaam' })}</>}
                        style={InputStyle.light}
                        error={!!lastNameErrorMessage}
                        errorMessage={lastNameErrorMessage}
                    />

                    <Input
                        value={email}
                        onChange={v => setEmail(v)}
                        style={InputStyle.light}
                        placeholder={locale._({ id: 'createUser.email.placeholder', message: 'naam@company.com' })}
                        label={<>{locale._({ id: 'createUser.email', message: 'E-mailadres' })}</>}
                        error={!!emailErrorMessage}
                        errorMessage={emailErrorMessage}
                        validators={[EmailValidators.isEmailAddress]}
                    />
                </Column>
                <InfoText size={TextSizeEnumType.large} weight={TextWeightEnumType.bold} className={styles.title}>
                    {locale._({ id: 'createUser.account', message: 'Account' })}
                </InfoText>
                <Column className={styles.inputContainer}>
                    <Select
                        label={locale._({ id: 'createUser.role', message: 'Functie' })}
                        backgroundColor={'white'}
                        value={role}
                        onChange={v => setRole(v)}
                        options={getRoles()}
                    />

                    <PasswordInput
                        type="password"
                        label={<>{locale._('common.fields.password.label')}</>}
                        placeholder={locale._('common.fields.password.placeholder')}
                        onChange={v => setPassword1(v)}
                        value={password1}
                        required={true}
                        style={InputStyle.light}
                        error={!!password1ErrorMessage}
                        errorMessage={password1ErrorMessage}
                    />
                    <PasswordStrengthIndicator value={password1} />

                    <PasswordInput
                        type="password"
                        label={<>{locale._('common.fields.passwordConfirm.label')}</>}
                        placeholder={locale._('common.fields.passwordConfirm.placeholder')}
                        onChange={v => setPassword2(v)}
                        value={password2}
                        required={true}
                        style={InputStyle.light}
                        error={!!password2ErrorMessage}
                        errorMessage={password2ErrorMessage}
                    />
                </Column>
                <Row>
                    <Button
                        buttonStyle={ButtonStyleType.primary}
                        isSubmit={true}
                        className={styles.submitButton}
                        isDisabled={getButtonDisabledState()}
                        onClick={onCreateUser}
                        loading={loading}
                    >
                        {locale._({
                            id: 'createUserForm.submitButtonLabel',
                            message: 'Account aanmaken',
                        })}
                    </Button>
                    <Button
                        buttonStyle={ButtonStyleType.secondary}
                        onClick={() => history.push(routes.authorized.users.index)}
                    >
                        {locale._({
                            id: 'createUserForm.submitButtonLabel',
                            message: 'Annuleren',
                        })}
                    </Button>
                </Row>
            </Column>
        </>
    )

    function getButtonDisabledState(): boolean {
        if (!password1 || !password2 || !firstName || !lastName || !email) {
            return true
        }

        return false
    }

    function getRoles() {
        const roles = Object.values(Roles)

        return roles.map(role => {
            return {
                label: roleTranslations[role],
                value: role,
            }
        })
    }

    function onCreateUser() {
        if (password1 !== password2) {
            setPassword2ErrorMessage(
                locale._({
                    id: 'editPasswordForm.errorMessage',
                    message: 'Het wachtwoord komt niet overeen.',
                })
            )
            return
        }

        if (!firstName) {
            setFirstNameErrorMessage(
                locale._({
                    id: 'editPasswordForm.firstNameErrorMessage',
                    message: 'Er is geen voornaam ingevuld',
                })
            )
            return
        }

        if (!lastName) {
            setLastNameErrorMessage(
                locale._({
                    id: 'editPasswordForm.lastNameErrorMessage',
                    message: 'Er is geen achternaam ingevuld',
                })
            )
            return
        }

        if (!email) {
            setEmailErrorMessage(
                locale._({
                    id: 'editPasswordForm.emailErrorMessage',
                    message: 'Er is geen E-mailadres ingevuld',
                })
            )
            return
        }

        if (!password1) {
            setPassword1ErrorMessage(
                locale._({
                    id: 'editPasswordForm.password1ErrorMessage',
                    message: 'Er is geen wachtwoord ingevuld',
                })
            )
        }

        onSave(
            {
                email: email,
                firstname: firstName,
                lastname: lastName,
                password: password1,
                role: role,
            },
            setFirstNameErrorMessage
        )
    }
}

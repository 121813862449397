import React, { useContext } from 'react'
import classNames from 'classnames'
import styles from './DataPointsGrid.module.scss'
import { FontSize, HeadingLevel, InfoText, TextStyleEnumType, Title } from '@unicaiot/unica-iot-gallery-buildinginsight'
import { ScrollWrapper } from 'domain/Shared/ScrollWrapper/ScrollWrapper'
import { LocaleContext } from '@unicaiot/unica-iot-gallery-core'

interface Props {
    title?: string
    className?: string
    data?: JSX.Element[]
}

export const DataPointsGrid: React.FC<Props> = props => {
    const { title, data, className } = props
    const classes = classNames(styles.container, className)
    const locale = useContext(LocaleContext)

    return (
        <div className={classes}>
            <Title headingLevel={HeadingLevel.h2} fontSize={FontSize.size22} className={styles.title}>
                {title}
            </Title>
            <div className={styles.dataPointsContainer}>
                <ScrollWrapper>{renderData()}</ScrollWrapper>
            </div>
        </div>
    )

    function renderData() {
        if (data && data.length > 0) {
            return data
        }

        return <InfoText style={TextStyleEnumType.italic}>{locale._('roomDetail.dataPoints.noDataPoints')}</InfoText>
    }
}

import { client, ResponsePromise } from '@unicaiot/unica-iot-gallery-core'
import { config } from 'config'
import { CreateUserFormData, User } from './types'

export interface UserService {
    getAllUsers: () => ResponsePromise<User[]>
    getUser: (id: string) => ResponsePromise<User>
    deleteUser: (id: string) => ResponsePromise<unknown>
    createUser: (params: CreateUserFormData) => ResponsePromise<string>
}

export const usersService: UserService = {
    getAllUsers() {
        return client.get<User[]>(config.usersAll)
    },
    getUser(id: string) {
        return client.get<User>(config.userDetail(id))
    },
    deleteUser(id: string) {
        return client.delete<unknown>(config.userDetail(id))
    },
    createUser(params: CreateUserFormData) {
        return client.post<string>(config.users, params)
    },
}

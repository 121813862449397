import { Type } from 'domain/Rooms/Sensors/service/types'

export function getDataPointUnit(type?: Type): string {
    let unit = ''

    switch (type) {
        case Type.CO2:
            unit = 'ppm'
            break
        case Type.humidity:
            unit = String.fromCharCode(37)
            break
        case Type.temperature:
            unit = String.fromCharCode(8451)

            break
    }

    return unit
}

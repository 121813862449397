import { Trans } from '@lingui/macro'
import { Center } from '@unicaiot/unica-iot-gallery-buildinginsight'
import { ActivityIndicator, ActivityIndicatorEnumType } from '@unicaiot/unica-iot-gallery-core'
import { RegisterSensorSuccessContainer } from 'domain/RegisterSensor/RegisterSensorSuccessContainer/RegisterSensorSuccessContainer'
import { useGetSensor } from 'domain/Rooms/Sensors/service/sensorsService.hooks'
import { ErrorBlock } from 'domain/Shared/ErrorBlock/ErrorBlock'
import React from 'react'
import { useParams } from 'react-router-dom'
import { RegisterSensorDetailParams } from '../routes'

export const RegisterSensorSuccessView: React.FC = () => {
    const { id } = useParams<RegisterSensorDetailParams>()
    const { data, loading, error } = useGetSensor(id)

    return renderView()

    function renderView() {
        if (loading) {
            return (
                <Center grow={true}>
                    <ActivityIndicator size={ActivityIndicatorEnumType.large} />
                    <Trans id="common.loading">De data vanuit de datapunten wordt opgehaald.</Trans>
                </Center>
            )
        }

        if (error) {
            return (
                <Center grow={true}>
                    <ErrorBlock />
                </Center>
            )
        }

        if (data) {
            return <RegisterSensorSuccessContainer data={data} />
        }

        return null
    }
}

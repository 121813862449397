import {
    BuildingExpandedRoomsQuery,
    BuildingExpandedRoomsRoom,
} from 'domain/Buildings/services/types/buildingExpandedRooms'
import { useCallback, useEffect, useState } from 'react'

export interface HierarchyListProps {
    data: BuildingExpandedRoomsQuery
    startingNodeName: string
}

export interface HierarchyNodeData {
    title?: string
    childrenTitle?: string
    label: string
    id: string
    buildingRef?: string
    roomRef?: string
    onClick?: () => void
    type?: string
    rooms?: BuildingExpandedRoomsRoom[]
    childrenNodes?: MappedChildrenNode[]
    index: number
    isStartingNode?: boolean
}

export interface MappedChildrenNode {
    type?: string
    childrenNodes: HierarchyNodeData[]
}

function useMappedData(data: BuildingExpandedRoomsQuery, startingNodeName: string) {
    const [mappedData, setMappedData] = useState<HierarchyNodeData[] | undefined>(undefined)

    const memoizedMapRootDataToNodeData = useCallback(mapRootDataToNodeData, [mapRootDataToNodeData])
    const memoizedSetMappedData = useCallback(
        () => setMappedData(memoizedMapRootDataToNodeData(data)),
        [data, memoizedMapRootDataToNodeData]
    )

    useEffect(() => {
        memoizedSetMappedData()
    }, [memoizedSetMappedData])

    function mapRootDataToNodeData(data?: BuildingExpandedRoomsQuery): HierarchyNodeData[] {
        if (!data) {
            return []
        }

        return data.map((node, index) => {
            const possibleTypes = [...new Set(node.rooms.map(room => room.type))]

            return {
                isStartingNode: true,
                title: startingNodeName,
                childrenTitle: '',
                id: node.id,
                label: node.name,
                rooms: node.rooms,
                childrenNodes: possibleTypes.map(type => ({
                    type: type,
                    childrenNodes: mapRoomDataToNodeData(node.rooms.filter(room => room.type === type)),
                })),
                index: index,
            }
        })
    }

    function mapRoomDataToNodeData(data?: BuildingExpandedRoomsRoom[]): HierarchyNodeData[] {
        if (!data) {
            return []
        }

        return data.map((node, index) => {
            const possibleTypes = [...new Set(node.rooms.map(room => room.type))]

            return {
                childrenTitle: '',
                label: node.name,
                type: node.type,
                id: node.id,
                roomRef: node.roomRef,
                buildingRef: node.buildingRef,
                rooms: node.rooms,
                childrenNodes: possibleTypes.map(type => ({
                    type: type,
                    childrenNodes: mapRoomDataToNodeData(node.rooms.filter(room => room.type === type)),
                })),
                index: index,
            }
        })
    }

    return { mappedData }
}

export function useHierarchyListComponent(props: HierarchyListProps) {
    const { data, startingNodeName } = props
    const { mappedData } = useMappedData(data, startingNodeName)

    return {
        data: {
            mappedData,
        },
    }
}

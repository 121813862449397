import React from 'react'
import { DataPointType, Icon, Icons, IconType, InfoText } from '@unicaiot/unica-iot-gallery-buildinginsight'
import styles from './DataPointItem.module.scss'
import classNames from 'classnames'
import { PointKpi } from 'domain/Rooms/Sensors/service/types'

interface Props {
    value: string
    type: DataPointType
    status: PointKpi
}

export const DataPointItem: React.FC<Props> = props => {
    const { value, type, status } = props
    const classes = classNames(styles.container, {
        [styles.positive]: status === PointKpi.Sufficient,
        [styles.average]: status === PointKpi.Moderate,
        [styles.negative]: status === PointKpi.InSufficient,
    })

    return (
        <div className={classes}>
            <Icon className={styles.icon} size={30} icon={getIcon()} />
            <InfoText className={styles.text}>{value}</InfoText>
        </div>
    )

    function getIcon() {
        const iconKey = `module-${type}`

        const icons = Icons as { [key: string]: IconType }

        return icons[iconKey]
    }
}

import { Type } from 'domain/Rooms/Sensors/service/types'

type TypeTranslations = {
    [key in keyof typeof Type]: Translation
}

interface Translation {
    id: string
    message: string
}

export const typeTranslations: TypeTranslations = {
    [Type.CO2]: {
        id: 'roomDetail.dataPoints.CO2',
        message: 'Luchtkwaliteit',
    },
    [Type.humidity]: {
        id: 'roomDetail.dataPoints.humidity',
        message: 'Luchtvochtigheid',
    },
    [Type.temperature]: {
        id: 'roomDetail.dataPoints.temperature',
        message: 'Temperatuur',
    },
}

import React, { useContext } from 'react'
import {
    Button,
    ButtonStyleType,
    Column,
    ColumnSpacing,
    Icon,
    Icons,
    InfoText,
    Row,
    TextSizeEnumType,
    TextWeightEnumType,
} from '@unicaiot/unica-iot-gallery-buildinginsight'
import { LocaleContext } from '@unicaiot/unica-iot-gallery-core'
import { useHistory } from 'react-router-dom'
import { routes } from 'views/router/Authorized/routes'
import styles from './ResetPasswordSuccessBlock.module.scss'

interface Props {
    id: string
}

export const ResetPasswordSuccessBlock: React.FC<Props> = ({ id }) => {
    const locale = useContext(LocaleContext)
    const history = useHistory()

    return (
        <Column spacing={ColumnSpacing.spacing4} className={styles.container}>
            <Row className={styles.headerContainer}>
                <div className={styles.iconContainer}>
                    <Icon icon={Icons.checkmark} className={styles.icon} />
                </div>
                <InfoText size={TextSizeEnumType.large} weight={TextWeightEnumType.bold}>
                    {locale._({ id: 'resetPasswordForm.success.title', message: 'Wachtwoord is gereset' })}
                </InfoText>
            </Row>

            <InfoText>
                {locale._({
                    id: 'resetPasswordForm.success.message',
                    message: `
                    Je wachtwoord is gereset. Er is een mail verzonden met een url om je wachtwoord opnieuw in te stellen.
        `,
                })}
            </InfoText>
            <Button
                buttonStyle={ButtonStyleType.primary}
                isSubmit={true}
                onClick={() => history.push(routes.authorized.users.detail(id))}
                className={styles.button}
            >
                {locale._({
                    id: 'resetPasswordForm.goToProfileButtonLabel',
                    message: 'Terug naar profiel',
                })}
            </Button>
        </Column>
    )
}

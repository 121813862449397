import styles from './BuildingInfo.module.scss'

import React, { FunctionComponent, useContext } from 'react'

import classNames, { Argument } from 'classnames'
import moment from 'moment'
import { Column, RectangleDataPoint, DataPointSize, Center } from '@unicaiot/unica-iot-gallery-buildinginsight'
import { ActivityIndicator, LocaleContext } from '@unicaiot/unica-iot-gallery-core'
import { RoomDetails } from 'domain/Shared/Buildings/Rooms/RoomDetails/RoomDetails'
import { BuildingViewType } from 'domain/Buildings/services/buildingService/types'
import { getDataPointStatus } from 'utils/getDataPointStatus'
import { getDataPointType } from 'utils/getDataPointType'
import { getDataPointUnit } from 'utils/getDataPointUnit'
import { useGetBuildingImage } from 'domain/Buildings/services/buildingService/buildingService.hooks'
import { Type } from 'domain/Rooms/Sensors/service/types'

interface Props {
    className?: Argument
    building: BuildingViewType
}

export const BuildingInfo: FunctionComponent<Props> = ({ className, building }) => {
    const classes = classNames(styles.container, className)
    const locale = useContext(LocaleContext)
    const { data, loading } = useGetBuildingImage(building.id)

    return (
        <div className={classes}>
            <Column>
                <div className={styles.imageWrapper}>{renderImage()}</div>
                <RoomDetails
                    rooms={building.rooms?.length || 0}
                    sensors={Object.entries(building.averageValues).length}
                    modificationDate={
                        building.modificationDate ? moment(building.modificationDate).format('DD MMMM YYYY') : ''
                    }
                />
                <div className={styles.dataPointsGrid}>{renderDataPoints()}</div>
            </Column>
        </div>
    )

    function renderDataPoints() {
        const data = Object.entries(building.averageValues)

        return data.map((dataPoint, index) => {
            const type = dataPoint[0] as Type
            const data = dataPoint[1]
            const value = Math.round(dataPoint[1]?.value || 0)

            return (
                <RectangleDataPoint
                    key={index}
                    status={getDataPointStatus(data?.kpi)}
                    type={getDataPointType(type)}
                    size={DataPointSize.smallGroup}
                    label={locale._(`buildingDetail.dataPointAverages.${type}`)}
                    value={`${value} ${getDataPointUnit(type)}`}
                    className={styles.dataPoint}
                />
            )
        })
    }

    function renderImage() {
        if (loading) {
            return (
                <Center grow={true}>
                    <ActivityIndicator />
                </Center>
            )
        }

        if (data) {
            return <img className={styles.image} alt={building.name} src={URL.createObjectURL(new Blob([data!]))} />
        }

        return <img className={styles.image} src={'https://picsum.photos/id/1081/500/300'} alt={building.name} />
    }
}

import styles from './RoomDetailGrid.module.scss'
import React, { FunctionComponent } from 'react'

import classNames, { Argument } from 'classnames'

interface Props {
    className?: Argument
    rooms: JSX.Element[]
    columns?: GridColumns
}

export enum GridColumns {
    '2-columns' = 2,
    '3-columns' = 3,
}

export const RoomDetailGrid: FunctionComponent<Props> = ({ rooms, className, columns = GridColumns['2-columns'] }) => {
    const classes = classNames(styles.container, className, {
        [styles.twoColumns]: columns === GridColumns['2-columns'],
        [styles.threeColumns]: columns === GridColumns['3-columns'],
    })

    return <div className={classes}>{renderRooms()}</div>

    function renderRooms() {
        return rooms.map((room, index) => (
            <div key={index} className={styles.roomDetail}>
                {room}
            </div>
        ))
    }
}

import { Trans } from '@lingui/macro'
import {
    Button,
    ButtonStyleType,
    Center,
    Column,
    ColumnSpacing,
    FontSize,
    HeadingLevel,
    JustifyContent,
    PageTitle,
    Row,
    Title,
} from '@unicaiot/unica-iot-gallery-buildinginsight'
import { ActivityIndicator, ActivityIndicatorEnumType, LocaleContext } from '@unicaiot/unica-iot-gallery-core'
import { checkIfRequestStatusOK } from 'domain/Core/utils/checkIfRequestStatusOK'
import { RoomsForm } from 'domain/Rooms/components/RoomsForm'
import { RoomFormData, roomsService } from 'domain/Rooms/service/roomsService'
import { useGetRoomViews } from 'domain/Rooms/service/roomsService.hooks'
import React, { Dispatch, SetStateAction, useContext } from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { routes } from 'views/router/Authorized/routes'

interface RouteParams {
    buildingId: string
    roomId: string
}

interface Props extends RouteComponentProps<RouteParams> {}

export const EditRoomView: React.FC<Props> = ({ match }) => {
    const locale = useContext(LocaleContext)
    const history = useHistory()
    const { buildingId, roomId } = match.params
    const { data, error, loading } = useGetRoomViews({ buildingId, roomId })

    const room = data?.room

    const submitRoom = async (data: RoomFormData, setError: Dispatch<SetStateAction<string | undefined>>) => {
        const result = await roomsService.putRoom({
            name: data.name,
            roomRef: roomId,
        })

        if (checkIfRequestStatusOK(result.status)) {
            history.push(routes.authorized.buildings.rooms.detail({ buildingId, roomId }))

            return
        }

        setError(locale._('common.error'))
    }

    return <Column spacing={ColumnSpacing.spacing5}>{renderBuilding()}</Column>

    function renderBuilding() {
        if (loading) {
            return (
                <Center grow={true}>
                    <ActivityIndicator size={ActivityIndicatorEnumType.large} />
                    <Trans id="common.loading">De data vanuit de datapunten wordt opgehaald.</Trans>
                </Center>
            )
        }

        if (error) {
            return (
                <Center grow={true}>
                    <Column spacing={ColumnSpacing.spacing4}>
                        <Row justifyContent={JustifyContent.center}>
                            <Title headingLevel={HeadingLevel.h2} fontSize={FontSize.size22}>
                                <Trans id="common.error.title">Er is iets fout gegaan</Trans>
                            </Title>
                        </Row>
                        <Row justifyContent={JustifyContent.center}>
                            <Trans id="common.error.message">
                                Er ging iets mis met het laden van de pagina. Keer terug naar de vorige pagina.
                            </Trans>
                        </Row>
                        <Row justifyContent={JustifyContent.center}>
                            <Button buttonStyle={ButtonStyleType.primary} onClick={() => history.goBack()}>
                                <Trans id={'common.goBack'}>Terug naar vorige pagina</Trans>
                            </Button>
                        </Row>
                    </Column>
                </Center>
            )
        }

        if (data) {
            return (
                <>
                    <PageTitle>
                        <Trans id={'editRoom.title'}>{locale._('editRoom.title', { name: data.room?.name })}</Trans>
                    </PageTitle>
                    <RoomsForm onSave={submitRoom} room={room} spaceRefs={{ buildingRef: buildingId }} />
                </>
            )
        }
    }
}

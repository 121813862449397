import { Trans } from '@lingui/macro'
import {
    FontSize,
    HeadingLevel,
    HorizontalRule,
    InfoText,
    TextColorEnumType,
    Title,
} from '@unicaiot/unica-iot-gallery-buildinginsight'
import { LocaleContext } from '@unicaiot/unica-iot-gallery-core'
import React, { useContext } from 'react'
import { RoomDetails } from '../RoomDetails/RoomDetails'

interface Props {
    title?: string
    type: 'floor' | 'room' | 'space' | string
    rooms?: number
    sensors?: number
    modificationDate?: string
}

export const RoomCardHeader: React.FC<Props> = props => {
    const locale = useContext(LocaleContext)
    const { title, rooms, sensors, modificationDate, type } = props

    return (
        <>
            <Title headingLevel={HeadingLevel.h3} fontSize={FontSize.size22}>
                {title}
            </Title>
            <InfoText color={TextColorEnumType.grey}>
                <Trans id={`buildingDetail.rooms.type.${type}`}>{locale._(`buildingDetail.rooms.type.${type}`)}</Trans>
            </InfoText>

            <HorizontalRule />
            <RoomDetails rooms={rooms} sensors={sensors} modificationDate={modificationDate} />
        </>
    )
}

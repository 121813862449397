import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { routes } from '../../routes'
import { DeleteUserView } from './Detail/DeleteUserView/DeleteUserView'
import { ResetUserPasswordView } from './Detail/ResetPasswordView/ResetUserPasswordView'
import { UserCreateView } from './Detail/UserCreateView/UserCreateView'
import { UserReadView } from './Detail/UserReadView/UserReadView'
import { UsersOverviewView } from './UsersOverviewView'

export const UsersView: React.FC = () => {
    return (
        <Switch>
            <Route path={routes.authorized.users.index} exact={true} component={UsersOverviewView} />
            <Route path={routes.authorized.users.detail()} exact={true} component={UserReadView} />
            <Route path={routes.authorized.users.editPassword()} exact={true} component={ResetUserPasswordView} />
            <Route path={routes.authorized.users.deleteUser()} exact={true} component={DeleteUserView} />
            <Route path={routes.authorized.users.create} exact={true} component={UserCreateView} />
        </Switch>
    )
}

import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { DataPointsDetailView } from 'views/pages/DataPoints/DataPointsDetailView/DataPointsDetailView'
import { DataPointsEditView } from 'views/pages/DataPoints/DataPointsEditView/DataPointsEditView'
import { routes } from 'views/router/Authorized/routes'

export const DataPointsView: React.FC = () => {
    return (
        <Switch>
            <Route
                path={routes.authorized.buildings.rooms.dataPoints.detail()}
                exact={true}
                component={DataPointsDetailView}
            />
            <Route
                path={routes.authorized.buildings.rooms.dataPoints.edit()}
                exact={true}
                component={DataPointsEditView}
            />
        </Switch>
    )
}

import { Trans } from '@lingui/macro'
import {
    Button,
    ButtonStyleType,
    FontSize,
    HeadingLevel,
    Icons,
    TextButton,
    Title,
} from '@unicaiot/unica-iot-gallery-buildinginsight'
import { LocaleContext } from '@unicaiot/unica-iot-gallery-core'
import React, { FC, useContext } from 'react'
import styles from './FormDeleteModal.module.scss'

interface Props {
    title: string
    infoText: string
    type: Type
    onDelete?: () => void
    onCancel?: () => void
    deleteIcon?: boolean
    cancelButtonColor?: CancelButtonColor
}

enum CancelButtonColor {
    blue = 'blue',
    black = 'black',
}

type Type = 'building' | 'room' | 'space' | 'floor' | 'sensor'

export const FormDeleteModal: FC<Props> = props => {
    const { onCancel, onDelete, type, title, infoText, deleteIcon } = props
    const locale = useContext(LocaleContext)

    return (
        <div className={styles.container}>
            <Title className={styles.title} headingLevel={HeadingLevel.h4} fontSize={FontSize.size16}>
                {title}
            </Title>
            <p className={styles.infoText}>{infoText}</p>
            <div className={styles.buttonContainer}>
                <TextButton onClick={onCancel} buttonStyle={ButtonStyleType.secondary}>
                    {locale._(`${getButtonTranslations()}.cancel`)}
                </TextButton>

                <Button
                    buttonStyle={ButtonStyleType.danger}
                    onClick={onDelete}
                    icon={deleteIcon ? Icons.bin : undefined}
                >
                    {locale._(`${getButtonTranslations()}.title`)}
                </Button>
            </div>
        </div>
    )

    function getButtonTranslations() {
        let id = ''
        switch (type) {
            case 'building':
                id = 'deleteBuilding'
                break
            case 'floor':
                id = 'deleteFloor'
                break
            case 'room':
                id = 'deleteRoom'
                break
            case 'space':
                id = 'deleteSpace'
                break
            case 'sensor':
                id = 'deleteSensor'
                break
        }

        return id
    }
}

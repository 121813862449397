import { Config as ConfigGallery, config as configGallery } from '@unicaiot/unica-iot-gallery-core'

export interface Config extends ConfigGallery {
    mapping: (code: string) => string
    registration: (id: string) => string
    rooms: string
    roomsDetail: (id: string) => string
    kpi: {
        getAllOverviewCounts: string
        getAllOverview: string
    }
    buildings: string
    buildingsView: string
    buildingExpandedRooms: string
    buildingIncludeRooms: string
    buildingImage: (id: string) => string
    buildingsDetailView: (id: string) => string
    buildingsExpandedData: string
    buildingExpandedRoomsByBuildingId: (buildingId: string) => string
    roomsDetailView: (buildingId: string, roomId: string) => string
    roomsListDetailView: (buildingId: string, roomId: string) => string
    sensorsGraphData: (id: string) => string
    sensorsListView: string
    getSensorDetailView: (buildingId: string, roomId: string, dataPointId: string) => string
    hdata: {
        get: string
    }
    defaultLocale: string
    preferences: {
        preferencesCombined: (userId: string) => string
    }
    export: string
    entities: (type: 'rooms' | 'buildings' | 'sensors', id: string) => string
    users: string
    usersAll: string
    userDetail: (id: string) => string
    sensors: (id: string) => string
}

export const config: Config = {
    ...configGallery,
    mapping: (code: string) => `/mapping/co2/${code}`,
    registration: (id: string) => `/api/registration/${id}`,
    rooms: '/api/rooms',
    roomsDetail: (id: string) => `api/rooms/${id}`,
    kpi: {
        getAllOverviewCounts: 'api/kpi/counts',
        getAllOverview: 'api/kpi/all',
    },
    buildings: 'api/buildings',
    sensors: (id: string) => `/api/sensors/${id}`,
    buildingsExpandedData: `api/views/buildings/expand/rooms`,
    buildingExpandedRooms: `/api/views/buildings/expand/rooms`,
    buildingExpandedRoomsByBuildingId: (buildingId: string) => `/api/views/buildings/${buildingId}/expand/rooms`,
    buildingImage: (id: string) => `api/buildings/${id}/image`,
    buildingsView: 'api/views/buildings',
    buildingIncludeRooms: 'api/views/buildings/include/rooms',
    buildingsDetailView: (id: string) => `api/views/buildings/${id}/include/rooms`,
    sensorsListView: `/api/views/buildings/expand/rooms/list/sensors`,
    roomsDetailView: (buildingId: string, roomId: string) => `api/views/buildings/${buildingId}/expand/rooms/${roomId}`,
    roomsListDetailView: (buildingId: string, roomId: string) =>
        `api/views/buildings/${buildingId}/expand/rooms/${roomId}/list/sensors`,
    sensorsGraphData: (id: string) => `api/views/data/sensors/${id}`,
    getSensorDetailView: (buildingId: string, roomId: string, dataPointId: string) =>
        `/api/views/buildings/${buildingId}/expand/rooms/${roomId}/list/sensors/${dataPointId}`,
    hdata: {
        get: '/hs/hisRead',
    },
    defaultLocale: 'nl',
    preferences: {
        preferencesCombined: (userId: string) => `p/preferences/fetch/${userId}`,
    },
    export: 'reports/csv/raw',
    entities: (type: 'rooms' | 'buildings' | 'sensors', id: string) => `api/${type}/${id}`,
    usersAll: 'api/users/all',
    users: 'api/users',
    userDetail: (id: string) => `api/users/${id}`,
}

import { client, ResponsePromise } from '@unicaiot/unica-iot-gallery-core'
import { config } from 'config'
import { BuildingFormData } from 'domain/Buildings/components/BuildingsForm/BuildingsForm'
import { Building, BuildingViewType } from './types'
import { BuildingExpandedRoomsQuery } from '../types/buildingExpandedRooms'

export interface BuildingService {
    getBuildingExpandedRooms: () => ResponsePromise<BuildingExpandedRoomsQuery>
    getBuildingExpandedRoomsByBuildingId: (buildingId: string) => ResponsePromise<BuildingExpandedRoomsQuery>
    getBuilding: (pointRef: string) => ResponsePromise<Building>
    postBuilding: (name: string) => ResponsePromise
    putBuilding: (data: BuildingFormData) => ResponsePromise
    getBuildingDetailView: (params: BuildingViewDetailParamsType) => ResponsePromise<BuildingViewType>
    getBuildingsExpandedData: (params?: BuildingViewDetailParamsType) => ResponsePromise<BuildingViewType[]>
    getBuildingViews: (params?: BuildingsViewParamsType) => ResponsePromise<BuildingViewType[]>
    postBuildingImage: (params: BuildingImageData) => ResponsePromise
    getBuildingImage: (id: string) => ResponsePromise<ArrayBuffer>
    deleteEntity: (id: string) => ResponsePromise
}

export interface BuildingsViewParamsType {
    page?: number
    pageCount?: number
    kpiType?: string
    sensorType?: string
    excludeSensors?: boolean
    orderByType?: string
}

export interface BuildingViewDetailParamsType {
    id: string
    orderByProperty?: string
    orderByType?: string
    kpiType?: string
    sensorType?: string
    excludeSensors?: string
}

export interface BuildingImageData {
    id: string
    file: File
}

export const buildingsService: BuildingService = {
    getBuildingExpandedRooms() {
        return client.get<BuildingExpandedRoomsQuery>(config.buildingExpandedRooms)
    },
    getBuildingExpandedRoomsByBuildingId(buildingId: string) {
        return client.get<BuildingExpandedRoomsQuery>(config.buildingExpandedRoomsByBuildingId(buildingId))
    },
    getBuilding(pointRef?: string) {
        return client.get<Building>(config.buildings, { params: { pointRef } })
    },
    postBuilding(name: string) {
        return client.post(config.buildings, { name })
    },
    putBuilding(data: BuildingFormData) {
        const { id, ...rest } = data
        return client.put(config.entities('buildings', id), rest)
    },
    getBuildingDetailView(params) {
        return client.get<BuildingViewType>(config.buildingsDetailView(params.id), {
            params,
        })
    },
    getBuildingsExpandedData() {
        return client.get<BuildingViewType[]>(config.buildingsExpandedData)
    },
    getBuildingViews(params) {
        return client.get<BuildingViewType[]>(config.buildingsView, {
            params: {
                page: params?.page,
                pageCount: params?.pageCount,
                ['Filter.KpiType']: params?.kpiType,
                ['Filter.SensorType']: params?.sensorType,
                excludeSensors: params?.excludeSensors,
                ['OrderBy.Type']: params?.orderByType,
            },
        })
    },
    deleteEntity(id: string) {
        return client.delete(config.entities('buildings', id))
    },
    postBuildingImage(params: BuildingImageData) {
        const formData = new FormData()
        formData.append('file', params.file)

        return client.post(config.buildingImage(params.id), formData, {
            headers: {
                'content-type': 'multipart/form-data',
                'Content-Disposition': `form-data; name=${params.file.name};`,
            },
        })
    },
    getBuildingImage(id: string) {
        return client.get(config.buildingImage(id), {
            responseType: 'arraybuffer',
        })
    },
}

import React, { useContext, useState } from 'react'
import { ActivityIndicator, ActivityIndicatorEnumType, LocaleContext } from '@unicaiot/unica-iot-gallery-core'
import { RoomTableHeader } from '../RoomTableHeader/RoomTableHeader'
import { useGetRoomsListDetailViews } from 'domain/Rooms/service/roomsService.hooks'
import { Center, InfoText, Table, TableItem, TextColorEnumType } from '@unicaiot/unica-iot-gallery-buildinginsight'
import { Trans } from '@lingui/macro'
import { ErrorBlock } from 'domain/Shared/ErrorBlock/ErrorBlock'
import { Room } from 'domain/Rooms/service/types'
import { routes } from 'views/router/Authorized/routes'
import { useHistory } from 'react-router-dom'
import { DataPointItem } from '../DataPointItem/DataPointItem'
import moment from 'moment'
import { getDataPointUnit } from 'utils/getDataPointUnit'
import { getDataPointType } from 'utils/getDataPointType'
import { SortFunctions } from 'utils/sortByValues'
import { Sensor } from 'domain/Rooms/Sensors/service/types'

enum TableHeaders {
    type = 'type',
    rooms = 'rooms',
    value = 'value',
    checkedOutOn = 'checkedOutOn',
    uniqueCode = 'uniqueCode',
}

const tableHeaders = [
    TableHeaders.type,
    TableHeaders.rooms,
    TableHeaders.value,
    TableHeaders.uniqueCode,
    TableHeaders.checkedOutOn,
]

interface SpaceDetails {
    buildingId: string
    roomId: string
}

interface Props {
    spaceDetails: SpaceDetails
}

export const RoomDetailListView: React.FC<Props> = props => {
    const locale = useContext(LocaleContext)
    const [filter, setFilter] = useState<TableHeaders>()
    const [filteredList, setFilteredList] = useState<Sensor[]>()
    const { spaceDetails } = props
    const history = useHistory()

    const { data, error, loading } = useGetRoomsListDetailViews({
        buildingId: spaceDetails.buildingId,
        roomId: spaceDetails.roomId,
    })

    return renderRoomList()

    function renderRoomList() {
        if (loading) {
            return (
                <Center grow={true}>
                    <ActivityIndicator size={ActivityIndicatorEnumType.large} />
                    <Trans id="common.loading">De data vanuit de datapunten wordt opgehaald.</Trans>
                </Center>
            )
        }

        if (error) {
            return (
                <Center grow={true}>
                    <ErrorBlock />
                </Center>
            )
        }

        if (data && data.room) {
            const { room } = data

            const deepestRoom = findDeepestRoom(room)
            const list = filteredList ?? deepestRoom?.sensors

            return <Table headers={renderHeaders(deepestRoom)} rows={renderListItems(list)} flex={1} />
        }

        return null
    }

    function renderHeaders(room?: Room) {
        return tableHeaders.map((text, index) => {
            if (text === TableHeaders.rooms) {
                return (
                    <RoomTableHeader
                        key={index}
                        headingText={locale._(`roomDetail.headings.${text}`)}
                        isOpen={filter === text}
                    />
                )
            }
            return (
                <RoomTableHeader
                    key={index}
                    headingText={locale._(`roomDetail.headings.${text}`)}
                    onClick={() => {
                        if (filter === text) {
                            setFilter(undefined)
                            setFilteredList(undefined)
                            return
                        }

                        setFilter(text)
                        filterData(text, room?.sensors)
                    }}
                    isOpen={filter === text}
                />
            )
        })
    }

    function renderListItems(data?: Sensor[]) {
        return data?.map((sensor, index) => {
            return (
                <TableItem
                    key={index}
                    onClick={() =>
                        history.push(
                            routes.authorized.buildings.rooms.dataPoints.detail({
                                buildingId: sensor.buildingRef,
                                roomId: sensor.roomRef,
                                dataPointId: sensor.id,
                            })
                        )
                    }
                    data={[
                        <InfoText key={index}>{locale._(`roomDetail.dataPoints.${sensor.type}`)}</InfoText>,
                        renderNames(sensor, index),
                        <DataPointItem
                            key={index}
                            value={`${sensor.latestKpi.value} ${getDataPointUnit(sensor.type)}`}
                            status={sensor.latestKpi.kpi}
                            type={getDataPointType(sensor.type)}
                        />,
                        <InfoText color={TextColorEnumType.grey} key={index}>
                            {sensor.name}
                        </InfoText>,
                        <InfoText color={TextColorEnumType.grey} key={index}>
                            {moment(sensor.modificationDate).format('h:mm - DD-MM-YYYY')}
                        </InfoText>,
                    ]}
                    flex={1}
                />
            )
        })
    }

    function renderNames(sensor: Sensor, index: number) {
        if (spaceDetails.roomId === sensor.roomRef) {
            return (
                <InfoText color={TextColorEnumType.grey} key={index}>
                    {locale._('roomDetail.dataPoints.noRoom')}
                </InfoText>
            )
        }

        return <InfoText key={index}>{sensor.roomName}</InfoText>
    }

    function findDeepestRoom(room: Room): Room {
        if (room.room && room.room.sensors) {
            findDeepestRoom(room.room)
        }

        return room
    }

    function filterData(filter?: TableHeaders, data?: Sensor[]) {
        if (data) {
            let sensors = data

            switch (filter) {
                case TableHeaders.type:
                    sensors = SortFunctions.sortByType(data)
                    break
                case TableHeaders.value:
                    sensors = SortFunctions.sortByValues(data)
                    break
                case TableHeaders.checkedOutOn:
                    sensors = SortFunctions.sortByDates(data)
                    break
                case TableHeaders.rooms:
                    sensors = SortFunctions.sortBySpace(data)
                    break
                case TableHeaders.uniqueCode:
                    sensors = SortFunctions.sortBySensorCode(data)
                    break
            }
            setFilteredList(sensors)
        }
    }
}

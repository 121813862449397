import { DataPointStatus } from '@unicaiot/unica-iot-gallery-buildinginsight'
import { PointKpi } from 'domain/Rooms/Sensors/service/types'

export function getDataPointStatus(pointKpi?: PointKpi): DataPointStatus {
    let status = DataPointStatus.positive

    if (pointKpi) {
        switch (pointKpi) {
            case 'Sufficient':
                status = DataPointStatus.positive
                break
            case 'Moderate':
                status = DataPointStatus.warning
                break
            case 'InSufficient':
                status = DataPointStatus.negative
                break
        }
    }

    return status
}

import { Trans } from '@lingui/macro'
import { Column, PageTitle } from '@unicaiot/unica-iot-gallery-buildinginsight'
import { LocaleContext } from '@unicaiot/unica-iot-gallery-core'
import { checkIfRequestStatusOK } from 'domain/Core/utils/checkIfRequestStatusOK'
import { RoomsForm } from 'domain/Rooms/components/RoomsForm'
import { RoomFormData, roomsService } from 'domain/Rooms/service/roomsService'
import React, { Dispatch, SetStateAction, useContext } from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { RoomsDetailBaseParams } from 'views/router/Authorized/Building/Rooms/routes'
import { routes } from 'views/router/Authorized/routes'

interface Props extends RouteComponentProps<RoomsDetailBaseParams> {}

export const CreateRoomView: React.FC<Props> = ({ match }) => {
    const locale = useContext(LocaleContext)
    const history = useHistory()
    const { buildingId, roomId } = match.params

    const submitRoom = async (data: RoomFormData, setError: Dispatch<SetStateAction<string | undefined>>) => {
        const result = await roomsService.postRoom(data)

        if (checkIfRequestStatusOK(result.status)) {
            if (data.roomRef) {
                history.push(routes.authorized.buildings.rooms.detail({ buildingId, roomId: data.roomRef }))
                return
            }
            history.push(routes.authorized.buildings.detail(buildingId))
            return
        }

        setError(locale._('common.error.title'))
    }

    return (
        <Column>
            <PageTitle>
                <Trans id={'createRoom.title'}>Ruimte toevoegen</Trans>
            </PageTitle>
            <RoomsForm onSave={submitRoom} spaceRefs={{ buildingRef: buildingId, roomRef: roomId }} />
        </Column>
    )
}

import classNames from 'classnames'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Center, HorizontalRule } from '@unicaiot/unica-iot-gallery-buildinginsight'
import styles from './DataPointsDetailGrid.module.scss'
import { DataPointsDetailItem } from '../DataPointsDetailItem/DataPointsDetailItem'
import { ActivityIndicator, ActivityIndicatorEnumType, LocaleContext } from '@unicaiot/unica-iot-gallery-core'
import { ErrorBlock } from 'domain/Shared/ErrorBlock/ErrorBlock'
import { Trans } from '@lingui/macro'
import moment from 'moment'
import { DataPointsDetailContainer } from '../DataPointsDetailContainer/DataPointsDetailContainer'
import { useParams } from 'react-router-dom'
import { BuildingViewType } from 'domain/Buildings/services/buildingService/types'
import { DataPointsDetailBaseParams } from 'views/router/Authorized/Building/Rooms/Datapoints/routes'
import { DataPointFilters } from 'views/pages/DataPoints/DataPointsDetailView/DataPointsDetailView'
import { Graph } from '../Graph/Graph'
import { getDataPointUnit } from 'utils/getDataPointUnit'
import { HData } from 'domain/Rooms/Sensors/service/types'
import { useGetGraphData } from 'domain/Rooms/Sensors/service/sensorsService.hooks'

interface Props {
    className?: string
    buildingData: BuildingViewType
    filters?: DataPointFilters
}

export const DataPointsDetailGrid: React.FC<Props> = props => {
    const { dataPointId } = useParams<DataPointsDetailBaseParams>()
    const { buildingData, filters, className } = props
    const room = buildingData.room
    const classes = classNames(styles.container, className)
    const locale = useContext(LocaleContext)
    const [data, setData] = useState<{ x: number; y: number }[]>()
    const latest = filters?.date?.endDate === new Date()

    const {
        data: dataPointData,
        loading,
        error,
    } = useGetGraphData({
        id: dataPointId,
        endDate: filters?.date?.endDate ? filters?.date.endDate : new Date(),
        startDate: filters?.date?.startDate ? filters?.date.startDate : new Date(),
    })

    const processData = useCallback(
        (
            data: HData | undefined,
            latest: boolean,
            set: (
                value: React.SetStateAction<
                    | {
                          x: number
                          y: number
                      }[]
                    | undefined
                >
            ) => void
        ) => {
            if (data) {
                let points = data?.rows
                    .map(r => {
                        return {
                            x: Date.parse(r.ts.substring(2).split(' ')[0]),
                            y: Math.round(Number.parseFloat(r.val.substring(2))),
                        }
                    })
                    .sort((a, b) => {
                        let comparison = 0
                        if (a.x > b.x) {
                            comparison = 1
                        } else if (a.x < b.x) {
                            comparison = -1
                        }
                        return comparison
                    })

                if (latest && points && points.length > 0) {
                    const start = points[points.length - 1].x - 60 * 60 * 1000
                    points = points.filter(e => e.x >= start)
                }

                set && set(points)
            }
        },
        []
    )

    useEffect(() => {
        if (dataPointData) {
            processData(dataPointData, latest, setData)
        }
    }, [dataPointData, processData, latest])

    return (
        <div className={classes}>
            <DataPointsDetailContainer>
                <DataPointsDetailItem
                    label={locale._('dataPointsDetail.liveValue')}
                    value={`${buildingData.room?.sensorInList?.latestKpi.value} ${getDataPointUnit(
                        room?.sensorInList?.type
                    )}`}
                />
                <HorizontalRule />
                <DataPointsDetailItem label={locale._('dataPointsDetail.location')} value={buildingData.name} />
                <HorizontalRule />
                <DataPointsDetailItem label={locale._('dataPointsDetail.room')} value={buildingData.room?.name ?? ''} />
                <HorizontalRule />
                <DataPointsDetailItem
                    label={locale._('dataPointsDetail.modifiedOn')}
                    value={moment(room?.modificationDate).format('DD MMMM YYYY')}
                />
            </DataPointsDetailContainer>

            {renderLineChart()}
        </div>
    )

    function renderLineChart() {
        if (error) {
            return (
                <Center grow={true}>
                    <ErrorBlock />
                </Center>
            )
        }

        if (loading) {
            return (
                <Center grow={true}>
                    <ActivityIndicator size={ActivityIndicatorEnumType.large} />
                    <Trans id="common.loading">De data vanuit de datapunten wordt opgehaald.</Trans>
                </Center>
            )
        }

        if (data && room?.sensorInList) {
            return <Graph data={data} type={room?.sensorInList?.type} />
        }
    }
}

import { HierarchyList, Modal, PromptModalView, useModal } from '@unicaiot/unica-iot-gallery-buildinginsight'
import { LocaleContext } from '@unicaiot/unica-iot-gallery-core'
import { BuildingExpandedRoomsQuery } from 'domain/Buildings/services/types/buildingExpandedRooms'
import { checkIfRequestStatusOK } from 'domain/Core/utils/checkIfRequestStatusOK'
import { roomsService } from 'domain/Rooms/service/roomsService'
import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { routes } from 'views/router/Authorized/routes'
import { BuildingHierarchyDeleteModal } from '../BuildingHierarchyDeleteModal/BuildingHierarchyDeleteModal'
import { useHierarchyListComponent } from './useHierarchyList'

export interface HierarchyListProps {
    data: BuildingExpandedRoomsQuery
    startingNodeName: string
}

export const BuildingHierarchyList: React.FunctionComponent<HierarchyListProps> = props => {
    const {
        data: { mappedData },
    } = useHierarchyListComponent(props)
    const locale = useContext(LocaleContext)
    const [deleteModalRef, modalActions] = useModal()
    const [id, setId] = useState<string>()
    const history = useHistory()

    if (!mappedData) {
        return null
    }

    return (
        <>
            <HierarchyList
                startingNodeName={locale._({ id: 'BuildingOverviewView.startingNodeName', message: 'Gebouwen' })}
                data={mappedData}
                onEditRoute={routes.authorized.buildings.rooms.edit}
                onAddRoute={routes.authorized.buildings.rooms.create}
                onDeleteAction={id => {
                    setId(id)
                    modalActions.actions.openModal
                }}
            />
            <Modal ref={deleteModalRef}>
                <PromptModalView>
                    <BuildingHierarchyDeleteModal
                        title={locale._({ id: 'BuildingHierarchyDeleteModal.title', message: `Verwijderen` })}
                        message={locale._({ id: 'BuildingHierarchyDeleteModal.message', message: `Verwijderen` })}
                        onCancel={modalActions.actions.closeModal}
                        onDelete={onDeleteBuilding}
                    />
                </PromptModalView>
            </Modal>
        </>
    )

    async function onDeleteBuilding() {
        if (id) {
            try {
                const result = await roomsService.deleteEntity(id)

                if (checkIfRequestStatusOK(result.status)) {
                    history.push(routes.authorized.buildings.index)
                    return
                }
            } catch (e) {
                alert(locale._('common.error.title'))
            }
        }
    }
}

import React from 'react'
import styles from './RegisterSensorFormContainer.module.scss'

interface Props {}

export const RegisterFormContainer: React.FC<Props> = props => {
    const { children } = props

    return <div className={styles.container}>{children}</div>
}

import classNames, { Argument } from 'classnames'
import React from 'react'
import styles from './DataPointsDetailContainer.module.scss'
import { Card, Column, ColumnSpacing } from '@unicaiot/unica-iot-gallery-buildinginsight'

interface Props {
    className?: Argument
}

export const DataPointsDetailContainer: React.FC<Props> = props => {
    const { className, children } = props
    const classes = classNames(styles.container, className)
    return (
        <div className={classes}>
            <Card className={styles.dataPointsDetailCard}>
                <Column spacing={ColumnSpacing.spacing3}>{children}</Column>
            </Card>
        </div>
    )
}

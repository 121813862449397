import React, { Dispatch, SetStateAction, useContext, useState } from 'react'
import {
    Column,
    InfoText,
    TextSizeEnumType,
    TextWeightEnumType,
    Button,
    ButtonStyleType,
    ColumnSpacing,
    Checkbox,
    TextColorEnumType,
} from '@unicaiot/unica-iot-gallery-buildinginsight'
import styles from './ResetPasswordForm.module.scss'
import { authService, LocaleContext } from '@unicaiot/unica-iot-gallery-core'
import { EditPasswordFormData, User } from 'domain/Users/services/types'
import { useHistory } from 'react-router-dom'
import { routes } from 'views/router/Authorized/routes'

interface Props {
    data: User
    loading?: boolean
    onReset: (email: string, setError: Dispatch<SetStateAction<string | undefined>>) => Promise<void>
}

export const ResetPasswordForm: React.FC<Props> = props => {
    const { data, onReset, loading } = props
    const [errorMessage, setErrorMessage] = useState<string>()
    const [checkboxValue, setCheckboxValue] = useState<boolean>()
    const locale = useContext(LocaleContext)

    return (
        <Column className={styles.container} spacing={ColumnSpacing.spacing4}>
            <div className={styles.textContainer}>
                <InfoText size={TextSizeEnumType.large} weight={TextWeightEnumType.bold} className={styles.title}>
                    {locale._({ id: 'resetPasswordForm.title', message: 'Wachtwoord resetten' })}
                </InfoText>
                <InfoText className={styles.alinea1}>
                    {locale._({
                        id: 'resetPasswordForm.message.alinea1',
                        message: `
                        Na het resetten van je wachtwoord ontvang je een e-mail met een link om je wachtwoord opnieuw in te stellen.
                    `,
                    })}
                </InfoText>
            </div>

            <Checkbox
                onChange={v => setCheckboxValue(v.target.checked)}
                label={locale._({
                    id: 'resetPasswordForm.checkbox.label',
                    message: `
                    Ja, ik wil mijn wachtwoord resetten
            `,
                })}
            />
            {errorMessage && (
                <InfoText color={TextColorEnumType.red} size={TextSizeEnumType.medium}>
                    {errorMessage}
                </InfoText>
            )}
            <Button
                buttonStyle={ButtonStyleType.primary}
                isSubmit={true}
                loading={loading}
                className={styles.submitButton}
                isDisabled={!checkboxValue}
                onClick={() => onReset(data.email, setErrorMessage)}
            >
                {locale._({
                    id: 'resetPasswordForm.submitButtonLabel',
                    message: 'Bevestig wachtwoord',
                })}
            </Button>
        </Column>
    )
}

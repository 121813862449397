import React, { Fragment, useContext, useState } from 'react'
import {
    Center,
    Column,
    ColumnSpacing,
    Icons,
    MoreButton,
    PageTitle,
} from '@unicaiot/unica-iot-gallery-buildinginsight'
import { ActivityIndicator, ActivityIndicatorEnumType, LocaleContext } from '@unicaiot/unica-iot-gallery-core'
import { RouteComponentProps, useParams } from 'react-router-dom'
import { Trans } from '@lingui/macro'
import { ErrorBlock } from 'domain/Shared/ErrorBlock/ErrorBlock'
import { routes } from 'views/router/Authorized/routes'
import { LiveFilter, SessionStorageValues } from 'domain/Shared/LiveFilter/LiveFilter'
import { DataPointsDetailGrid } from 'domain/DataPoints/Components/DataPointsDetailGrid/DataPointsDetailGrid'
import { DataPointsDetailBaseParams } from 'views/router/Authorized/Building/Rooms/Datapoints/routes'
import { useGetSensorDetailView } from 'domain/Rooms/Sensors/service/sensorsService.hooks'

interface Props extends RouteComponentProps<DataPointsDetailBaseParams> {}

export interface DataPointFilters {
    timeframe?: string
    date?: { endDate: Date; startDate: Date }
}

export const LIVE_FILTER_SESSION_STORAGE_KEY = 'unica-bi-live-filter-state'

export const DataPointsDetailView: React.FC<Props> = () => {
    const { dataPointId, buildingId, roomId } = useParams<DataPointsDetailBaseParams>()
    const sessionStorage = window.sessionStorage.getItem(LIVE_FILTER_SESSION_STORAGE_KEY)
    const defaultValues = sessionStorage ? (JSON.parse(sessionStorage) as SessionStorageValues) : undefined
    const [filters, setFilters] = useState<DataPointFilters | undefined>(getAverageValues(defaultValues))
    const locale = useContext(LocaleContext)

    const { data, error, loading } = useGetSensorDetailView(
        {
            buildingId,
            roomId,
            dataPointId,
        },
        [filters]
    )

    return <Column spacing={ColumnSpacing.spacing5}>{renderData()}</Column>

    function renderData() {
        if (error) {
            return (
                <Center grow={true}>
                    <ErrorBlock />
                </Center>
            )
        }

        if (loading) {
            return (
                <Center grow={true}>
                    <ActivityIndicator size={ActivityIndicatorEnumType.large} />
                    <Trans id="common.loading">De data vanuit de datapunten wordt opgehaald.</Trans>
                </Center>
            )
        }

        if (data && data.room) {
            const room = data.room
            return (
                <>
                    <PageTitle
                        breadCrumbs={[
                            {
                                label: data.name,
                                to: routes.authorized.buildings.detail(buildingId),
                            },
                            {
                                label: room.name,
                                to: routes.authorized.buildings.rooms.detail({ buildingId, roomId }),
                            },
                        ]}
                        renderActions={() => (
                            <Fragment>
                                <LiveFilter
                                    onFilterChange={value => {
                                        const { timeframe, date } = value

                                        setFilters({
                                            timeframe,
                                            date: {
                                                endDate: date[0],
                                                startDate: date[1],
                                            },
                                        })
                                    }}
                                />
                                <MoreButton
                                    topLinks={[
                                        {
                                            to: routes.authorized.buildings.rooms.dataPoints.edit({
                                                buildingId,
                                                roomId,
                                                dataPointId,
                                            }),
                                            label: locale._('dataPointsDetail.menu.edit'),
                                            icon: Icons.edit,
                                        },
                                    ]}
                                />
                            </Fragment>
                        )}
                    >
                        {locale._(`roomDetail.dataPoints.${room.sensorInList?.type}`)}
                    </PageTitle>
                    <DataPointsDetailGrid buildingData={data} filters={filters} />
                </>
            )
        }
    }

    function getAverageValues(values?: SessionStorageValues): DataPointFilters | undefined {
        if (!values) {
            return undefined
        }

        return {
            timeframe: values.timeframe,
            date: { endDate: values.date[0], startDate: values.date[1] },
        }
    }
}

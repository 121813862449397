import React, { FunctionComponent } from 'react'
import { Route, Switch, withRouter, Redirect, RouteComponentProps } from 'react-router-dom'
import { config } from './config'
import { TelemetryProvider, Shield, withLogin, useResize, useScroll } from '@unicaiot/unica-iot-gallery-core'
import { GlobalErrorView, UnauthorizedView } from '@unicaiot/unica-iot-gallery-buildinginsight'
import { AuthorizedView } from 'views/router/Authorized/AuthorizedView'
import { routes } from 'views/router/Authorized/routes'

withLogin()

const TelemetryProviderWithRouter = withRouter(TelemetryProvider)

export const RootView: FunctionComponent = () => {
    useScroll()

    useResize()

    return (
        <Shield
            getErrorView={() => {
                return <GlobalErrorView />
            }}
        >
            <TelemetryProviderWithRouter instrumentationKey={config.instrumentationKey}>
                <Switch>
                    <Route path={routes.unauthorized.index} component={UnauthorizedView} />
                    <Route path={routes.authorized.index} component={AuthorizedView} />
                    <Redirect to={config.defaultPath} />
                </Switch>
            </TelemetryProviderWithRouter>
        </Shield>
    )
}

import { Card, Column, ColumnSpacing, Row, RowSpacing } from '@unicaiot/unica-iot-gallery-buildinginsight'
import { RoomCardHeader } from 'domain/Shared/Buildings/Rooms/RoomCardHeader/RoomCardHeader'
import React from 'react'
import styles from './RoomInfoCard.module.scss'

interface Props {
    title: string
    type: 'floor' | 'room' | 'space' | string
    rooms: number
    sensors: number
    modificationDate?: string
    data?: JSX.Element[] | JSX.Element
    onClick: () => void
}

export const RoomInfoCard: React.FC<Props> = props => {
    const { title, type, rooms, sensors, data, modificationDate, onClick } = props

    return (
        <Card className={styles.container} onClick={onClick}>
            <Column spacing={ColumnSpacing.spacing1}>
                <RoomCardHeader
                    title={title}
                    rooms={rooms}
                    sensors={sensors}
                    modificationDate={modificationDate}
                    type={type}
                />
                <Row spacing={RowSpacing.spacing3}>{data}</Row>
            </Column>
        </Card>
    )
}

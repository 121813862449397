import { Trans } from '@lingui/macro'
import { Column, ColumnSpacing, PageTitle } from '@unicaiot/unica-iot-gallery-buildinginsight'
import { LocaleContext } from '@unicaiot/unica-iot-gallery-core'
import { BuildingFormData, BuildingsForm } from 'domain/Buildings/components/BuildingsForm/BuildingsForm'
import { buildingsService } from 'domain/Buildings/services/buildingService/buildingService'
import { checkIfRequestStatusOK } from 'domain/Core/utils/checkIfRequestStatusOK'
import React, { Dispatch, FunctionComponent, SetStateAction, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { routes } from 'views/router/Authorized/routes'

export const CreateBuildingsView: FunctionComponent = () => {
    const locale = useContext(LocaleContext)
    const history = useHistory()

    const submitBuilding = async (data: BuildingFormData, setError: Dispatch<SetStateAction<string | undefined>>) => {
        const result = await buildingsService.postBuilding(data.name)

        if (checkIfRequestStatusOK(result.status)) {
            // TODO: redirect when the ID is added as response data
            history.push(routes.authorized.buildings.index)

            return
        }

        setError(locale._('common.error.title'))
    }

    return (
        <Column spacing={ColumnSpacing.spacing5}>
            <PageTitle>
                <Trans id={'createBuildings.title'}>Gebouw toevoegen</Trans>
            </PageTitle>
            <BuildingsForm onSave={submitBuilding} />
        </Column>
    )
}

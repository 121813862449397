import {
    ActivityIndicator,
    ActivityIndicatorEnumType,
    authService,
    LocaleContext,
    useUser,
} from '@unicaiot/unica-iot-gallery-core'
import { useGetUser } from 'domain/Users/services/userService.hooks'
import React, { Dispatch, SetStateAction, useContext, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { UserDetailBaseParams } from '../../routes'
import {
    ButtonStyleType,
    Center,
    Column,
    ColumnSpacing,
    Icons,
    PageTitle,
    Row,
    RowSpacing,
    TextButton,
} from '@unicaiot/unica-iot-gallery-buildinginsight'
import { ErrorBlock } from 'domain/Shared/ErrorBlock/ErrorBlock'
import { Trans } from '@lingui/macro'
import { routes } from 'views/router/Authorized/routes'
import { UserMenu } from 'domain/Users/Components/UserMenu/UserMenu'
import { UserMenuItems } from 'domain/Users/Components/UserMenuItems/UserMenuItems'
import { ResetPasswordForm } from 'domain/Users/Components/ResetPasswordForm/ResetPasswordForm'
import { ResetPasswordSuccessBlock } from 'domain/Users/Components/EditPasswordSuccessBlock/ResetPasswordSuccessBlock'
import { Roles, User } from 'domain/Users/services/types'
import { MenuTranslationsType, MenuItems } from '../UserReadView/UserReadView'

export const ResetUserPasswordView: React.FC = () => {
    const locale = useContext(LocaleContext)
    const { id } = useParams<UserDetailBaseParams>()
    const [succeeded, setSucceeded] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const { data, loading: userDataLoading, error } = useGetUser(id)
    const user = useUser()
    const history = useHistory()

    const menuTranslations: MenuTranslationsType = {
        [MenuItems.profile]: locale._({ id: 'userReadView.menuItems.profile', message: 'Profiel' }),
        [MenuItems.resetPassword]: locale._({
            id: 'userReadView.menuItems.resetPassword',
            message: 'Wachtwoord resetten',
        }),
        [MenuItems.deleteAccount]: locale._({
            id: 'userReadView.menuItems.deleteAccount',
            message: 'Account verwijderen',
        }),
    }

    const handleOnReset = async (email: string, setError: Dispatch<SetStateAction<string | undefined>>) => {
        try {
            setLoading(true)

            const result = await authService.postResetPassword(email)

            if (result.status === 200) {
                setSucceeded(true)
            } else {
                setError(result.statusText)
            }
        } catch (e: any) {
            setError(e.message)
        } finally {
            setLoading(false)
        }
    }

    return (
        <>
            <Column spacing={ColumnSpacing.spacing5}>{renderView()}</Column>
        </>
    )

    function renderView() {
        if (error) {
            return (
                <Center grow={true}>
                    <ErrorBlock />
                </Center>
            )
        }

        if (userDataLoading) {
            return (
                <Center grow={true}>
                    <ActivityIndicator size={ActivityIndicatorEnumType.large} />
                    <Trans id="common.loading">De data vanuit de datapunten wordt opgehaald.</Trans>
                </Center>
            )
        }

        if (data) {
            return (
                <>
                    <PageTitle
                        breadCrumbs={[
                            {
                                label: locale._({ id: 'userReadView.users', message: 'Gebruikers' }),
                                to: routes.authorized.users.index,
                            },
                        ]}
                    >
                        <Trans id="users.title">{data.firstName}</Trans>
                    </PageTitle>
                    <Row spacing={RowSpacing.spacing17} grow={true}>
                        <Column>
                            <TextButton
                                icon={Icons.return}
                                buttonStyle={ButtonStyleType.secondary}
                                onClick={() => history.push(routes.authorized.users.index)}
                            >
                                {locale._({ id: 'userReadView.goBackToOverView', message: 'Terug naar overzicht' })}
                            </TextButton>
                            <UserMenu elements={renderMenuElements(data)} />
                        </Column>
                        {renderPasswordForm(data)}
                    </Row>
                </>
            )
        }
    }

    function renderPasswordForm(data: User) {
        if (succeeded) {
            return <ResetPasswordSuccessBlock id={data.id} />
        }

        return <ResetPasswordForm onReset={handleOnReset} data={data} loading={loading} />
    }

    function renderMenuElements(data: User) {
        if (user?.sub === data.id || user?.role === Roles.superAdmin || user?.role.includes(Roles.superAdmin)) {
            return [
                <UserMenuItems
                    key={`user-menu-item-${MenuItems.profile}-${routes.authorized.users.detail}`}
                    label={menuTranslations['profile']}
                    to={routes.authorized.users.detail(id)}
                />,
                <UserMenuItems
                    key={`user-menu-item-${MenuItems.resetPassword}`}
                    label={menuTranslations['resetPassword']}
                    to={routes.authorized.users.editPassword(id)}
                    isActive={true}
                />,
            ]
        }

        return [
            <UserMenuItems
                key={`user-menu-item-${MenuItems.profile}-${routes.authorized.users.detail}`}
                label={menuTranslations['profile']}
                to={routes.authorized.users.detail(id)}
            />,
        ]
    }
}

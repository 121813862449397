import React, { FunctionComponent, useState, useEffect, useContext, useRef } from 'react'
import { XYPlot, XAxis, YAxis, Crosshair, LineSeriesPoint, LineSeries, CustomSVGSeries } from 'react-vis'
import '../../../../../node_modules/react-vis/dist/style.css'
import dateFormat from 'dateformat'
import { isMobile, withOrientationChange } from 'react-device-detect'
import { LocaleContext } from '@unicaiot/unica-iot-gallery-core'
import styles from './Graph.module.scss'
import d3Locale from '../../../Core/utils/d3Locale'
import displayRanges from '../GraphUtils/DisplayRanges'
import { getDataPointUnit } from 'utils/getDataPointUnit'
import trunc from '../GraphUtils/Trunc'
import { Card } from '@unicaiot/unica-iot-gallery-buildinginsight'
import { Type } from 'domain/Rooms/Sensors/service/types'

interface GraphProp {
    data: { x: number; y: number }[]
    type: Type
}

const GraphInternal: FunctionComponent<GraphProp> = ({ data, type }) => {
    const ref = useRef<HTMLDivElement>(null)
    const locale = useContext(LocaleContext)

    const [width, setWidth] = useState<number>(0)
    const [height, setHeight] = useState<number>(0)

    const [value, setValue] = useState<LineSeriesPoint>()

    const [avg, setAvg] = useState<{ x: number; y: number }>()

    const [yDomain, setYDomain] = useState(displayRanges.get(type)?.[0])

    useEffect(() => {
        if (ref && ref.current) {
            setWidth(ref.current.clientWidth - 32)
            setHeight(ref.current.clientHeight - 32)
        }
    }, [ref])

    useEffect(() => {
        if (data?.length > 0) {
            const points = data

            const x0 = points[0].x

            const y = points.map(e => e.y).sort((a, b) => a - b)
            if (y?.length > 0) {
                const yMin =
                    Math.trunc(
                        Math.min(
                            displayRanges.get(type)?.[0][0] || y[0],
                            Math.max(y[0], displayRanges.get(type)?.[1][0] || y[0])
                        ) / (displayRanges.get(type)?.[2][0] || 10)
                    ) * (displayRanges.get(type)?.[2][0] || 10)

                const yMax =
                    Math.ceil(
                        Math.max(
                            displayRanges.get(type)?.[0][1] || y[y.length - 1],
                            Math.min(y[y.length - 1], displayRanges.get(type)?.[1][1] || y[y.length - 1])
                        ) / (displayRanges.get(type)?.[2][0] || 10)
                    ) * (displayRanges.get(type)?.[2][0] || 10)

                setYDomain([yMin, yMax])

                const avg = (arr => arr.reduce((p, c) => p + c, 0) / arr.length)(y)

                setAvg({ x: x0, y: parseFloat(avg.toFixed(trunc.get(type))) })
            }
        }
    }, [data, type])

    return (
        <div ref={ref}>
            <Card>
                <XYPlot
                    xType={'time'}
                    width={width}
                    height={height}
                    onMouseLeave={() => setValue(undefined)}
                    style={{ margin: 'auto' }}
                    yDomain={yDomain}
                >
                    <XAxis
                        tickTotal={isMobile ? 5 : 10}
                        style={{
                            fontSize: 11,
                            ticks: { fill: '#A8A8B5', fontWeight: 500 },
                        }}
                        hideLine={true}
                        tickFormat={d3Locale}
                    />
                    <YAxis
                        tickTotal={isMobile ? 5 : 10}
                        style={{
                            fontSize: 11,
                            ticks: { fill: '#A8A8B5', fontWeight: 500 },
                        }}
                        tickFormat={t => new Intl.NumberFormat(undefined, { maximumFractionDigits: 0 }).format(t)}
                        hideLine={true}
                    />

                    <LineSeries
                        data={data}
                        onNearestX={d => setValue(d)}
                        curve={'curveMonotoneX'}
                        color={'#E32935'}
                        style={{
                            strokeWidth: 3,
                        }}
                    />

                    {avg && (
                        <CustomSVGSeries
                            data={[
                                {
                                    customComponent: 'circle',
                                    style: { fill: '#E32935' },
                                    size: 10,
                                    ...avg,
                                },
                            ]}
                        />
                    )}
                    {value && (
                        <Crosshair
                            className={styles.crosshair}
                            values={[value]}
                            titleFormat={() => {
                                return {
                                    title: '',
                                    value: locale._({
                                        id: 'dataOverview.timeValue',
                                        message: `op ${dateFormat(
                                            value.x,
                                            'd mmmm yyyy'
                                        ).toLowerCase()} om ${dateFormat(value.x, 'HH:MM')}`,
                                    }),
                                }
                            }}
                            itemsFormat={() => {
                                return [
                                    {
                                        title: '',
                                        value: `${value.y} ${getDataPointUnit(type)}`,
                                    },
                                ]
                            }}
                            style={{
                                line: { backgroundColor: '#A8A8B5' },
                                box: { backgroundColor: '#fff', borderColor: '#d7d7d7' },
                            }}
                        />
                    )}
                </XYPlot>
            </Card>
        </div>
    )
}

const Graph = withOrientationChange(GraphInternal)

export { Graph }

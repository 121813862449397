import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { BuildingOverviewView } from 'views/pages/Buildings/BuildingOverviewView/BuildingOverviewView'
import { CreateRoomView } from 'views/pages/Rooms/CreateRoomView/CreateRoomView'
import { EditRoomView } from 'views/pages/Rooms/EditRoomView/EditRoomView'
import { RoomDetailView } from 'views/pages/Rooms/RoomDetailView/RoomDetailView'
import { routes } from '../../routes'
import { DataPointsView } from './Datapoints/DataPointsView'

interface Props {}

export const RoomsView: React.FC<Props> = () => {
    return (
        <Switch>
            <Route path={routes.authorized.buildings.rooms.detail()} exact={true} component={RoomDetailView} />
            <Route path={routes.authorized.buildings.rooms.create()} exact={true} component={CreateRoomView} />
            <Route path={routes.authorized.buildings.rooms.edit()} exact={true} component={EditRoomView} />
            <Route path={routes.authorized.buildings.rooms.dataPoints.detail()} component={DataPointsView} />
            <Route
                path={routes.authorized.buildings.rooms.buildingOverview()}
                exact={true}
                component={BuildingOverviewView}
            />
        </Switch>
    )
}

import classNames, { Argument } from 'classnames'
import React from 'react'
import styles from './UserMenu.module.scss'

interface Props {
    className?: Argument
    elements: JSX.Element[]
}

export const UserMenu: React.FC<Props> = props => {
    const { elements, className } = props
    const classes = classNames(styles.dropdown, className)

    return (
        <div className={classes}>
            <div className={styles.menu}>
                <div className={classNames(styles.dropdownWrapper, styles.items)}>{elements}</div>
            </div>
        </div>
    )
}

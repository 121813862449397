import { Trans } from '@lingui/macro'
import {
    Button,
    ButtonStyleType,
    Column,
    ColumnSpacing,
    FontSize,
    HeadingLevel,
    JustifyContent,
    Row,
    Title,
} from '@unicaiot/unica-iot-gallery-buildinginsight'
import React from 'react'
import { useHistory } from 'react-router-dom'

export const ErrorBlock: React.FC = () => {
    const history = useHistory()

    return (
        <Column spacing={ColumnSpacing.spacing4}>
            <Row justifyContent={JustifyContent.center}>
                <Title headingLevel={HeadingLevel.h2} fontSize={FontSize.size22}>
                    <Trans id="common.error.title">Er is iets fout gegaan</Trans>
                </Title>
            </Row>
            <Row justifyContent={JustifyContent.center}>
                <Trans id="common.error.message">
                    Er ging iets mis met het laden van de pagina. Keer terug naar de vorige pagina.
                </Trans>
            </Row>
            <Row justifyContent={JustifyContent.center}>
                <Button
                    buttonStyle={ButtonStyleType.primary}
                    onClick={() => {
                        history.goBack()
                    }}
                >
                    <Trans id={'common.goBack'}>Terug naar vorige pagina</Trans>
                </Button>
            </Row>
        </Column>
    )
}

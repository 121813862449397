import { BuildingRoutesBaseParams } from '../routes'
import { dataPointsRoutes } from './Datapoints/routes'

export interface RoomsDetailBaseParams extends BuildingRoutesBaseParams {
    roomId: string
}

export interface CreateRoomDetailParams extends BuildingRoutesBaseParams {
    roomId?: string
}

export const roomsRoutes = {
    create: (params: CreateRoomDetailParams = { buildingId: ':buildingId', roomId: ':roomId' }) =>
        `/buildings/${params?.buildingId}/rooms/${params.roomId}/create`,
    detail: (params: RoomsDetailBaseParams = { buildingId: ':buildingId', roomId: ':roomId' }) =>
        `/buildings/${params?.buildingId}/rooms/${params?.roomId}`,
    edit: (params: RoomsDetailBaseParams = { buildingId: ':buildingId', roomId: ':roomId' }) =>
        `/buildings/${params.buildingId}/rooms/${params.roomId}/edit`,
    buildingOverview: (params: RoomsDetailBaseParams = { buildingId: ':buildingId', roomId: ':roomId' }) =>
        `/buildings/${params.buildingId}/rooms/${params.roomId}/buildingoverview`,
    dataPoints: dataPointsRoutes,
}

import { Trans } from '@lingui/macro'
import {
    Center,
    Column,
    ColumnSpacing,
    Icons,
    MoreButton,
    PageTitle,
    TextButton,
} from '@unicaiot/unica-iot-gallery-buildinginsight'
import { ActivityIndicator, ActivityIndicatorEnumType, LocaleContext } from '@unicaiot/unica-iot-gallery-core'
import { RoomDetailListView } from 'domain/Rooms/components/RoomDetailListView/RoomDetailListView'
import { RoomsDetailGridView } from 'domain/Rooms/components/RoomsDetailGridView/RoomsDetailGridView'
import { useGetRoomViews } from 'domain/Rooms/service/roomsService.hooks'
import { Room } from 'domain/Rooms/service/types'
import { EntityFilter, Filters } from 'domain/Shared/EntityFilter/EntityFilter'
import { ErrorBlock } from 'domain/Shared/ErrorBlock/ErrorBlock'
import { LiveFilter } from 'domain/Shared/LiveFilter/LiveFilter'
import React, { Fragment, useContext, useState } from 'react'
import { RouteComponentProps, useParams } from 'react-router-dom'
import { routes } from 'views/router/Authorized/routes'

interface RouteParams {
    buildingId: string
    roomId: string
}

interface Props extends RouteComponentProps<RouteParams> {}

type Layout = 'grid' | 'list'

export const RoomDetailView: React.FC<Props> = () => {
    const locale = useContext(LocaleContext)
    const [filters, setFilters] = useState<Filters>()
    const { buildingId, roomId } = useParams<RouteParams>()
    const { data, error, loading } = useGetRoomViews({ buildingId, roomId }, [roomId, filters])
    const [layout, setLayout] = useState<Layout>('grid')
    const space = findDeepestRoom(data?.room)

    return (
        <Column spacing={ColumnSpacing.spacing5}>
            <PageTitle
                breadCrumbs={[
                    {
                        label: data?.name ?? '',
                        to: routes.authorized.buildings.detail(buildingId),
                    },
                    {
                        label: space?.name ?? '',
                        to: routes.authorized.buildings.rooms.detail({ buildingId, roomId: space?.id ?? '' }),
                    },
                ]}
                renderActions={() => (
                    <Fragment>
                        <EntityFilter
                            onChange={filters => setFilters(filters)}
                            sortByLabel={<Trans id="Dashboard.filters.sortRooms">Ruimtes sorteren</Trans>}
                        />
                        {renderLayoutButton()}
                        <LiveFilter />
                        <MoreButton
                            topLinks={[
                                {
                                    to: routes.authorized.buildings.rooms.buildingOverview({
                                        buildingId: buildingId,
                                        roomId: roomId,
                                    }),
                                    label: locale._({
                                        id: 'Dashboard.buildingOverview',
                                        message: 'Gebouw overzicht',
                                    }),
                                },
                                {
                                    to: routes.authorized.buildings.rooms.edit({
                                        buildingId: buildingId,
                                        roomId: roomId,
                                    }),

                                    label: locale._({
                                        id: 'Dashboard.editRoom',
                                        message: 'Ruimte aanpassen',
                                    }),
                                    icon: Icons.edit,
                                },
                                {
                                    to: routes.authorized.buildings.rooms.create({
                                        buildingId: buildingId,
                                        roomId: roomId,
                                    }),
                                    label: locale._({
                                        id: 'Dashboard.editRoom',
                                        message: 'Ruimte toevoegen',
                                    }),
                                    icon: Icons.add,
                                },
                            ]}
                        />
                    </Fragment>
                )}
            >
                {space?.name}
            </PageTitle>
            {renderRoomsLayout()}
        </Column>
    )

    function renderGridRooms() {
        if (loading) {
            return (
                <Center grow={true}>
                    <ActivityIndicator size={ActivityIndicatorEnumType.large} />
                    <Trans id="common.loading">De data vanuit de datapunten wordt opgehaald.</Trans>
                </Center>
            )
        }

        if (error) {
            return (
                <Center grow={true}>
                    <ErrorBlock />
                </Center>
            )
        }

        if (data) {
            const { room } = data

            if (room) {
                const deepestRoom = findDeepestRoom(room)

                if (deepestRoom) {
                    return (
                        <>
                            <RoomsDetailGridView data={deepestRoom} spaceDetails={{ buildingId, roomId }} />
                        </>
                    )
                }
            }
        }
    }

    function renderLayoutButton() {
        if (layout === 'list') {
            return (
                <TextButton icon={Icons.grid} onClick={() => setLayout('grid')}>
                    {locale._('roomDetail.layoutButton.grid')}
                </TextButton>
            )
        }

        return (
            <TextButton icon={Icons.list} onClick={() => setLayout('list')}>
                {locale._('roomDetail.layoutButton.list')}
            </TextButton>
        )
    }

    function renderRoomsLayout() {
        if (layout === 'grid') {
            return renderGridRooms()
        }

        return <RoomDetailListView spaceDetails={{ buildingId, roomId }} />
    }

    function findDeepestRoom(space?: Room): Room | undefined {
        if (space) {
            if (!space.room) {
                return space
            }
            return findDeepestRoom(space.room)
        }

        return undefined
    }
}

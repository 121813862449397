import { Trans } from '@lingui/macro'
import {
    Row,
    JustifyContent,
    RowSpacing,
    TextButton,
    ButtonStyleType,
    Button,
    PreviewLayout,
    Column,
    ColumnSpacing,
    Input,
    InputStyle,
    Tooltip,
    Select,
    useModal,
    Modal,
    PromptModalView,
} from '@unicaiot/unica-iot-gallery-buildinginsight'
import { LocaleContext } from '@unicaiot/unica-iot-gallery-core'
import { checkIfRequestStatusOK } from 'domain/Core/utils/checkIfRequestStatusOK'
import { FormDeleteModal } from 'domain/Shared/FormDeleteModal/FormDeleteModal'
import React, { Dispatch, Fragment, SetStateAction, useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { routes } from 'views/router/Authorized/routes'
import { RoomFormData, roomsService } from '../service/roomsService'
import { Room } from '../service/types'
import { CreateRoomsPreviewSvg } from './CreateRoomsPreviewSvg'

interface Props {
    onSave: (data: RoomFormData, setError: Dispatch<SetStateAction<string | undefined>>) => void
    saveLabel?: string | JSX.Element
    room?: Room
    spaceRefs: SpaceRefs
}

enum RoomsType {
    room = 'room',
    underlyingSpace = 'underlying space',
}

interface SpaceRefs {
    buildingRef: string
    roomRef?: string
}

export const RoomsForm: React.FC<Props> = ({ onSave, room, spaceRefs }) => {
    const locale = useContext(LocaleContext)

    const [name, setName] = useState<string | undefined>(room?.name)
    const [type, setType] = useState<string | undefined>(room?.type || RoomsType.room)
    const [nameError, setNameError] = useState<string>()
    const [typeError, setTypeError] = useState<string>()

    const history = useHistory()
    const [deleteModalRef, modalActions] = useModal()

    return (
        <>
            <PreviewLayout
                preview={<CreateRoomsPreviewSvg name={name} />}
                footer={
                    <Fragment>
                        {room ? (
                            <Row justifyContent={JustifyContent.spaceBetween}>
                                {renderDeleteButton()}
                                <Modal ref={deleteModalRef}>
                                    <PromptModalView>
                                        <FormDeleteModal
                                            title={locale._('editRoom.title', { name: room.name })}
                                            infoText={locale._('editRoom.message', { name: room.name })}
                                            type={'room'}
                                            onCancel={modalActions.actions.closeModal}
                                            onDelete={onDeleteBuilding}
                                        />
                                    </PromptModalView>
                                </Modal>

                                {renderBasicFooterButtons()}
                            </Row>
                        ) : (
                            renderBasicFooterButtons()
                        )}
                    </Fragment>
                }
            >
                <Column spacing={ColumnSpacing.spacing4}>
                    <Select
                        label={<Trans id={'roomsForm.type'}>Type ruimte</Trans>}
                        backgroundColor={'white'}
                        value={type}
                        onChange={value => setType(value)}
                        error={!!typeError}
                        errorMessage={typeError}
                        options={[
                            {
                                label: locale._('options.room'),
                                value: RoomsType.room,
                            },

                            {
                                label: locale._('options.underlyingSpace'),
                                value: RoomsType.underlyingSpace,
                            },
                        ]}
                    />
                    <Input
                        value={name}
                        onChange={(v: string) => setName(v)}
                        style={InputStyle.light}
                        label={<Trans id={'roomsForm.name.label'}>Naam ruimte</Trans>}
                        error={!!nameError}
                        errorMessage={nameError}
                    />
                </Column>
            </PreviewLayout>
        </>
    )

    function renderBasicFooterButtons() {
        return (
            <Row justifyContent={JustifyContent.flexEnd} spacing={RowSpacing.spacing4}>
                <TextButton
                    onClick={() => history.push(routes.authorized.buildings.detail(spaceRefs.buildingRef))}
                    buttonStyle={ButtonStyleType.secondary}
                >
                    {locale._('common.cancel')}
                </TextButton>
                <Button onClick={onSaveBuilding}>{locale._('common.save')}</Button>
            </Row>
        )
    }

    function renderDeleteButton(): JSX.Element {
        if (!room?.rooms?.length) {
            return (
                <Button buttonStyle={ButtonStyleType.danger} onClick={modalActions.actions.openModal}>
                    <Trans id={'deleteRoom.delete'}>Ruimte verwijderen</Trans>
                </Button>
            )
        }

        return (
            <Tooltip place="right" effect="solid" text={locale._('deleteBuilding.error.message')}>
                <Button buttonStyle={ButtonStyleType.danger} onClick={modalActions.actions.openModal} isDisabled={true}>
                    <Trans id={'deleteRoom.delete'}>Ruimte verwijderen</Trans>
                </Button>
            </Tooltip>
        )
    }

    async function onDeleteBuilding() {
        if (!room) {
            return
        }

        try {
            const result = await roomsService.deleteEntity(room.id)

            if (checkIfRequestStatusOK(result.status)) {
                history.push(routes.authorized.buildings.index)
                return
            }
        } catch (e) {
            setNameError(locale._('common.error.title'))
            alert(locale._('common.error.title'))
        }
    }

    function onSaveBuilding() {
        if (!name) {
            setNameError(locale._('createRoom.nameError'))
            return
        }

        if (!type) {
            setTypeError(locale._('createRoom.typeError'))
            return
        }

        onSave(
            {
                name,
                type,
                buildingRef: spaceRefs.buildingRef,
                roomRef: spaceRefs.roomRef,
            },
            setNameError
        )
    }
}

import React, { useContext } from 'react'
import {
    Column,
    InfoText,
    Input,
    Avatar,
    InputStyle,
    Row,
    TextSizeEnumType,
    TextWeightEnumType,
    ColumnSpacing,
} from '@unicaiot/unica-iot-gallery-buildinginsight'
import { LocaleContext } from '@unicaiot/unica-iot-gallery-core'
import styles from './UserReadForm.module.scss'
import { Roles } from 'domain/Users/services/types'

interface Props {
    data: UserData
}

interface UserData {
    name: string
    email: string
    role: Roles[] | Roles
}

export const UserReadForm: React.FC<Props> = props => {
    const { data } = props
    const locale = useContext(LocaleContext)

    return (
        <Column className={styles.container} spacing={ColumnSpacing.spacing4}>
            <InfoText size={TextSizeEnumType.large} weight={TextWeightEnumType.bold}>
                {locale._({ id: 'userReadView.profile', message: 'Profiel' })}
            </InfoText>
            <Avatar initials={getInitials()} />
            <InfoText size={TextSizeEnumType.large} weight={TextWeightEnumType.bold}>
                {locale._({ id: 'userReadView.info', message: 'Gegevens' })}
            </InfoText>
            <Row className={styles.inputContainer}>
                <Input
                    value={data.name}
                    style={InputStyle.light}
                    label={<>{locale._({ id: 'userReadView.fullName', message: 'Volledige naam' })}</>}
                    readonly={true}
                    className={styles.input}
                />
                <Input
                    value={data.email}
                    style={InputStyle.light}
                    label={<>{locale._({ id: 'userReadView.email', message: 'E-mailadres' })}</>}
                    readonly={true}
                    className={styles.input}
                />
            </Row>
            <InfoText size={TextSizeEnumType.large} weight={TextWeightEnumType.bold}>
                {locale._({ id: 'userReadView.account', message: 'Account' })}
            </InfoText>
            <Row className={styles.inputContainer}>
                <Input
                    value={`${data.role}`}
                    style={InputStyle.light}
                    label={<>{locale._({ id: 'userReadView.role', message: 'Rol' })}</>}
                    readonly={true}
                    className={styles.input}
                />
            </Row>
        </Column>
    )

    function getInitials() {
        const names = data.name.split(' ')
        const initials = names.map(name => name.charAt(0))

        return initials.join('')
    }
}

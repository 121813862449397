import { Trans } from '@lingui/macro'
import React from 'react'
import styles from './RoomDetails.module.scss'

interface Props {
    rooms?: number
    sensors?: number
    modificationDate?: string
}

export const RoomDetails: React.FC<Props> = props => {
    const { rooms, sensors, modificationDate } = props

    return (
        <div className={styles.infoWrapper}>
            <div>
                <div className={styles.label}>
                    <Trans id="roomDetail.rooms.title">Ruimtes</Trans>
                </div>
                <div className={styles.value}>{rooms}</div>
            </div>
            <div>
                <div className={styles.label}>
                    <Trans id="roomDetail.sensors.title">Sensoren</Trans>
                </div>
                <div className={styles.value}>{sensors}</div>
            </div>
            <div>
                <div className={styles.label}>
                    <Trans id="roomDetail.modifiedOn.title">Gewijzigd op</Trans>
                </div>
                <div className={styles.value}>{modificationDate}</div>
            </div>
        </div>
    )
}

import { ActivityIndicator, ActivityIndicatorEnumType, LocaleContext } from '@unicaiot/unica-iot-gallery-core'
import React, { useContext } from 'react'
import { RouteComponentProps, useHistory, useParams } from 'react-router-dom'
import { routes } from 'views/router/Authorized/routes'
import {
    Button,
    ButtonStyleType,
    Center,
    Column,
    ColumnSpacing,
    FontSize,
    HeadingLevel,
    JustifyContent,
    PageTitle,
    Row,
    Title,
} from '@unicaiot/unica-iot-gallery-buildinginsight'
import { Trans } from '@lingui/macro'
import { DataPointsDetailBaseParams } from 'views/router/Authorized/Building/Rooms/Datapoints/routes'
import { SensorsForm } from 'domain/Rooms/Sensors/SensorsForm'
import { useSubmitSensor } from 'domain/Rooms/Sensors/hooks/useSubmitSensor'
import { useGetSensorDetailView } from 'domain/Rooms/Sensors/service/sensorsService.hooks'

interface RouteParams {
    buildingId: string
    roomId: string
}

interface Props extends RouteComponentProps<RouteParams> {}

export const DataPointsEditView: React.FC<Props> = () => {
    const locale = useContext(LocaleContext)
    const history = useHistory()
    const { buildingId, roomId, dataPointId } = useParams<DataPointsDetailBaseParams>()
    const { data, error, loading } = useGetSensorDetailView({ buildingId, roomId, dataPointId })
    const { onSubmit } = useSubmitSensor(routes.authorized.buildings.rooms.detail({ buildingId, roomId }))

    return <Column spacing={ColumnSpacing.spacing5}>{renderBuilding()}</Column>

    function renderBuilding() {
        if (loading) {
            return (
                <Center grow={true}>
                    <ActivityIndicator size={ActivityIndicatorEnumType.large} />
                    <Trans id="common.loading">De data vanuit de datapunten wordt opgehaald.</Trans>
                </Center>
            )
        }

        if (error) {
            return (
                <Center grow={true}>
                    <Column spacing={ColumnSpacing.spacing4}>
                        <Row justifyContent={JustifyContent.center}>
                            <Title headingLevel={HeadingLevel.h2} fontSize={FontSize.size22}>
                                <Trans id="common.error.title">Er is iets fout gegaan</Trans>
                            </Title>
                        </Row>
                        <Row justifyContent={JustifyContent.center}>
                            <Trans id="common.error.message">
                                Er ging iets mis met het laden van de pagina. Keer terug naar de vorige pagina.
                            </Trans>
                        </Row>
                        <Row justifyContent={JustifyContent.center}>
                            <Button buttonStyle={ButtonStyleType.primary} onClick={() => history.goBack()}>
                                <Trans id={'common.goBack'}>Terug naar vorige pagina</Trans>
                            </Button>
                        </Row>
                    </Column>
                </Center>
            )
        }

        if (data && data.room?.sensorInList) {
            const sensor = data.room.sensorInList

            return (
                <>
                    <PageTitle
                        breadCrumbs={[
                            {
                                label: data.name,
                                to: routes.authorized.buildings.detail(buildingId),
                            },
                            {
                                label: data.room.name,
                                to: routes.authorized.buildings.rooms.detail({ buildingId, roomId }),
                            },
                        ]}
                    >
                        {locale._(`roomDetail.dataPoints.${sensor.type}`)}
                    </PageTitle>
                    <SensorsForm onSave={data => onSubmit(data)} sensor={sensor} />
                </>
            )
        }
    }
}

import { DataPointType } from '@unicaiot/unica-iot-gallery-buildinginsight'
import { Type } from 'domain/Rooms/Sensors/service/types'

export function getDataPointType(type: Type): DataPointType {
    let status = DataPointType.energy

    switch (type) {
        case Type.CO2:
            status = DataPointType.air
            break
        case Type.humidity:
            status = DataPointType.water
            break
        case Type.temperature:
            status = DataPointType.air
            break
    }

    return status
}

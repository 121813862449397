import { LocaleContext, pathof, RequestStatus } from '@unicaiot/unica-iot-gallery-core'
import React, { Dispatch, Fragment, SetStateAction, useContext, useEffect, useState } from 'react'
import {
    Button,
    ButtonStyleType,
    Column,
    ColumnSpacing,
    FontSize,
    HeadingLevel,
    InfoText,
    Select,
    Title,
    TitleColor,
} from '@unicaiot/unica-iot-gallery-buildinginsight'
import styles from './RegisterSensorForm.module.scss'
import { RegisterSensor, Sensor, Type } from 'domain/Rooms/Sensors/service/types'
import { useGetRegistration } from 'domain/Rooms/Sensors/service/sensorsService.hooks'
import { Room } from 'domain/Rooms/service/types'
import { Building } from 'domain/Buildings/services/buildingService/types'
import { translations } from '../../../translations'
import { typeTranslations } from 'domain/DataPoints/translations/translations'

interface Props {
    id: string
    onSave: (sensor: Sensor, setError?: Dispatch<SetStateAction<string | undefined>>) => void
    loading?: boolean
    data: RequestStatus<RegisterSensor | undefined>
}

export const RegisterSensorForm: React.FC<Props> = props => {
    const { id, onSave, loading } = props
    const { data } = useGetRegistration(id)
    const locale = useContext(LocaleContext)

    const [building, setBuilding] = useState<Building>()
    const [globalRoom, setRoom] = useState<Room>()

    const [buildings, setBuildings] = useState<Building[]>([])

    useEffect(() => {
        if (data?.sensor?.buildingRef && data?.sensor?.roomRef) {
            const b = data.buildings?.find(b => b.id === data?.sensor?.buildingRef)
            setBuilding(Object.assign({}, b))
            setRoom(findRoom(b?.rooms, data?.sensor?.roomRef))
        }
        if (data?.buildings) {
            setBuildings(data.buildings)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    const expand = (room?: Room, rooms?: Room[], parrentRoom?: Room) => {
        return (
            <Fragment>
                <Select
                    value={room?.id || ''}
                    disabled={!building}
                    required={true}
                    placeholder={
                        parrentRoom
                            ? undefined
                            : locale._(pathof<typeof translations>()._('registrationView')._('roomSelect').path)
                    }
                    onChange={v => {
                        const vRoom = rooms?.find(r => r.id === v)
                        let result: Room | undefined = vRoom && { ...vRoom, rooms: [] }

                        if (parrentRoom) {
                            parrentRoom.rooms = result ? [result] : []
                            result = globalRoom
                        }

                        setRoom(Object.assign({}, result))
                    }}
                    options={(parrentRoom
                        ? [
                              {
                                  value: '',
                                  label: locale._(
                                      pathof<typeof translations>()._('registrationView')._('roomNone').path
                                  ),
                              },
                          ]
                        : []
                    ).concat(
                        rooms?.map(t => {
                            return { value: t.id, label: t.name }
                        }) ?? []
                    )}
                />
                {room?.rooms && expand(room.rooms?.[0], rooms?.find(r => r.id === room?.id)?.rooms, room)}
            </Fragment>
        )
    }

    return (
        <div className={styles.container}>
            <Column spacing={ColumnSpacing.spacing3}>
                <div>
                    <Title headingLevel={HeadingLevel.h4} fontSize={FontSize.size14} color={TitleColor.dark}>
                        {locale._({ id: 'registerSensorForm.label', message: 'Type sensor' })}
                    </Title>
                    <InfoText>{locale._(typeTranslations[data?.sensor.type as Type])}</InfoText>
                </div>

                <div>
                    <Title headingLevel={HeadingLevel.h4} fontSize={FontSize.size14} color={TitleColor.dark}>
                        {locale._({ id: 'registerSensorForm.uniqueCode.label', message: 'Unieke code' })}
                    </Title>
                    <InfoText>{data?.sensor.name}</InfoText>
                </div>
                <Select
                    value={building?.id}
                    required={true}
                    label={locale._({ id: 'registerSensorForm.building', message: 'Gebouw' })}
                    placeholder={locale._(pathof<typeof translations>()._('registrationView')._('buildingSelect').path)}
                    onChange={v => {
                        setBuilding(buildings.find(b => b.id === v))
                        setRoom(undefined)
                    }}
                    backgroundColor={'grey'}
                    options={buildings.map(t => {
                        return { value: t.id, label: t.name }
                    })}
                />
                {expand(globalRoom, building?.rooms)}
                <div className={styles.buttonContainer}>
                    <Button buttonStyle={ButtonStyleType.primary} onClick={onRegisterSensor} loading={loading}>
                        Sensor registeren
                    </Button>
                </div>
            </Column>
        </div>
    )

    function onRegisterSensor() {
        if (!building || !globalRoom || !data?.sensor) {
            return
        }

        const getRoomRef = (room: Room): string => {
            return room.rooms?.[0] ? getRoomRef(room.rooms?.[0]) : room.id
        }

        data.sensor.buildingRef = building.id
        data.sensor.roomRef = getRoomRef(globalRoom)

        onSave(data.sensor)
    }

    function findRoom(rooms?: Room[], roomRef?: string, parentRoom?: Room): Room | undefined {
        const room =
            rooms?.find(r => r.id === roomRef) || rooms?.map(r => findRoom(r.rooms, roomRef, r))?.filter(r => r)?.[0]

        let result: Room | undefined

        if (room) {
            result = Object.assign({}, room)
            result.rooms = result.rooms || []
        }

        if (parentRoom && result) {
            result.roomRef = parentRoom.id
            result = Object.assign({}, { ...parentRoom, rooms: [result] })
        }

        return result
    }
}

import {
    Column,
    FontSize,
    HeadingLevel,
    IconButton,
    Icons,
    InfoText,
    TextColorEnumType,
    Title,
    TitleColor,
} from '@unicaiot/unica-iot-gallery-buildinginsight'
import { LocaleContext } from '@unicaiot/unica-iot-gallery-core'
import classNames, { Argument } from 'classnames'
import { typeTranslations } from 'domain/DataPoints/translations/translations'
import { Sensor, Type } from 'domain/Rooms/Sensors/service/types'
import React, { useContext } from 'react'
import styles from './RegisterSensorSuccessAccordion.module.scss'

interface Props {
    className?: Argument
    data: Sensor
    open: boolean
    onClick: () => void
}

export const RegisterSensorSuccessAccordion: React.FC<Props> = props => {
    const { data, className, open, onClick } = props
    const classes = classNames(styles.container, className)
    const locale = useContext(LocaleContext)

    if (open) {
        return (
            <Column className={classes}>
                <div className={styles.buttonContainer}>
                    <InfoText>
                        {locale._({
                            id: 'registerSensorSuccessAccordion.buttonText',
                            message: 'Sensor gegevens bekijken',
                        })}
                    </InfoText>
                    <IconButton icon={Icons['arrow-down']} onClick={onClick} />
                </div>

                <div>
                    <Title headingLevel={HeadingLevel.h2} fontSize={FontSize.size22} color={TitleColor.dark}>
                        {locale._({ id: 'registerSensorSuccessAccordion.title', message: 'Sensor gegevens' })}
                    </Title>
                    <InfoText color={TextColorEnumType.grey}>
                        {locale._({
                            id: 'registerSensorSuccessAccordion.message',
                            message:
                                'Deze gegevens zijn ook terug te vinden in Building Insight in je geselecteerde ruimte.',
                        })}
                    </InfoText>
                </div>
                <div>
                    <Title headingLevel={HeadingLevel.h4} fontSize={FontSize.size14} color={TitleColor.dark}>
                        {locale._({ id: 'registerSensorForm.label', message: 'Type sensor' })}
                    </Title>
                    <InfoText>{locale._(typeTranslations[data.type as Type])}</InfoText>
                </div>
                <div>
                    <Title headingLevel={HeadingLevel.h4} fontSize={FontSize.size14} color={TitleColor.dark}>
                        {locale._({ id: 'registerSensorForm.uniqueCode', message: 'Unieke code' })}
                    </Title>
                    <InfoText>{data.name}</InfoText>
                </div>
                <div>
                    <Title headingLevel={HeadingLevel.h4} fontSize={FontSize.size14} color={TitleColor.dark}>
                        {locale._({ id: 'registerSensorForm.building', message: 'Gebouw' })}
                    </Title>
                    <InfoText>{data.buildingName}</InfoText>
                </div>
                <div>
                    <Title headingLevel={HeadingLevel.h4} fontSize={FontSize.size14} color={TitleColor.dark}>
                        {locale._({ id: 'registerSensorForm.space', message: 'Ruimte' })}
                    </Title>
                    <InfoText>{data.roomName}</InfoText>
                </div>
            </Column>
        )
    }

    return null
}

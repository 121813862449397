import styles from './BuildingDetailGrid.module.scss'

import React, { FunctionComponent, useContext } from 'react'
import {
    Column,
    ColumnSpacing,
    DataBlock,
    DataPointStatus,
    InfoText,
    TextSizeEnumType,
} from '@unicaiot/unica-iot-gallery-buildinginsight'
import classNames, { Argument } from 'classnames'
import { RoomDetailGrid } from '../RoomDetailGrid/RoomDetailGrid'
import { RoomInfoCard } from '../RoomInfo/RoomInfoCard'
import moment from 'moment'

import { LocaleContext } from '@unicaiot/unica-iot-gallery-core'
import { useHistory } from 'react-router-dom'
import { BuildingInfo } from '../BuildingInfo/BuildingInfo'
import { BuildingViewType } from 'domain/Buildings/services/buildingService/types'
import { routes } from 'views/router/Authorized/routes'
import { EmptyState } from 'domain/Shared/EmptyState/EmptyState'
import { Room } from 'domain/Rooms/service/types'
import { PointKpi } from 'domain/Rooms/Sensors/service/types'

interface Props {
    className?: Argument
    buildingDetail: BuildingViewType
}

export const BuildingDetailGrid: FunctionComponent<Props> = ({ buildingDetail, className }) => {
    const locale = useContext(LocaleContext)
    const classes = classNames(styles.container, className)

    const history = useHistory()

    return (
        <div className={classes}>
            <div className={styles.buildingDetail}>
                <BuildingInfo building={buildingDetail} />
            </div>
            {renderRooms(buildingDetail.rooms)}
        </div>
    )

    function renderRooms(rooms?: Room[]) {
        if (!rooms || !rooms.length) {
            return (
                <EmptyState
                    title={locale._('Dashboard.emptyRoom.title')}
                    infoText={locale._('Dashboard.emptyRoom.infoText')}
                    button={{
                        text: locale._('Dashboard.addRoom'),
                        onClick: () =>
                            history.push(
                                routes.authorized.buildings.rooms.create({
                                    buildingId: buildingDetail.id,
                                })
                            ),
                    }}
                />
            )
        }

        return (
            <div className={styles.rooms}>
                <Column spacing={ColumnSpacing.spacing3}>
                    <InfoText size={TextSizeEnumType.large}>{locale._('buildingDetail.floors.title')}</InfoText>
                    <RoomDetailGrid
                        rooms={rooms.map((data, index) => {
                            return (
                                <RoomInfoCard
                                    key={index}
                                    type={data.type}
                                    title={data.name}
                                    rooms={data.counts.rooms || 0}
                                    sensors={data.counts.sensors || 0}
                                    data={getDataPointsCount(data.valuesCounts)}
                                    modificationDate={moment(data.modificationDate).format('DD MMMM YYYY')}
                                    onClick={() =>
                                        history.push(
                                            routes.authorized.buildings.rooms.detail({
                                                buildingId: buildingDetail.id,
                                                roomId: data.id,
                                            })
                                        )
                                    }
                                />
                            )
                        })}
                    />
                </Column>
            </div>
        )
    }

    function getDataPointsCount(value: { [key in keyof typeof PointKpi]?: number }) {
        const data = Object.entries(value)
        return data.map(([status, count], index) => {
            return <DataBlock key={index} data={count} status={getDataPointsStatus(status as PointKpi)} />
        })
    }

    function getDataPointsStatus(data: PointKpi): DataPointStatus {
        let status = DataPointStatus.average
        switch (data) {
            case PointKpi.Sufficient:
                status = DataPointStatus.positive
                break
            case PointKpi.Moderate:
                status = DataPointStatus.warning
                break
            case PointKpi.InSufficient:
                status = DataPointStatus.negative
                break
        }

        return status
    }
}

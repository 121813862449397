import { LocaleContext } from '@unicaiot/unica-iot-gallery-core'
import React, { Fragment, useContext } from 'react'
import { DataPointsGrid } from '../DataPointsGrid/DataPointsGrid'
import {
    DataPointSize,
    InfoText,
    RectangleDataPoint,
    SquaredDataPoint,
    TextSizeEnumType,
    TextStyleEnumType,
} from '@unicaiot/unica-iot-gallery-buildinginsight'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { RoomDetailGrid, GridColumns } from 'domain/Buildings/components/RoomDetailGrid/RoomDetailGrid'
import { RoomInfoCard } from 'domain/Buildings/components/RoomInfo/RoomInfoCard'
import { EmptyState } from 'domain/Shared/EmptyState/EmptyState'
import { ScrollWrapper } from 'domain/Shared/ScrollWrapper/ScrollWrapper'
import { routes } from 'views/router/Authorized/routes'
import { Room } from 'domain/Rooms/service/types'
import { getDataPointStatus } from 'utils/getDataPointStatus'
import { getDataPointType } from 'utils/getDataPointType'
import { getDataPointUnit } from 'utils/getDataPointUnit'
import { LatestValue, Sensor, Type } from 'domain/Rooms/Sensors/service/types'

interface Props {
    data: Room
    spaceDetails: SpaceDetails
}

interface SpaceDetails {
    buildingId: string
    roomId: string
}

export const RoomsDetailGridView: React.FC<Props> = props => {
    const locale = useContext(LocaleContext)
    const { data, spaceDetails } = props
    const history = useHistory()

    return (
        <>
            <DataPointsGrid title={locale._(`roomDetail.dataPoints.title`)} data={renderDataPoints(data?.sensors)} />
            <InfoText size={TextSizeEnumType.large}>{locale._(`roomDetail.rooms.title`)}</InfoText>
            {renderRooms(data)}
        </>
    )

    function renderRooms(room?: Room) {
        if (!room || !room.rooms?.length) {
            return (
                <EmptyState
                    title={locale._('Dashboard.emptyRoom.title')}
                    infoText={locale._('Dashboard.emptyRoom.infoText')}
                    button={{
                        text: locale._('Dashboard.addRoom'),
                        onClick: () =>
                            history.push(
                                routes.authorized.buildings.rooms.create({
                                    buildingId: spaceDetails.buildingId,
                                    roomId: spaceDetails.roomId,
                                })
                            ),
                    }}
                />
            )
        }

        return (
            <RoomDetailGrid
                columns={GridColumns['3-columns']}
                rooms={room.rooms.map((data, index) => {
                    return (
                        <RoomInfoCard
                            key={index}
                            type={data.type}
                            title={data.name}
                            rooms={data.counts.rooms}
                            sensors={data.counts.sensors}
                            modificationDate={moment(data.modificationDate).format('DD MMMM YYYY')}
                            onClick={() =>
                                history.push(
                                    routes.authorized.buildings.rooms.detail({
                                        buildingId: spaceDetails.buildingId,
                                        roomId: data.id,
                                    })
                                )
                            }
                            data={<Fragment>{renderAverageValues(data.averageValues)}</Fragment>}
                        />
                    )
                })}
            />
        )
    }

    function renderAverageValues(values: LatestValue) {
        const data = Object.entries(values)

        if (!data.length) {
            return (
                <InfoText style={TextStyleEnumType.italic}>{locale._('roomDetail.dataPoints.noDataPoints')}</InfoText>
            )
        }

        const averageValues = data.map(([type, data], index) => {
            const value = Math.round(data?.value || 0)

            return (
                <RectangleDataPoint
                    key={index}
                    status={getDataPointStatus(data?.kpi)}
                    type={getDataPointType(type as Type)}
                    size={DataPointSize.smallGroup}
                    label={locale._(`buildingDetail.dataPointAverages.${type}`)}
                    value={`${value} ${getDataPointUnit(type as Type)}`}
                />
            )
        })

        return <ScrollWrapper>{averageValues}</ScrollWrapper>
    }

    function renderDataPoints(data?: Sensor[]) {
        return data?.map((sensor, index) => {
            const { latestKpi } = sensor

            return (
                <SquaredDataPoint
                    key={index}
                    status={getDataPointStatus(latestKpi.kpi)}
                    type={getDataPointType(sensor.type)}
                    label={locale._(`roomDetail.dataPoints.${sensor.type}`)}
                    value={`${latestKpi.value} ${getDataPointUnit(sensor.type)}`}
                    averageLabel={'some average'}
                    hoveredText={'Bekijken'}
                    onClick={() =>
                        history.push(
                            routes.authorized.buildings.rooms.dataPoints.detail({
                                buildingId: spaceDetails.buildingId,
                                roomId: spaceDetails.roomId,
                                dataPointId: sensor.id,
                            })
                        )
                    }
                />
            )
        })
    }
}

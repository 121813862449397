import React, { Dispatch, SetStateAction, useContext, useRef, useState } from 'react'
import {
    ButtonStyleType,
    Column,
    Icons,
    Modal,
    PageTitle,
    PromptModalView,
    Row,
    RowSpacing,
    TextButton,
    useModal,
} from '@unicaiot/unica-iot-gallery-buildinginsight'
import { Trans } from '@lingui/macro'
import { useHistory } from 'react-router-dom'
import { LocaleContext, useUser } from '@unicaiot/unica-iot-gallery-core'
import { routes } from 'views/router/Authorized/routes'
import { UserCreateForm } from 'domain/Users/Components/UserCreateForm/UserCreateForm'
import { usersService } from 'domain/Users/services/usersService'
import { checkIfRequestStatusOK } from 'domain/Core/utils/checkIfRequestStatusOK'
import { CreateUserFormData, User } from 'domain/Users/services/types'
import { UserCreateModal } from 'domain/Users/Components/UserCreateModal/UserCreateModal'

export const UserCreateView: React.FC = () => {
    const history = useHistory()
    const [loading, setLoading] = useState<boolean>(false)
    const locale = useContext(LocaleContext)
    const [createdUser, setCreatedUser] = useState<User>()
    const [createUserModalRef, createUserModalActions] = useModal()

    const createUser = async (data: CreateUserFormData, setError: Dispatch<SetStateAction<string | undefined>>) => {
        const result = await usersService.createUser(data)

        setLoading(true)

        if (checkIfRequestStatusOK(result.status)) {
            getUser(result.data)
            setLoading(false)
            createUserModalActions.actions.openModal()
            return
        }

        setLoading(false)
        setError(locale._('common.error'))
    }

    return (
        <>
            <PageTitle
                breadCrumbs={[
                    {
                        label: locale._({ id: 'userReadView.users', message: 'Gebruikers' }),
                        to: routes.authorized.users.index,
                    },
                ]}
            >
                <Trans id="users.title">{'Nieuwe gebruiker'}</Trans>
            </PageTitle>
            <Row spacing={RowSpacing.spacing17} grow={true}>
                <Column>
                    <TextButton
                        icon={Icons.return}
                        buttonStyle={ButtonStyleType.secondary}
                        onClick={() => history.goBack()}
                    >
                        {locale._({ id: 'userReadView.goBackToOverView', message: 'Terug naar overzicht' })}
                    </TextButton>
                </Column>
            </Row>
            <UserCreateForm onSave={createUser} loading={loading} />
            <Modal ref={createUserModalRef}>
                <PromptModalView>
                    <UserCreateModal
                        title={locale._({
                            id: 'createUserForm.modal.title',
                            message: `Account gecreëerd voor ${createdUser?.firstName} ${createdUser?.lastName}`,
                        })}
                        infoText={locale._({
                            id: 'createUserForm.modal.title',
                            message: `Er is een mail verzonden naar ${createdUser?.firstName} ${createdUser?.lastName} met de link om zijn account te bevestigen.`,
                        })}
                    />
                </PromptModalView>
            </Modal>
        </>
    )

    async function getUser(id: string) {
        const response = await usersService.getUser(id)

        if (checkIfRequestStatusOK(response.status)) {
            setCreatedUser(response.data)
            return
        }
    }
}

import { LocaleContext } from '@unicaiot/unica-iot-gallery-core'
import React, { FC, useContext } from 'react'
import {
    Button,
    ButtonStyleType,
    FontSize,
    HeadingLevel,
    Icons,
    InfoText,
    TextButton,
    Title,
} from '@unicaiot/unica-iot-gallery-buildinginsight'
import styles from './BuildingHierarchyDeleteModal.module.scss'

interface Props {
    title: string
    message: string
    onDelete?: () => void
    onCancel?: () => void
}

export const BuildingHierarchyDeleteModal: FC<Props> = props => {
    const { onCancel, onDelete, title, message } = props
    const locale = useContext(LocaleContext)

    return (
        <div className={styles.container}>
            <Title className={styles.title} headingLevel={HeadingLevel.h4} fontSize={FontSize.size16}>
                {title}
            </Title>
            <InfoText className={styles.message}>{message}</InfoText>
            <div className={styles.buttonContainer}>
                <TextButton onClick={onCancel} buttonStyle={ButtonStyleType.secondary}>
                    {locale._({ id: 'deleteSpace.cancel', message: 'Nee, ruimte behouden' })}
                </TextButton>

                <Button buttonStyle={ButtonStyleType.danger} onClick={onDelete} icon={Icons.bin}>
                    {locale._({ id: 'deleteSpace.delete', message: 'Ja, verwijder ruimte' })}
                </Button>
            </div>
        </div>
    )
}
